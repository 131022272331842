import  React, {useEffect} from "react"
import { H5PEditorUI } from '@lumieducation/h5p-react';
import * as h5PService from "../../../Services/h5p.service";
import {Button, Col, Container, FormControl, Row} from "react-bootstrap";


const EditH5P = (props) => {

    useEffect(()=> {}, [])

    const h5pEditor = React.createRef();

    const save = async () => {
        try {
            const result =  await h5pEditor.current?.save()
            props.updateChapterTopic(result.data.contentId, 'contentId')

            // do something with the return values or ignore them
        } catch (error) {
            console.log(error)
        }
    }

    const getEdit  =  async (contentId) => {
       let result = await h5PService.getEdit(contentId)
        return result.data
    }

    const deletePlayer = async (contentId) => {
        if (contentId) {
        //    await h5PService.deletePlayer(contentId)
       //     props.deleteTopic()
            props.updateChapterTopic(true, 'delete')
        } else {
       //     props.deleteTopic()
        }
    }

    return (
        <Container>
            <Row>

                <Col md={12} style={{marginBottom: "10px"}}>
                    <FormControl
                        onChange={(event) => props.updateChapterTopic(event.target.value, 'title')}
                        value={props.topic.title}
                        placeholder="Title"
                        aria-label="Title"
                        aria-describedby="basic-addon1"/>
                </Col>

                <Col md={12}>
                    <H5PEditorUI
                        ref = {h5pEditor}
                        contentId={props.topic.contentId}
                        loadContentCallback = {getEdit}
                        saveContentCallback={h5PService.saveContent}
                    />
                </Col>

                <Row style={{marginTop:"10px"}}>

                <Col md={4}>
                    <Button onClick={()=>save()}>Save</Button>
                </Col>

                <Col md={4}>
                    <Button onClick={()=>deletePlayer(props.topic?.contentId)}>Delete</Button>
                </Col>
                </Row>

            </Row>
        </Container>
    )
}

export default EditH5P