import {Col, Container, Row} from "react-bootstrap";
import * as React from 'react';
import './About.component.css'
import about1 from "../../images/about-1.jpg";
import about2 from "../../images/about-2.jpg";
import about3 from "../../images/about-3.jpg";
import about4 from "../../images/about-4.jpg";
import about5 from "../../images/about-5.jpg";

const About = () => {

    return (
        <div id="about-us">
            <Container className="d-none d-sm-block">
                <Row>
                    <Col md={12}>
                        <h1 style={{
                            letterSpacing: '1px',
                            color: 'black',
                            fontSize: '25px',
                            textTransform: 'capitalize',
                        }} className={"primary-font-family"}>About</h1>
                    </Col>
                </Row>

                <Row style={{alignItems: "center"}}>
                    <Col md={6}>
                        <div style={{marginBottom: "10px"}}>
                            <div style={{fontWeight: "bold"}}>
                                Flexibility
                            </div>
                            <div>
                                With eLearning, you can easily combine work and studies and not be limited to a
                                classroom, city, or even your country.
                            </div>
                        </div>
                    </Col>

                    <Col md={6}>
                        <div>
                            <img src={about1} style={{width: "100%", height: "300px"}} alt={'learning platform'}/>
                        </div>
                    </Col>
                </Row>


                <Row style={{alignItems: "center"}}>
                    <Col md={6}>
                        <div>
                            <img src={about2} style={{width: "100%", height: "300px"}} alt={'learning platform'}/>
                        </div>
                    </Col>

                    <Col md={6}>
                        <div style={{marginBottom: "10px"}}>
                            <div style={{fontWeight: "bold"}}>
                                Self-paced learning
                            </div>
                            <div>
                                Log in when it’s comfortable for you. Easily go back to previous lectures, steps, or
                                lessons to clarify anything you missed.
                            </div>
                        </div>
                    </Col>
                </Row>


                <Row style={{alignItems: "center"}}>
                    <Col md={6}>
                        <div style={{marginBottom: "10px"}}>
                            <div style={{fontWeight: "bold"}}>
                                Time-efficiency
                            </div>
                            <div>
                                According to Brandon Hall, eLearning typically requires 40-60% less time investment from
                                a student than learning in a traditional classroom.
                            </div>
                        </div>
                    </Col>

                    <Col md={6}>
                        <div>
                            <img src={about3} style={{width: "100%", height: "300px"}} alt={'learning platform'}/>
                        </div>
                    </Col>

                </Row>


                <Row style={{alignItems: "center"}}>
                    <Col md={6}>
                        <div>
                            <img src={about4} style={{width: "100%", height: "300px"}} alt={'learning platform'}/>
                        </div>
                    </Col>

                    <Col md={6}>
                        <div style={{marginBottom: "10px"}}>
                            <div style={{fontWeight: "bold"}}>
                                Control over studies
                            </div>
                            <div>
                                Online students learn at the speed comfortable for them, and, according to IBM, can
                                assimilate five times more information in online courses than in traditional courses.
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row style={{alignItems: "center"}}>
                    <Col md={6}>
                        {/*<div style={{*/}
                        {/*    textAlign: "justify",*/}
                        {/*    fontSize: '18px',*/}
                        {/*    letterSpacing: '1px',*/}
                        {/*    backgroundColor: 'white',*/}
                        {/*    padding: "5px",*/}
                        {/*    border: '1px solid #dddddd',*/}
                        {/*    borderRadius: '5px',*/}
                        {/*}}>*/}

                        <div style={{marginBottom: "10px"}}>
                            <div style={{fontWeight: "bold"}}>
                                High retention rates
                            </div>
                            <div>
                                Keeping a student engaged throughout a course is always a problem. However, interactive
                                learning at our platform will help your students to retain the learning methods.
                            </div>
                        </div>

                        {/*</div>*/}
                    </Col>

                    <Col md={6}>
                        <div>
                            <img src={about5} style={{width: "100%", height: "300px"}} alt={'learning platform'}/>
                        </div>
                    </Col>
                </Row>

            </Container>


            <Container className="d-block d-sm-none">
                <Row>
                    <Col md={12}>
                        <h1 style={{
                            letterSpacing: '1px',
                            color: 'black',
                            fontSize: '25px',
                            textTransform: 'capitalize',
                        }} className={"primary-font-family"}>About</h1>
                    </Col>
                </Row>

                <Row style={{alignItems: "center", marginBottom: '15px'}}>

                    <Col md={6}>
                        <div>
                            <img src={about1} style={{width: "100%", height: "300px"}} alt={'learning platform'}/>
                        </div>
                    </Col>

                    <Col md={6}>
                        <div style={{marginBottom: "10px"}}>
                            <div style={{fontWeight: "bold"}}>
                                Flexibility
                            </div>
                            <div>
                                With eLearning, you can easily combine work and studies and not be limited to a
                                classroom, city, or even your country.
                            </div>
                        </div>
                    </Col>


                </Row>


                <Row style={{alignItems: "center", marginBottom: '15px'}}>
                    <Col md={6}>
                        <div>
                            <img src={about2} style={{width: "100%", height: "300px"}} alt={'learning platform'}/>
                        </div>
                    </Col>

                    <Col md={6}>
                        <div style={{marginBottom: "10px"}}>
                            <div style={{fontWeight: "bold"}}>
                                Self-paced learning
                            </div>
                            <div>
                                Log in when it’s comfortable for you. Easily go back to previous lectures, steps, or
                                lessons to clarify anything you missed.
                            </div>
                        </div>
                    </Col>
                </Row>


                <Row style={{alignItems: "center", marginBottom: '15px'}}>

                    <Col md={6}>
                        <div>
                            <img src={about3} style={{width: "100%", height: "300px"}} alt={'learning platform'}/>
                        </div>
                    </Col>

                    <Col md={6}>
                        <div style={{marginBottom: "10px"}}>
                            <div style={{fontWeight: "bold"}}>
                                Time-efficiency
                            </div>
                            <div>
                                According to Brandon Hall, eLearning typically requires 40-60% less time investment from
                                a student than learning in a traditional classroom.
                            </div>
                        </div>
                    </Col>



                </Row>


                <Row style={{alignItems: "center", marginBottom: '15px'}}>
                    <Col md={6}>
                        <div>
                            <img src={about4} style={{width: "100%", height: "300px"}} alt={'learning platform'}/>
                        </div>
                    </Col>

                    <Col md={6}>
                        <div style={{marginBottom: "10px"}}>
                            <div style={{fontWeight: "bold"}}>
                                Control over studies
                            </div>
                            <div>
                                Online students learn at the speed comfortable for them, and, according to IBM, can
                                assimilate five times more information in online courses than in traditional courses.
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row style={{alignItems: "center"}}>

                    <Col md={6}>
                        <div>
                            <img src={about5} style={{width: "100%", height: "300px"}} alt={'learning platform'}/>
                        </div>
                    </Col>

                    <Col md={6}>
                        {/*<div style={{*/}
                        {/*    textAlign: "justify",*/}
                        {/*    fontSize: '18px',*/}
                        {/*    letterSpacing: '1px',*/}
                        {/*    backgroundColor: 'white',*/}
                        {/*    padding: "5px",*/}
                        {/*    border: '1px solid #dddddd',*/}
                        {/*    borderRadius: '5px',*/}
                        {/*}}>*/}

                        <div style={{marginBottom: "10px"}}>
                            <div style={{fontWeight: "bold"}}>
                                High retention rates
                            </div>
                            <div>
                                Keeping a student engaged throughout a course is always a problem. However, interactive
                                learning at our platform will help your students to retain the learning methods.
                            </div>
                        </div>

                        {/*</div>*/}
                    </Col>


                </Row>

            </Container>
        </div>
    )
};


export default About;



