import React, {useEffect, useState} from "react";
import * as userService from "../../Services/User.service"
import {useParams} from "react-router-dom";
import {Container, Spinner} from "react-bootstrap";


const ConfirmEmailComponent = () => {

    const {token} = useParams()
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("")


    useEffect(() => {

        userService.confirmEmail(token).then(() => {

            setMessage("Your Email is confirmed. You can login in order to access your account")
            setLoading(false)
        }).catch((err) => {
            setMessage("There was some problem in confirming your email. Please try again")
            setLoading(false)
        })
    })

    return (
        <Container>
            <div>
                {
                    loading ?
                        <div style={{textAlign: "center"}}>
                            <Spinner animation="border"/>
                        </div> :
                        <div>
                            {message}
                        </div>
                }
            </div>

        </Container>
    )


}

export default ConfirmEmailComponent