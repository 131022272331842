import * as React from 'react'
import {Accordion, Card, Container} from "react-bootstrap";

const Faq = () => {

    let arr = [
        {question:" What is AcademyIn?", answer: "Our purpose is to provide you with information about the calories that you are consuming in each of your meals."},
        {question: "How to enroll in Courses as a Student?", answer: "You will get the list of Recipes that are currently available on our website. We keep adding new recipes regularly."},
        {question: "How can I become a Professor?", answer: "You will get the list of ingredients that are used in the recipes prepared."}
            ];

    const getAccordion = (question, answer, key) =>{
        return (
            <Card key={key}>
                <Accordion.Item eventKey={key} >
                <Accordion.Header as={Card.Header}  style={{
                    letterSpacing: '1px',
                    fontSize: '18px',
                    cursor: 'pointer'
                }}
                                  className={"font-color primary-font-family"}>
                    {question}
                </Accordion.Header>
                <Accordion.Body eventKey={key}>
                    <Card.Body style={{
                        fontSize: '18px',
                        letterSpacing: '1px'
                    }} className={"secondary-font-family"}>{answer}</Card.Body>
                </Accordion.Body>
                </Accordion.Item>
            </Card>
        )
    };

    return (
        <Container>

            <h1 style={{
                letterSpacing: '1px',
                fontSize: '25px',
                textTransform: 'capitalize'
            }}
                className={"font-color primary-font-family"}
            >FAQ</h1>
            <Accordion defaultActiveKey="1">
                {
                    arr.map((item, index) =>{
                      return  getAccordion(item.question, item.answer, index+1);
                    })
                }
            </Accordion>
        </Container>
    )

};


export default Faq;
