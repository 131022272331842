import axios from "axios";
import {getHeadersCustom} from "./Admin.service"
import {allCourses} from "../actions/courseAction";

const URL = process.env.NODE_ENV === "development" ? "/api/courses" : "/api/courses"

export const getCourseDetails = (slug) => axios.get( `${URL}/get/slug/${slug}`)
export const getCourseDetailsAdmin = (id) => axios.get( `${URL}/get/id/${id}`)
export const deleteCourse = (id) => axios.delete( `${URL}/delete/${id}`)
export const saveCourse = (course) => axios.post( `${URL}/save-course`, course)
export const updateCourse = (course) => axios.put( `${URL}/update-course/${course.id}`, course)
export const checkUserCourseEnrollment = (userId, courseId) => axios.get( `${URL}/check-enrollment/course/${courseId}`)
export const getTotalUsersEnrolledForCourses = () => axios.get( `${URL}/courses-enrolled`)
export const getCourseCompletion = (userId, courseId) => axios.get( `${URL}/course-completion/user/${userId}/course/${courseId}`)
export const markCourseComplete = (userId, courseId, body) => axios.post( `${URL}/course-completion/user/${userId}/course/${courseId}`, body)
export const getAllStudentsEnrolment = (courseId) => axios.get(`${URL}/all-students-course-enrolment/${courseId}`)

export const getAllCourses = () => {
    return (dispatch) => {
        return axios.get(URL + "/get-all")
            .then((res) => {
                dispatch(allCourses(res.data))
            })
            .catch((err) => {
                console.log(err)
            })
    }
};

export const getAllCoursesAdmin = () => {
    return (dispatch) => {
        return axios.get(URL + "/get-all-courses-admin", getHeadersCustom())
            .then((res) => {
                dispatch(allCourses(res.data))
            })
            .catch((err) => {
                console.log(err)
            })
    }
};

export const updateCourseList = (courseList) => {
    return (dispatch) => {
        dispatch(allCourses(courseList))
    }
};

