import React, {useEffect, useState} from "react"
import {Button, Col, Container, FormControl, InputGroup, Row} from "react-bootstrap";
import * as userService from "../../Services/User.service";
import AdminHeaderComponent from "./Header/Header.component";
import {getAllCourses} from "../../Services/Course.service"
import Select from "react-select";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const TempLoginLink = (props) => {

    const [tempLinkDetails, setTempLinkDetails] = useState(null)
    const [generatedLink, setGeneratedLink] = useState(null)
    const [imageLink, setImageLink] = useState("")

    useEffect(() => {
        props.getAllCourses()

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const updateTempLinkDetails = (val, element) => {
        let newTempLinkDetails = {};
        Object.assign(newTempLinkDetails, tempLinkDetails);
        newTempLinkDetails[element] = val
        setTempLinkDetails(newTempLinkDetails)
    }

    const getLink = () => {
        userService.generateLink(tempLinkDetails).then((response) => {
            setGeneratedLink(response.data.link)
            const blob = base64toBlob(response.data.base64);

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            setImageLink(url)
            link.href = url;
            link.setAttribute('download', 'file.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();

        }).catch((err) => {
            console.log(err)
        })
    }

    const base64toBlob = (data) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64

        const bytes = window.atob(data);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'application/pdf' });
    };


    const getCourseList = () => {
        let result = []

        if (props.allCourses?.length > 0) {
            props.allCourses.forEach((organization) => {
                result.push({value: organization.id, label: organization.name})
            })
        }

        return result
    }

    const getCurrentCourse = () => {
        if (props.allCourses?.length > 0) {
            const org = props.allCourses.find((course) => course.id === tempLinkDetails?.courseId)

            if (org) {
                return {value: org.id, label: org.name}
            }
        }
    }

    const changeCourse = (val) => {
        let newTempLinkDetails = {};
        Object.assign(newTempLinkDetails, tempLinkDetails);
        newTempLinkDetails['courseId'] = val.value
        setTempLinkDetails(newTempLinkDetails)
    }

    const copyContent = (val) => {
        navigator.clipboard.writeText(val)
    };

    return (
        <Container style={{minHeight: "700px"}}>

            <AdminHeaderComponent/>

            <Row style={{marginBottom: '20px'}}>
                <Col md={12}>
                    <Select
                        placeholder={'Courses'}
                        value={getCurrentCourse()}
                        onChange={(val) => changeCourse(val)}
                        options={getCourseList()}
                    />
                    {/*<InputGroup>*/}
                    {/*    <FormControl*/}
                    {/*        onChange={(event) => updateTempLinkDetails(event.target.value, 'courseId')}*/}
                    {/*        value={tempLinkDetails?.courseId}*/}
                    {/*        placeholder={"Course Id"}*/}
                    {/*        aria-label={"CourseId"}*/}
                    {/*        aria-describedby="basic-addon1"/>*/}
                    {/*</InputGroup>*/}
                </Col>
            </Row>

            <Row style={{marginBottom: '20px'}}>
                <Col md={12}>
                    <InputGroup>
                        <InputGroup>
                            <FormControl
                                onChange={(event) => updateTempLinkDetails(event.target.value, 'time')}
                                value={tempLinkDetails?.time}
                                placeholder={"Time in Minutes"}
                                aria-label={"Time"}
                                aria-describedby="basic-addon1"/>
                        </InputGroup>
                    </InputGroup>
                </Col>
            </Row>

            {
                <img src={imageLink} alt=""/>
            }

            {
                generatedLink ?
                    <div>
                        <Row>
                            <Col>
                                <div>
                                    {generatedLink}

                                    <FontAwesomeIcon style={{cursor: "pointer", fontSize: "30px", marginRight: "5px"}}
                                                     icon={faCopy}
                                                     onClick={() => copyContent(`${generatedLink}`)}/>

                                </div>
                            </Col>
                        </Row>

                    </div> : undefined
            }

            <Row style={{marginBottom: '20px'}}>
                <Col md={3}>
                    <Button variant="primary" onClick={() => getLink()}>Get Link</Button>
                </Col>
            </Row>

        </Container>
    )

}

const mapStateToProps = (state) => ({
    userAuthenticated: state.userAuthenticated,
    allCourses: state.allCourses
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getAllCourses: getAllCourses,
        }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(TempLoginLink);