import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {submitTempLogin} from "../../Services/User.service";
import {getUserFromToken} from "../../Services/Admin.service";

const TempLogin = (props) => {

    const {token} = useParams()

    const [errors] = useState({password: null});
    const [tempLoginDetails, setTempLoginDetails] = useState({});

    useEffect(() => {
        let newTempLoginDetails = {};
        Object.assign(newTempLoginDetails, tempLoginDetails);
        newTempLoginDetails['token'] = token;
        setTempLoginDetails(newTempLoginDetails)

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);


    useEffect(()=> {
        if (props.userAuthenticated?.data?.userAuthentic) {
            const userDetails = getUserFromToken()

            if (userDetails.organizationUrl) {
                window.location.href  = process.env.NODE_ENV === "development" ? "http://" + userDetails.organizationUrl.split(":")[0] + ":3000" : "https://" + userDetails.organizationUrl
            } else {
                window.location.href  = process.env.NODE_ENV === "development" ? "http://localhost.com:3000" : "https://www.learningplatform.in"
            }
        }
    }, [props.userAuthenticated])


    const submitTempLoginDetails = () => {
        props.submitTempLogin(tempLoginDetails)
    }

    const updateTempLoginDetails = (val, element) => {
        let newTempLoginDetails = {};
        Object.assign(newTempLoginDetails, tempLoginDetails);
        newTempLoginDetails[element] = val;
        setTempLoginDetails(newTempLoginDetails)
    }


    return (
        <Container>
            <Row style={{height: "300px", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                <Col md={3}></Col>
                <Col md={6}>

                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            <InputGroup>
                                <Form.Control
                                    onChange={(event) => updateTempLoginDetails(event.target.value, 'name')}
                                    value={tempLoginDetails.name || ""}
                                    placeholder={"Name"}
                                    aria-label={"Name"}
                                    aria-describedby="basic-addon1"
                                    isInvalid={!!errors.name}
                                    isValid={errors.name === ''}/>
                                <Form.Control.Feedback type='invalid'>
                                    {errors.name}
                                </Form.Control.Feedback>
                                <Form.Control.Feedback>
                                    {"Looks Good!!"}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            <InputGroup>
                                <FormControl
                                    onChange={(event) => updateTempLoginDetails(event.target.value, 'email')}
                                    value={tempLoginDetails.email || ""}
                                    placeholder={"Email"}
                                    aria-label={"Email"}
                                    aria-describedby="basic-addon1"/>
                            </InputGroup>
                        </Col>
                    </Row>


                    <Row style={{marginBottom: '20px'}}>
                        <Col md={3}>
                            <Button variant="primary" onClick={() => submitTempLoginDetails()}>Submit</Button>
                        </Col>
                    </Row>


                </Col>
                <Col md={3}></Col>
            </Row>

        </Container>
    )

}

const mapStateToProps = (state) => ({
    userAuthenticated: state.userAuthenticated,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            submitTempLogin: submitTempLogin,
        }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(TempLogin);
