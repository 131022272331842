import React, {useState} from "react";
import {Button, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import * as userService from "../../Services/User.service";
import "./SignupStudent.component.css"
import DialogModel from "../models/DialogModel.component";
import {useHistory} from "react-router-dom";


const SignupStudentComponent = (props) => {

    const [user, setUser] = useState()
    const [errors, setErrors] = useState({name: null, email: null, contact: null, password: null})
    const [dilogMessage, setDilog] = useState("");
    const [showDilog, setShowDilog] = useState(false);
    const [signUpSuccess, setSignUpSuccess] = useState(false);
    const [pathname] = useState(process.env.NODE_ENV === "development" ? "http://localhost:8080/api/image/file" : "https://www.learningplatform.in/api/image/file");
    const history = useHistory()

    const setUserData = (event, element) => {
        let newUser = {};
        Object.assign(newUser, user);
        newUser[element] = event.target.value;
        setUser(newUser)
    };

    const InputBox = (method, value, placeholder, errorVar) => {
        return (
            <InputGroup>
                <Form.Control
                    onChange={method}
                    value={value}
                    placeholder={placeholder}
                    aria-label={placeholder}
                    isInvalid={!!errorVar}
                    isValid = {errorVar === ''}
                    aria-describedby="basic-addon1" />
                <Form.Control.Feedback type='invalid'>
                    { errorVar }
                </Form.Control.Feedback>
                <Form.Control.Feedback>
                    { "Looks Good!!" }
                </Form.Control.Feedback>
            </InputGroup>
        )
    };

    const updateUser = () => {
        if (validateUserInput()) {
            user.contact = parseInt(user?.contact)

            //let formData = new FormData()
            // formData.append("file", userImage);
            // formData.append("data", JSON.stringify(user))

            userService.signUp(user).then((result) => {
                setDilog(result.data.message)
                setShowDilog(true)
                setSignUpSuccess(true)

            }).catch((err) => {
                setDilog(err.response?.data?.message)
                setShowDilog(true)
            })
        }
    }

    const hideDilogBox = () => {
        setShowDilog(false)

        if (signUpSuccess) {
            history.push("/login")
        }
    }

    const validateUserInput = () => {

        const contactPattern  =/^[0-9]*$/;
        const emailPattern =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        //eslint-disable-next-line
        const passwordPattern = /(?=.*?[0-9])(?=.*?[A-Za-z])(?=.*[^0-9A-Za-z]).+/i

        let errors = {name: "", email: "", contact: "", password: ""}
        let valid = true
        if(!user?.name) {
            errors.name = "Name is required"
            valid = false
        }

        if (!user?.contact) {
            errors.contact = "Contact number is required"
            valid = false
        }  else if ( !contactPattern.test(user?.contact)) {
            errors.contact = "Contact number is incorrect"
            valid = false
        } else if (user?.contact?.length !== 10) {
            errors.contact = "Contact number must be 10 digits"
            valid = false
        }

        if (!user?.email) {
            errors.email = "Email is required"
            valid = false
        } else if (!emailPattern.test(user?.email)) {
            errors.email = "Email ID is incorrect"
            valid = false
        }

        if (!user?.password) {
            errors.password = "Password is required"
            valid = false
        } else if (user?.password !== user?.passwordConfirm) {
            errors.password = "Passwords do not match"
            valid = false
        } else if (!passwordPattern.test(user?.password)) {
            errors.password = "Password must have both alphabets, numbers and Special Characters"
            valid = false
        }  else if (user?.password?.length < 8) {
            errors.password = "Password must be minimum 8 characters"
            valid = false
        }

        setErrors(errors)
        return valid

    }

    const setLocalImage = (e) => {
        let reader = new FileReader();

        reader.readAsDataURL(e.target.files[0]);

        reader.onload = () => {
            console.log("Called", reader);
            const baseURL = reader.result;

            let newUser = {};
            Object.assign(newUser, user);
            newUser["imageBase64"] = baseURL
            setUser(newUser)

        }
    };

    return (
        <Container>
            <Row>
                <Col md={8}>
                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            {
                                InputBox((val) => setUserData(val, 'name'), user?.name, 'Name', errors.name)
                            }
                        </Col>
                    </Row>

                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            {
                                InputBox((val) => setUserData(val, 'email'), user?.email, 'Email', errors.email)
                            }
                        </Col>
                    </Row>

                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            {
                                InputBox((val) => setUserData(val, 'contact'), user?.contact, 'Contact', errors.contact)
                            }
                        </Col>
                    </Row>

                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            <InputGroup>
                                <Form.Control
                                    type={'password'}
                                    onChange={(val) => setUserData(val, 'password')}
                                    value={user?.password}
                                    placeholder={"Password"}
                                    aria-label={"Password"}
                                    aria-describedby="basic-addon1"
                                    isInvalid={!!errors.password}
                                    isValid = {errors.password === ''}/>
                                <Form.Control.Feedback type='invalid'>
                                    { errors.password }
                                </Form.Control.Feedback>
                                <Form.Control.Feedback>
                                    { "Looks Good!!" }
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            <InputGroup>
                                <FormControl
                                    type={'password'}
                                    onChange={(val) => setUserData(val, 'passwordConfirm')}
                                    value={user?.passwordConfirm}
                                    placeholder={"Confirm Password"}
                                    aria-label={"Confirm Password"}
                                    aria-describedby="basic-addon1"/>
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row style={{marginBottom: '20px'}}>
                        <Col md={3}>
                            <Button variant="primary" onClick={() => updateUser()}>Save</Button>
                        </Col>
                    </Row>
                </Col>

                <Col md={4}>

                   <Row style={{justifyContent: "center"}}>
                       <Col md={12} style={{textAlign: "center", marginBottom: "20px"}}>
                           {
                                user?.imageBase64 ?
                                   <div>
                                       <img style={{borderRadius: "10px", width: "250px", height: "250px"}}
                                            src={`${user.imageBase64}`} alt={'test'}/> :
                                   </div> : <div>
                                       <img style={{borderRadius: "10px", width: "250px", height: "250px"}}
                                            src={`${pathname}/default-image.png`} alt={"default"}/>
                                   </div>
                           }
                       </Col>


                       <Col md={8} style={{textAlign: "center"}}>
                           <Form.Group>
                               <Form.Control type="file" onChange={(e) =>  setLocalImage(e)} />
                           </Form.Group>
                       </Col>
                   </Row>

                </Col>





            </Row>


            <DialogModel
                message={dilogMessage}
                show={showDilog}
                onHide={() => hideDilogBox()}
            />

        </Container>
    )

}

export default SignupStudentComponent