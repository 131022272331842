import React, {useEffect} from "react"
import {Button, Col, Container, Row} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import AdminHeaderComponent from "./Header/Header.component";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {getAllOrganizations} from "../../Services/Organization.service";

const AllOrganizationsAdminComponent = (props) => {


    const history = useHistory()
    
    useEffect(() => {
        props.getAllOrganizations()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <Container style={{minHeight: "700px"}}>
            <AdminHeaderComponent />

            <Row style={{marginBottom: "10px"}}>
                <Col md={12}>
                    <Button onClick={()=> history.push("/admin/add-company")}>{'New Company'}</Button>
                </Col>
            </Row>

            <Row>
                {
                    props.allOrganizations && props.allOrganizations.map((organization, index)=> {
                        return (
                            <Link style={{textDecoration:"none"}} to={'/admin/companies/' + organization.id} key={index}>
                                <Row style={{border: "1px solid #dddddd", borderRadius:  "5px"}}>
                                <Col md={4} >
                                    <div style={{fontSize:"20px", color: "#424242", textTransform: "capitalize", padding:"5px", fontWeight: "bold"}}>
                                        {organization.name}
                                    </div>
                                </Col>
                                </Row>
                            </Link>
                        )
                    })
                }
            </Row>
        </Container>
    )


}

const mapStateToProps = (state) => ({
    allOrganizations: state.allOrganizations,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getAllOrganizations: getAllOrganizations,
        }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(AllOrganizationsAdminComponent);