import React, {useEffect, useState} from "react"
import moment from "moment";
import {Calendar, momentLocalizer} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as calendarService from "../../Services/Calendar.service"
import {getUserFromToken} from "../../Services/Admin.service";
import ScheduleModel from "../models/ScheduleModel.component";


const CustomCalendar = () => {

    const localizer = momentLocalizer(moment)
    const [eventList, setEventList] = useState([])
    const [showSchedule, setShowSchedule] = useState(false)
    const [eventObject, setEventObject] = useState({startDate: "", endDate: "", additionalInfo: {title: ""}, id: ""})

    useEffect(() => {

        const startOfMonth = moment().startOf('month').format('YYYY-MM-DD hh:mm');
        const endOfMonth = moment().endOf('month').format('YYYY-MM-DD hh:mm');

        const body = {
            startDate: startOfMonth,
            endDate: endOfMonth,
            userId: getUserFromToken().id
        }

        calendarService.getScheduleForGivenPeriod(body).then((result) => {

            if (result.data) {

                const tempList = []

                result.data.forEach((item) => {
                    tempList.push({
                        id: item.id,
                        title: item.additionalInfo.title,
                        start: new Date(moment(item.startDate).year(), moment(item.startDate).month(), moment(item.startDate).date(), moment(item.startDate).hour(), moment(item.startDate).minute(), 0),
                        end: new Date(moment(item.endDate).year(), moment(item.endDate).month(), moment(item.endDate).date(), moment(item.endDate).hour(), moment(item.endDate).minute(), 0)

                    })
                })
                console.log(tempList)
                setEventList(tempList)
            }

        }).catch((err) => {
            console.log(err?.response?.data?.message)
        })

    }, [])


    const updateEventObject = (newEventObject) => {
      setEventObject(newEventObject)
    }


    const editEvent = (event) => {

        let newEventObject = Object.assign({}, eventObject)
        newEventObject.startDate = moment(event.start).format('YYYY-MM-DD')
        newEventObject.endDate = moment(event.end).format('YYYY-MM-DD')
        newEventObject.additionalInfo.title = event.title
        newEventObject.id = event.id
        setEventObject(newEventObject)
        setShowSchedule(true)
    }

    const slotSelected = (event) => {
        let newEventObject = Object.assign({}, eventObject)
        newEventObject.startDate = moment(event.start).format('YYYY-MM-DD')
        newEventObject.endDate = moment(event.start).format('YYYY-MM-DD')
        newEventObject.additionalInfo.title = ""
        newEventObject.id = ""
        setEventObject(newEventObject)
        setShowSchedule(true)
    }

    const onNavigate = (date, view) => {
        let start, end;

        if (view === 'month') {
            start = moment(date).startOf('month').startOf('week')
            end = moment(date).endOf('month').endOf('week')
        }
        console.log(start, end);

        return console.log({start, end});
    }

    const updateSchedule = (saveSchedule) => {
        setShowSchedule(false)
        if (saveSchedule) {

            let promise
            if (eventObject.id) {
                const body = {
                    startDate: moment(eventObject.startDate).format('YYYY-MM-DDThh:mm:ssZ'),
                    endDate: moment(eventObject.endDate).format('YYYY-MM-DDThh:mm:ssZ'),
                    additionalInfo: {title: eventObject.additionalInfo.title},
                    userId: parseInt(getUserFromToken().id),
                    id: eventObject.id
                }

                promise = calendarService.updateSchedule(eventObject.id, body)
            }  else {
                const body = {
                    startDate:moment(eventObject.startDate).format('YYYY-MM-DDThh:mm:ssZ'),
                    endDate: moment(eventObject.endDate).format('YYYY-MM-DDThh:mm:ssZ'),
                    additionalInfo: {title: eventObject.additionalInfo.title},
                    userId: parseInt(getUserFromToken().id),
                }

                promise = calendarService.saveSchedule(body)
            }

            promise.then((result) => {
                const tempList = JSON.parse(JSON.stringify(eventList))

                if (eventObject.id) {
                    let index = tempList.findIndex((item)=>item.id === eventObject.id)
                    tempList.splice(index, 1)
                }


                tempList.push({
                    id: result.data.id || eventObject.id,
                    title: eventObject.additionalInfo.title,
                    start: new Date(moment(eventObject.startDate).year(), moment(eventObject.startDate).month(), moment(eventObject.startDate).date(), moment(eventObject.startDate).hour(), moment(eventObject.startDate).minute(), 0),
                    end: new Date(moment(eventObject.endDate).year(), moment(eventObject.endDate).month(), moment(eventObject.endDate).date(), moment(eventObject.endDate).hour(), moment(eventObject.endDate).minute(), 0)
                })


                setEventList(tempList)

                console.log(tempList)
            }).catch((err) => {
                console.log(err?.response?.data?.message || "There was some issue")
            })
        }
    }

    const deleteObject = (id) => {

        setShowSchedule(false)
        calendarService.deleteEvent(id).then((result)=> {

            const tempList = JSON.parse(JSON.stringify(eventList))

            const objectIndex = tempList.findIndex((item)=> item.id === id)

            if (objectIndex !== null) {
                tempList.splice(objectIndex, 1)
            }

            setEventList(tempList)

        })

    }

    return (
        <div style={{height: "700px"}}>
            <Calendar
                selectable={true}
                onSelectSlot={(event) => slotSelected(event)}
                onClickEvent={(event) => editEvent(event)}
                onView={(event) => editEvent(event)}
                onSelectEvent={(event) => editEvent(event)}
                onNavigate={(date, view) => onNavigate(date, view)}
                localizer={localizer}
                events={eventList}
                startAccessor="start"
                endAccessor="end"
                style={{height: 500}}
            />


            <ScheduleModel
                show={showSchedule}
                eventObject = {eventObject}
                updateEventObject = {(newEventObject) => updateEventObject(newEventObject)}
                onHide={(saveSchedule) => updateSchedule(saveSchedule)}
                removeElement = {(id) => deleteObject(id)}
            />

        </div>
    )


}

export default CustomCalendar