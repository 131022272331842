import React, {useEffect, useState} from "react"
import {Carousel, Col, Container, Row, Spinner} from "react-bootstrap";
import sliderTwoImage from "../../images/slider-2.jpg"
import sliderOneImage from "../../images/slider-1.jpg"
import sliderThreeImage from "../../images/slider-3.jpg"
import * as categoryService from "../../Services/Category.service"
import {Link} from "react-router-dom";
import "./Home.component.css"
import * as organizationService from "../../Services/Organization.service";


const HomeComponent = () => {

    const [categories, setCategories] = useState([])
    const [pathname] = useState(process.env.NODE_ENV === "development" ? "http://localhost:8080/api/image/file" : "https://www.learningplatform.in/api/image/file");
    const [dataLoading, setLoading] = useState(false)
    const [organizationDetails, setOrganizationDetails] = useState(null);

    useEffect(()=> {
        setLoading(true)
        categoryService.getAllCategories().then((result)=> {
            setLoading(false)
            setCategories(result.data)
        }).catch((err)=> {
            setLoading(false)
        })

        organizationService.getOrganizationWithUrl().then(result => {
            setOrganizationDetails(result.data)
        })

    },[])

    return (
        <div id={"home-component"}>
            <Container>
            <div style={{display: "flex"}}>
                <div style={{flex: "12"}}>
                    {
                        organizationDetails?.homepage?.carousal?.length > 0 ?
                            <Carousel indicators={false}>
                                {
                                    organizationDetails?.homepage?.carousal.map((item, index) => {
                                        return (
                                            <Carousel.Item key={index}>
                                                <img
                                                    style={{width: "800px", height: "620px"}}
                                                    className="d-none d-sm-block d-block w-100"
                                                    src={pathname + "/" + item.imageName}
                                                    alt="Second slide"
                                                />

                                                <img
                                                    style={{width: "800px", height: "200px"}}
                                                    className="d-block d-sm-none  w-100"
                                                    src={pathname + "/" + item.imageName}
                                                    alt="Second slide"
                                                />
                                            </Carousel.Item>
                                        )
                                    })
                                }
                            </Carousel> :
                            <Carousel indicators={false}>
                                <Carousel.Item>
                                    <img
                                        style={{width: "800px", height: "620px"}}
                                        className="d-none d-sm-block d-block w-100"
                                        src={sliderTwoImage}
                                        alt="Second slide"
                                    />

                                    <img
                                        style={{width: "800px", height: "200px"}}
                                        className="d-block d-sm-none  w-100"
                                        src={sliderTwoImage}
                                        alt="Second slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        style={{width: "800px", height: "620px"}}
                                        className="d-none d-sm-block d-block w-100"
                                        src={sliderOneImage}
                                        alt="Second slide"
                                    />

                                    <img
                                        style={{width: "800px", height: "200px"}}
                                        className="d-block d-sm-none  w-100"
                                        src={sliderOneImage}
                                        alt="Second slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        style={{width: "800px", height: "620px"}}
                                        className="d-none d-sm-block d-block w-100"
                                        src={sliderThreeImage}
                                        alt="Second slide"
                                    />

                                    <img
                                        style={{width: "800px", height: "200px"}}
                                        className="d-block d-sm-none  w-100"
                                        src={sliderThreeImage}
                                        alt="Second slide"
                                    />
                                </Carousel.Item>
                            </Carousel>
                    }
                </div>
            </div>
            </Container>
            <Container>
            <Row style={{marginTop: "20px"}}>
                <Col>
                    <div className={'categories-heading'}>
                        Categories
                    </div>

                    <div>
                        {
                            categories?.length > 0 ?
                                <Row className="justify-content-md-center">
                                    {
                                        categories.map((category, index)=>{
                                            return (
                                                <Col md={4} xs={12} sm={12} key={index}>
                                                    <Link to={`/categories/${category.slug}`} style={{textDecoration: "none"}}>
                                                        <div key={index} className={'course-image-container'} >
                                                            <img  className=" course-image" style={{width: "100%", height: "200px"}}
                                                                  src={`${pathname}/${category.imageName}`} alt="course" />
                                                        </div>
                                                        <div className={"course-name"}>
                                                            {category.name}{' '}({category.courseCount})
                                                        </div>
                                                    </Link>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>:
                                <Row>
                        {
                            dataLoading ?
                                <Col style={{textAlign: "center", justifyContent: "center", alignItems: "center"}}>
                                    <Spinner animation={"border"} />
                                </Col>: <Col style={{textAlign: "center"}}>You have no courses available yet.</Col>
                        }

                            </Row>
                        }

                    </div>
                </Col>
            </Row>

            <Row style={{marginTop: "20px"}}>
                <Col md={12}>
                    <div  className={'categories-heading'}>
                
                    </div>
                </Col>

                <Col md={12}>
                    <div className={'about-us-content'}>
                   </div>
                </Col>

            </Row>

                </Container>
        </div>
    )


}


export default HomeComponent