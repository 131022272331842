import React from "react";
import {Col, Row} from "react-bootstrap";

const ZoomMeeting = (props) => {

    return (

        <Col md={12} key={props.index}
             style={{marginBottom: "10px"}}>
            <Row>
                <Col md={12}>
                    <div
                        style={{fontWeight: "bold", marginBottom: "10px"}}>
                        {props.topic.title}
                    </div>
                </Col>

                <Col md={12}
                     style={{textAlign: "justify"}}>

                    <div>
                        For Joining Zoom Meeting <a href={props.topic.zoomLink} style={{fontWeight: "bold", textDecoration: "underline"}}>Click here</a>
                    </div>

                </Col>
            </Row>
        </Col>
    )

}

export default ZoomMeeting