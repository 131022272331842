export const getUserAuthenticationReducer = (state = null, action) => {
    switch (action.type) {
        case "USER_AUTHENTICATION_SUCCESS":
            return {
                data: action.data,
                loading: false,
                error: action.error
            }

        case "USER_AUTHENTICATION_FAILURE":
            return {
                data: null,
                loading: false,
                error: action.error
            }

        case "USER_AUTHENTICATION_IN_PROGRESS":
            return {
                data: null,
                loading: true,
                error: null
            }

        default:
            return state

    }
};