import axios from "axios";
import {allOrganizations} from "../actions/organizationAction";

const URL = process.env.NODE_ENV === "development" ? "/api/organizations" : "/api/organizations"


//export const getAllCourses = () => axios.get(URL + "/getall")
export const getOrganizationDetails = (id) => axios.get(URL + `/id/${id}`)
export const getOrganizationWithUrl = () => axios.get(`${URL}/get-organization-with-url`)
export const saveOrganization = (organization) => axios.post(URL + `/save-organization`, organization)
export const updateOrganization = (organization) => axios.put(URL + `/update-organization/${organization.id}`, organization)

export const getAllOrganizations = () => {
    return (dispatch) => {
        return axios.get(URL + "/get-all")
            .then((res) => {
                dispatch(allOrganizations(res.data))
            })
            .catch((err) => {
                console.log(err)
            })
    }
};
