import React, {useEffect, useState} from "react"
import {Button, Col, Container, Row} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import AdminHeaderComponent from "./Header/Header.component";
import * as categoryService from "../../Services/Category.service"
import DialogModel from "../models/DialogModel.component";

const AllCategories = () => {

    const [allCategories, setAllCategories] = useState(null);
    const [dilogMessage, setDilog] = useState("");
    const [showDilog, setShowDilog] = useState(false);
    const history = useHistory();

    useEffect(() => {
        categoryService.getAllCategoriesAdmin().then((result)=>{
            setAllCategories(result.data)
        })

    },[])

    const deleteCategory = (id) => {
        categoryService.deleteCategory(id).then((result) => {

            let tempList = JSON.parse(JSON.stringify(allCategories))

            const index = tempList.findIndex((cat) => cat.id === id)

            if (index != null) {
                tempList.splice(index, 1)
            }

            setAllCategories(tempList)

        }).catch((err) => {
            setDilog(err?.response?.data?.message || "There was some issue in deleting the category")
            setShowDilog(true)
        })
    }

    return (
        <Container style={{minHeight: "700px"}}>
            <AdminHeaderComponent />

            <Row style={{marginBottom: "10px"}}>
                <Col md={12}>
                    <Button onClick={()=> history.push("/admin/categories/add-category")}>{'New Category'}</Button>
                </Col>
            </Row>

            <Row>
                {
                    allCategories && allCategories.map((category, index)=> {
                        return (
                            <div key={index}>
                                <Row style={{border: "1px solid #dddddd", borderRadius:  "5px"}} >
                                    <Col md={4} >
                                        <Link style={{textDecoration: "none"}} to={'/admin/categories/' + category.id} key={index}>
                                        <div style={{fontSize:"20px", color: "#424242", textTransform: "capitalize", padding:"5px", fontWeight: "bold"}}>
                                            {category.name}
                                        </div>
                                        </Link>
                                    </Col>

                                    <Col md={4} >
                                        <div style={{fontSize:"20px", color: "#424242", textTransform: "capitalize", padding:"5px", fontWeight: "bold"}}>
                                            <Button onClick={() => deleteCategory(category.id)} >Delete</Button>
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                        )
                    })
                }
            </Row>

            <DialogModel
                message={dilogMessage}
                show={showDilog}
                onHide={() => setShowDilog(false)}
            />
        </Container>
    )

}

export default AllCategories