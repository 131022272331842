import React, {useEffect} from "react"
import {Accordion, Button, Card, Col, Container, FormControl, InputGroup, Row} from "react-bootstrap";
import WisywigEditor from "../WisywigEditor.component";
import EditTopic from "./EditTopic.component";
import EditTopicQuiz from "./EditTopicQuiz.component";
import EditH5P from "./EditH5P.component";
import EditZoomLink from "./EditZoomLink.component";

const EditChapter = (props) => {

    useEffect(() => {

    }, [])

    const updateChapter = (event, index, element) => {
        let newCourse = {};
        Object.assign(newCourse, props.courseDetails);
        newCourse.chapterList[index][element] = event.target.value
        props.updateCourse(newCourse)
    }

    const updateChapterWisywig = (value, index, element) => {

        let newCourse = {};
        Object.assign(newCourse, props.courseDetails);
        newCourse.chapterList[index][element] = value
        props.updateCourse(newCourse)
    }

    const updateChapterTopic = (value, index, contentIndex, element) => {
        let newCourse = {};
        Object.assign(newCourse, props.courseDetails);
        newCourse.chapterList[index]["content"][contentIndex][element] = value
        props.updateCourse(newCourse)
    }

    const updateChapterTopicWisyWig = (value, index, contentIndex, element) => {
        let newCourse = {};
        Object.assign(newCourse, props.courseDetails);
        newCourse.chapterList[index]["content"][contentIndex][element] = value
        props.updateCourse(newCourse)
    }

    const addNewTopic = (chapterKey) => {

        let newCourse = {};
        Object.assign(newCourse, props.courseDetails);
        let newTopic = {
            title: "",
            text: "",
            video: "",
            order: "",
            type: "text"
        }

        if (!newCourse?.chapterList[chapterKey]["content"]) {
            newCourse.chapterList[chapterKey]["content"] = []
        }

        newCourse?.chapterList[chapterKey]["content"].push(newTopic)
        props.updateCourse(newCourse)
    }

    const addNewTopicQuiz = (chapterKey) => {

        let newCourse = {};
        Object.assign(newCourse, props.courseDetails);
        let newTopic = {
            title: "",
            type: "quiz",
            quiz: [
                {
                    question: "",
                    answers: ["", "", "", ""],
                    correctAnswer: -1,
                    order: "",

                }
            ],

        }

        if (!newCourse?.chapterList[chapterKey]["content"]) {
            newCourse.chapterList[chapterKey]["content"] = []
        }

        newCourse?.chapterList[chapterKey]["content"].push(newTopic)
        props.updateCourse(newCourse)
    }

    const addNewTopicH5P = (chapterKey) => {

        let newCourse = {};
        Object.assign(newCourse, props.courseDetails);
        let newTopic = {
            title: "",
            order: "",
            type: "h5p",
            contentId: "new"
        }

        if (!newCourse?.chapterList[chapterKey]["content"]) {
            newCourse.chapterList[chapterKey]["content"] = []
        }

        newCourse?.chapterList[chapterKey]["content"].push(newTopic)
        props.updateCourse(newCourse)
    }


    const addZoomLink = (chapterKey) => {

        let newCourse = {};
        Object.assign(newCourse, props.courseDetails);
        let newTopic = {
            title: "",
            order: "",
            zoomLink: "",
            type: "zoom",
        }

        if (!newCourse?.chapterList[chapterKey]["content"]) {
            newCourse.chapterList[chapterKey]["content"] = []
        }

        newCourse?.chapterList[chapterKey]["content"].push(newTopic)
        props.updateCourse(newCourse)
    }




    const deleteTopic = (chapterIndex, topicIndex) => {

        let newCourse = {};
        Object.assign(newCourse, props.courseDetails);

        //newCourse?.chapterList[chapterIndex].content[topicIndex]

        newCourse?.chapterList.forEach((chapter, index) => {
            if (index === chapterIndex) {
                chapter.content.forEach((topic, t) => {
                    if (t === topicIndex) {
                        chapter.content.splice(t, 1);
                    }
                })
            }
        });

        props.updateCourse(newCourse)
    }

    const deleteChapter = (chapterId) => {

        let newCourse = {};
        Object.assign(newCourse, props.courseDetails);
        const chapterIndex = newCourse.chapterList.findIndex((ch) => ch.id === chapterId)

        if (chapterIndex !== -1) {
            newCourse.chapterList.splice(chapterIndex, 1)
        }

        props.updateCourse(newCourse)
        //setShowDilogDeleteChapter(false)


        // chapterService.deleteChapter(chapterId).then((result) => {
        //     setDeleteChapterId(chapterId)
        //     setDilog(result.data.message)
        //     setShowDilogDeleteChapter(true)
        // }).catch((err) => {
         //   setDilog("Chapter has been deleted")
         //   setShowDilogDeleteChapter(true)
      //  })
    }

    // const deleteChapterCloseDilog = () => {
    //
    //     let newCourse = {};
    //     Object.assign(newCourse, props.courseDetails);
    //     const chapterIndex = newCourse.chapterList.findIndex((ch) => ch.id === deleteChapterId)
    //
    //     if (chapterIndex !== -1) {
    //         newCourse.chapterList.splice(chapterIndex, 1)
    //     }
    //
    //     props.updateCourse(newCourse)
    //     setShowDilogDeleteChapter(false)
    // }




    const getAccordion = (chapter, key) => {
        return (
            <Container key={key}>
                <Card key={key} style={{marginBottom: "20px"}}>
                    <Accordion.Item eventKey={key} key={key} style={{
                        letterSpacing: '1px',
                        fontSize: '18px',
                        cursor: 'pointer'

                    }}>
                        <Accordion.Header style={{textTransform: "capitalize"}}>
                        <span style={{margin: "10px"}}>
                        {chapter.name}
                        </span>


                        </Accordion.Header>
                        <Accordion.Body>
                            <Card.Body style={{
                                fontSize: '18px',
                                letterSpacing: '1px'
                            }} className={"secondary-font-family"}>

                                <Col md={12} style={{marginBottom: "10px"}}>
                                    <InputGroup>
                                        <FormControl
                                            onChange={(val) => updateChapter(val, key, 'name')}
                                            value={chapter.name}
                                            placeholder="Chapter Name"
                                            aria-label="Chapter Name"
                                            aria-describedby="basic-addon1"/>


                                        <Button onClick={() => deleteChapter(chapter.id)}>Delete Chapter</Button>

                                    </InputGroup>


                                </Col>


                                <Col md={12} style={{marginBottom: '20px'}}>
                                    <WisywigEditor
                                        placeholder="Excerpt"
                                        aria-label="Excerpt"
                                        value={chapter.excerpt}
                                        updateDescription={(val) => updateChapterWisywig(val, key, 'excerpt')}
                                    />
                                </Col>


                                <Row>
                                    <Col md={3} style={{marginBottom: "10px"}}>
                                        <Button style={{border: "none"}} variant="secondary"
                                                onClick={() => addNewTopic(key)}>
                                            +{' '}Add Topic Content
                                        </Button>
                                    </Col>

                                    <Col md={3}>
                                        <Button style={{border: "none"}} variant="secondary"
                                                onClick={() => addNewTopicQuiz(key)}>
                                            +{' '}Add Topic Quiz
                                        </Button>
                                    </Col>


                                    <Col md={3}>
                                        <Button style={{border: "none"}} variant="secondary"
                                                onClick={() => addNewTopicH5P(key)}>
                                            +{' '}Add Topic H5P
                                        </Button>
                                    </Col>

                                    <Col md={3}>
                                        <Button style={{border: "none"}} variant="secondary"
                                                onClick={() => addZoomLink(key)}>
                                            +{' '}Add Zoom Link
                                        </Button>
                                    </Col>
                                </Row>

                                <Col md={12} style={{marginBottom: "10px"}}>
                                    {
                                        chapter.content && chapter.content.map((topic, i) => {
                                            return (
                                                <div key={i}>
                                                    {
                                                        topic.type === 'text' || !topic.type ?
                                                            <EditTopic
                                                                topic={topic}
                                                                updateChapterTopic={(event, element) => updateChapterTopic(event, key, i, element)}
                                                                updateChapterTopicWisyWig={(value, element) => updateChapterTopicWisyWig(value, key, i, element)}
                                                                deleteTopic={() => deleteTopic(key, i)}
                                                            /> : <span></span>
                                                    }


                                                    {
                                                        topic.type === 'quiz' ?
                                                            <EditTopicQuiz
                                                                topic={topic}
                                                                updateChapterTopic={(value, element) => updateChapterTopic(value, key, i, element)}
                                                                deleteTopic={() => deleteTopic(key, i)}
                                                            /> : <span></span>
                                                    }

                                                    {
                                                        topic.type === 'h5p' && !topic.delete ?
                                                            <EditH5P
                                                                topic={topic}
                                                                updateChapterTopic={(value, element) => updateChapterTopic(value, key, i, element)}
                                                                deleteTopic={() => deleteTopic(key, i)}
                                                            /> : <span></span>
                                                    }

                                                    {
                                                        topic.type === 'zoom' ?
                                                            <EditZoomLink
                                                                topic={topic}
                                                                updateChapterTopic={(value, element) => updateChapterTopic(value, key, i, element)}
                                                                deleteTopic={() => deleteTopic(key, i)}
                                                            /> : <span></span>
                                                    }

                                                </div>


                                            )
                                        })
                                    }
                                </Col>


                            </Card.Body>
                        </Accordion.Body>
                    </Accordion.Item>
                </Card>

                {/*<DialogModel*/}
                {/*    message={dilogMessage}*/}
                {/*    show={showDilogDeleteChapter}*/}
                {/*    onHide={() => deleteChapterCloseDilog()}*/}
                {/*/>*/}

            </Container>
        )
    };


    return (
        <Accordion defaultActiveKey="1">
            {
                props.courseDetails?.chapterList && props.courseDetails.chapterList.map((chapter, index) => {
                    return getAccordion(chapter, index)
                })
            }
        </Accordion>
    )


}

export default EditChapter