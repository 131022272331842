import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import * as userService from "../../Services/User.service"
//import {useAuth} from "../../Services/Auth.service";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {signIn} from "../../Services/Admin.service";
import {useLocation} from "react-router-dom";
import DialogModel from "../models/DialogModel.component";
import "./ForgotPassword.component.css"

const ForgotPassword = (props) => {

    const location = useLocation()
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [dilogMessage, setDilog] = useState("");
    const [showDilog, setShowDilog] = useState(false);
    const [emailSentSuccess, setEmailSentSuccess] = useState(false)

    useEffect(() => {
        if(props.userAuthenticated?.data?.userAuthentic) {
            history.push({
                pathname: location.from || "/",
                state: location.state
            });
        }

    }, [props.userAuthenticated, history, location]);


    const getForgotPasswordEmail = () => {

        userService.forgotPassword(email).then((response)=>{
            if (response.data.emailSent) {
                setEmailSentSuccess(true)
                setDilog("Check you Email for changing password")
                setShowDilog(true)
            } else {
                setDilog("There was some issue. Please try again")
                setShowDilog(true)
            }

        }).catch((error)=>{
            setDilog(error.response?.data?.error || "There was some issue. Please try again")
            setShowDilog(true)
        })
    }

    const hideDilogBox = () => {
        setShowDilog(false)

        if (emailSentSuccess) {
            history.push("/login")
        }
    }

    return (
        <Container id={'forgot-password'}>

            <Row style={{margin: "150px auto", justifyContent:"center"}}>
                <Col md={3}></Col>
                <Col  md={6} className={'forgot-box'}>

                    <Row style={{textAlign: "left", marginBottom: "20px"}}>
                        <Col md={12} style={{marginBottom: "20px"}}>
                            <div style={{fontSize: "30px"}}>Forgot Password</div>
                        </Col>


                        <Col md={12} className={'small-text'}>
                        Please enter your email below and we will send you instructions for setting a new password.
                        </Col>
                    </Row>

                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type={email}
                                    className={'email-input-box'}
                                    onChange={(event) => setEmail(event.target.value)}
                                    value={email}
                                    placeholder={"example@gmail.com"}
                                    aria-label={"Email"}
                                    aria-describedby="basic-addon1"/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row style={{marginBottom: '20px'}}>
                        <Col md={6} xs={12} sm={12} style={{marginTop: "5px"}}>
                            <Button style={{ width:"100%"}} variant="primary" onClick={() => getForgotPasswordEmail()}>Recover Password</Button>
                        </Col>



                        <Col md={3} xs={12} sm={12} style={{marginTop: "5px"}}>
                            <Button style={{ width:"100%"}} variant="primary" onClick={() => history.push('/signup')}>SignUp</Button>
                        </Col>

                    </Row>


                </Col>
                <Col md={3}></Col>
            </Row>


            <DialogModel
                message={dilogMessage}
                show={showDilog}
                onHide={() => hideDilogBox()}
            />

        </Container>
    )

}

const mapStateToProps = (state) => ({
    userAuthenticated: state.userAuthenticated,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            loginUser: signIn
        }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
