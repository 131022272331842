import React, {useEffect} from "react"
import {Button, Col, FormControl, Row} from "react-bootstrap";

const EditTopicQuiz = (props) => {

    useEffect(() => {

    }, [])


    const updateAnswer = (value, index, i) => {
        let newQuiz = JSON.parse(JSON.stringify(props.topic.quiz))
        newQuiz[i].answers[index] = value
        props.updateChapterTopic(newQuiz, 'quiz')
    }

    const updateCorrectAnswer = (index, i) => {
         let newQuiz = JSON.parse(JSON.stringify(props.topic.quiz))
         newQuiz[i].correctAnswer = index
         props.updateChapterTopic(newQuiz, 'quiz')
    }

    const updateQuestion = (value, i) => {
        let newQuiz = JSON.parse(JSON.stringify(props.topic.quiz))
        newQuiz[i].question = value
        props.updateChapterTopic(newQuiz, 'quiz')
    }


    const addQuestion = () => {

        let newTopic = {};
        Object.assign(newTopic, props.topic)

        newTopic.quiz.push({
            question: "",
            answers: ["", "", "", ""],
            correctAnswer: -1,
            order: "",
        })

        props.updateChapterTopic(newTopic.quiz, 'quiz')

    }

    return (
        <Row style={{marginBottom: "50px"}}>

            <Col md={12} style={{marginBottom: "10px"}}>
                <FormControl
                    onChange={(event) => props.updateChapterTopic(event.target.value, 'title')}
                    value={props.topic.title}
                    placeholder="Title"
                    aria-label="Title"
                    aria-describedby="basic-addon1"/>
            </Col>

            <Col style={{marginBottom: "10px"}}>
                <Button onClick={()=> addQuestion()}>Add Question</Button>
            </Col>

            {
                props?.topic?.quiz && props.topic.quiz.map((q, i) => {
                    return (
                        <div key={i}>
                            <Col md={12} style={{marginBottom: "10px"}}>
                                <FormControl
                                    onChange={(event) => updateQuestion(event.target.value, i)}
                                    value={q.question}
                                    placeholder="Question"
                                    aria-label="Question"
                                    aria-describedby="basic-addon1"/>
                            </Col>

                            {
                                q.answers.map((ans, index) => {
                                    return (
                                        <Row key={index}>
                                            <Col md={6} style={{marginBottom: "10px"}}>
                                                <FormControl
                                                    key={index}
                                                    onChange={(event) => updateAnswer(event.target.value, index, i)}
                                                    value={ans}
                                                    placeholder="Answer"
                                                    aria-label="Answer"
                                                    aria-describedby="basic-addon1"/>
                                            </Col>

                                            <Col md={6} style={{marginBottom: "10px"}}>
                                                <Button
                                                     style={{color: q.correctAnswer === index ? "black" : "white"}}
                                                    onClick={() => updateCorrectAnswer(index, i)}>Correct
                                                    Answer</Button>
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }

            <Button onClick={() => props.deleteTopic()}>Delete Topic</Button>

        </Row>
    )

}

export default EditTopicQuiz