import React, {useEffect, useState} from "react"
import * as categoryService from "../../Services/Category.service"
import {useParams} from "react-router-dom";
import AdminHeaderComponent from "./Header/Header.component";
import {Button, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {getAllOrganizations} from "../../Services/Organization.service";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import DialogModel from "../models/DialogModel.component";
import defaultImage from "../../images/default-image.png"

const EditCategoryComponent = (props) => {

    const [categoryDetails, setCategoryDetails] = useState({});
    const [pathname] = useState(process.env.NODE_ENV === "development" ? "http://localhost:8080/api/image/file" : "https://www.learningplatform.in/api/image/file");
    // const history = useHistory()
    const {id} = useParams();

    const [dilogMessage, setDilog] = useState("");
    const [showDilog, setShowDilog] = useState(false);

    useEffect(() => {

        if (id) {
            categoryService.getCategoryDetailsAdmin(id).then((result) => {
                setCategoryDetails(result.data)
            })
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const setCategoryData = (event, element) => {
        let newUser = {};
        Object.assign(newUser, categoryDetails);
        newUser[element] = event.target.value;
        setCategoryDetails(newUser)
    };

    const InputBox = (method, value, placeholder) => {
        return (
            <InputGroup>
                <FormControl
                    onChange={method}
                    value={value || ""}
                    placeholder={placeholder}
                    aria-label={placeholder}
                    aria-describedby="basic-addon1"/>
            </InputGroup>
        )
    };


    const setLocalImage = (e) => {

        let reader = new FileReader();

        reader.readAsDataURL(e.target.files[0]);

        reader.onload = () => {
            const baseURL = reader.result;
            let newCategory = {};
            Object.assign(newCategory, categoryDetails);
            newCategory["imageBase64"] = baseURL
            setCategoryDetails(newCategory)
        }

    };


    const updateCategory = () => {
        if (!categoryDetails.id) {
            categoryService.saveCategory(categoryDetails).then((result) => {

                let newCategory = {};
                Object.assign(newCategory, categoryDetails);
                newCategory.id = result.data.id
                setCategoryDetails(newCategory)

                setDilog("Category has been successfully saved")
                setShowDilog(true)
            }).catch((err) => {
                setDilog(err?.response?.data?.message || "There was some issue in saving the data")
                setShowDilog(true)
            })
        } else {
            categoryService.updateCategory(categoryDetails, categoryDetails.id).then((result) => {
                setDilog("Category has been successfully updated")
                setShowDilog(true)
            })
        }
    }

    return (
        <Container>
            <AdminHeaderComponent/>

            <Row>
                <Col md={8}>
                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            {
                                InputBox((val) => setCategoryData(val, 'name'), categoryDetails?.name, 'Name')
                            }
                        </Col>


                        <Col md={12} style={{marginBottom: "10px"}}>
                            <InputGroup>
                                <FormControl as="textarea" rows={6}
                                             onChange={(val) => setCategoryData(val, 'excerpt')}
                                             value={categoryDetails?.excerpt}
                                             placeholder="Excerpt"
                                             aria-label="Excerpt"
                                             aria-describedby="basic-addon1"/>
                            </InputGroup>
                        </Col>


                    </Row>

                    <Row style={{marginBottom: '20px'}}>
                        <Col md={3}>
                            <Button variant="primary" onClick={() => updateCategory()}>Save</Button>
                        </Col>
                    </Row>
                </Col>


                <Col md={4}>
                    <Row style={{marginBottom: "10px", textAlign: "center", justifyContent: "center", alignItems: "center"}}>
                        <Col md={12}>
                            {
                                categoryDetails?.imageName || categoryDetails?.imageBase64 ?
                                    <img style={{borderRadius: "10px", width: "250px", height: "250px"}}
                                         src={`${categoryDetails.imageBase64 ? categoryDetails.imageBase64 : pathname + "/" + categoryDetails.imageName}`}
                                         alt="course"
                                    /> :
                                    <div>
                                        <img style={{borderRadius: "10px", width: "250px", height: "250px"}}
                                             src={defaultImage}
                                             alt="default"/>
                                    </div>
                            }
                        </Col>
                    </Row>


                    <Row style={{marginBottom: "10px"}}>
                        <Col md={12}>
                            <Form.Group>
                                <Form.Control type="file" onChange={(e) => setLocalImage(e)}/>
                            </Form.Group>
                        </Col>
                    </Row>

                </Col>


            </Row>


            <DialogModel
                message={dilogMessage}
                show={showDilog}
                onHide={() => setShowDilog(false)}
            />


        </Container>
    )

}

const mapStateToProps = (state) => ({
    allOrganizations: state.allOrganizations,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getAllOrganizations: getAllOrganizations,
        }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCategoryComponent);
