import React, {useEffect, useState} from "react"
import {Button, Col, Container, Row} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import AdminHeaderComponent from "./Header/Header.component";
import {bindActionCreators} from "redux";
import * as courseService from "../../Services/Course.service";
import {connect} from "react-redux";
import DialogModel from "../models/DialogModel.component";
import ConfirmModel from "../models/ConfirmModel.component";

const AllCoursesAdminComponent = (props) => {

    const history = useHistory()
    const [dilogMessage, setDilog] = useState("");
    const [showDilog, setShowDilog] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState("");
    const [courseId, setCourseId] = useState(0)

    useEffect(() => {
        props.getAllCourses()

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const deleteCourseConfirm = (id) => {
        setCourseId(id)
        setConfirmMessage("Are you sure you want to delete the course ?")
        setShowConfirm(true)
    }

    const deleteCourse = () => {

        courseService.deleteCourse(courseId).then((result) => {

            let newCourseList = JSON.parse(JSON.stringify(props.allCourses));

            const deletedCourseIndex = newCourseList.findIndex((course)=> course.id === courseId)

            if (deletedCourseIndex !== null && deletedCourseIndex !== undefined) {
                newCourseList.splice(deletedCourseIndex, 1)
                props.updateCourseList(newCourseList)
            }

            setDilog(result.data.message)
            setShowDilog(true)
            setUpdateSuccess(true)
        }).catch((err) => {
            setDilog(err?.response?.data?.message)
            setShowDilog(true)
        })
    }

    const updateDilogBox = () => {
        setShowDilog(false)

        if (updateSuccess) {

        }
    }

    const updateConfirmBox = (confirmed) => {

        setShowConfirm(false)

        if (confirmed) {
            deleteCourse()
        }

    }

    return (
        <Container style={{minHeight: "700px"}}>
            <AdminHeaderComponent/>

            <Row style={{marginBottom: "10px"}}>
                <Col md={12}>
                    <Button onClick={()=> history.push("/admin/courses/add-course")}>{'New Course'} <b></b></Button>
                </Col>
            </Row>

            <Row>
                {
                    props.allCourses && props.allCourses.map((course, index) => {
                        return (
                            <div style={{marginBottom: "10px"}} key={index}>
                                <Row style={{border: "1px solid #dddddd", borderRadius: "5px", padding: "5px"}}>

                                    <Col md={4}>
                                        <Link style={{textDecoration: "none"}} to={'/admin/courses/' + course.id}
                                              >
                                            <div style={{
                                                fontSize: "20px",
                                                color: "#424242",
                                                textTransform: "capitalize",
                                                padding: "5px",
                                                fontWeight: "bold"
                                            }}>
                                                {course.name}
                                            </div>
                                        </Link>
                                    </Col>

                                    <Col md={4}>
                                        <Button onClick={() => deleteCourseConfirm(course.id)}>Delete</Button>
                                    </Col>
                                </Row>

                            </div>
                        )
                    })
                }
            </Row>


            <DialogModel
                message={dilogMessage}
                show={showDilog}
                onHide={() => updateDilogBox()}
            />

            <ConfirmModel
                message={confirmMessage}
                show={showConfirm}
                onHide={(val) => updateConfirmBox(val)}
            />

        </Container>
    )


}

const mapStateToProps = (state) => ({
    allCourses: state.allCourses,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getAllCourses: courseService.getAllCoursesAdmin,
            updateCourseList: courseService.updateCourseList
        }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(AllCoursesAdminComponent);