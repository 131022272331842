

export const allOrganizations = (data) => {
    return {
        type: "STORE_ALL_ORGANIZATIONS",
        data
    }
};

export const currentOrganization = (data) => {
    return {
        type: "STORE_CURRENT_ORGANIZATION",
        data
    }
}

export const loading = ( loading ) => {
    return {
        type: "LOADING",
        loading,
    }
};







