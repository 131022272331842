import React, {useEffect, useState} from "react"
import {Button, Col, Row} from "react-bootstrap";
import ReactPlayer from "react-player";

const Content = (props) => {

    const [pathname] = useState(process.env.NODE_ENV === "development" ? "http://localhost:8080/api/image/file" : "https://www.learningplatform.in/api/image/file");


    useEffect(()=>{

    },[])


    return (
        <Col md={12} key={props.index}
             style={{marginBottom: "10px"}}>
            <Row>
                <Col md={12}>
                    <div
                        style={{fontWeight: "bold", marginBottom: "10px"}}>
                        {props.topic.title}
                    </div>
                </Col>

                <Col md={12}
                     style={{textAlign: "justify"}}>
                    <div>
                        <Row>
                            <Col md={4}>
                                <div>
                                    {
                                        props.chapterDetails?.imageName ?
                                            <img
                                                style={{
                                                    borderRadius: "10px",
                                                    width: "100%",
                                                    height: "300px"
                                                }}
                                                src={`${pathname}/${props.chapterDetails?.imageName}`}
                                                alt="chapter"/> :
                                            <span></span>
                                    }
                                </div>
                            </Col>
                        </Row>

                        <div>
                            <span dangerouslySetInnerHTML={{__html: props.topic.text}}/>
                        </div>

                        <div>
                            {
                                props.topic.video ?
                                    <div>
                                        <ReactPlayer
                                            playing={false}
                                            controls={true}
                                            width="100%"
                                            url={`${props.topic.video}`}/>
                                    </div>: <span></span>
                            }
                        </div>

                        <div style={{marginTop: "10px", textAlign: "right"}}>
                            {
                                !props.isTopicComplete(props.topicIndex - 1) ?

                                    <Button style={{
                                        border: "none",
                                        borderRadius: "15px"
                                    }}
                                            variant="secondary"
                                            onClick={() => props.markCourseComplete(props.topicIndex - 1)}>
                                        Mark as Complete
                                    </Button> : <div></div>

                            }
                        </div>
                    </div>
                </Col>
            </Row>
        </Col>
    )


}

export default Content