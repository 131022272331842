import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import {
    getAllCoursesReducer
} from "../reducers/Course.Reducer";
import {getUserAuthenticationReducer} from "../reducers/userAuthenticated.Reducer";
import {currentOrganizationReducer, getAllOrganizationsReducer} from "../reducers/Organization.Reducer";

const reducer = combineReducers( {
    allCourses: getAllCoursesReducer,
    allOrganizations: getAllOrganizationsReducer,
    userAuthenticated: getUserAuthenticationReducer,
    currentOrganization: currentOrganizationReducer
});

const store = createStore( reducer, {}, applyMiddleware( thunkMiddleware ) );

export default store

