import React from "react"
import "./Footer.css"
import {Link} from "react-router-dom";

const FooterComponent = () => {

    return (
        <div id="footer">
            <div style={{display: "flex"}}>
                    <div style={{flex: "12"}}>
                        <div className="justify-content-center">
                            <div style={{textAlign: "center"}}>
                               

                                <Link to="/privacy-policy"
                                      className="footer-link font-family"
                                      style={{textAlign: "justify"}}>
                                    {`Privacy Policy`}</Link>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default FooterComponent