import React, {useEffect, useState} from "react"
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import AdminHeaderComponent from "../Header/Header.component";
import * as userService from "../../../Services/User.service";
import sampleCSV from "../../../images/sample-csv.csv";
import "./AllUsers.component.css"
import DialogModel from "../../models/DialogModel.component";

const AllUsersComponent = () => {

    const history = useHistory()
    const [allUsers, setAllUsers] = useState([]);
    const [csvFile, setCSVFile] = useState(null);
    const [dilogMessage, setDilog] = useState("");
    const [showDilog, setShowDilog] = useState(false);

    useEffect(() => {
        userService.getAllStudents().then((result) => {
            return result.data
        }).then((val) => {
            return userService.getAllInstructors().then((result) => {
                let newResultSet = val || []
                if (result.data) {
                    return newResultSet.concat(result.data)
                } else {
                    return newResultSet
                }
            })
        }).then((val2)=> {
            userService.getAllAdmins().then((result) => {
                let newResultSet = val2 || []
                if (result.data) {
                    setAllUsers(newResultSet.concat(result.data))
                } else {
                    setAllUsers(newResultSet)
                }
            })
        })

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const uploadUserList = () => {

        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "file", csvFile
        );

        userService.uploadUserList(formData).then((result) => {
            console.log(result.data)
        }).catch((err) => {
            console.log(err?.response?.body?.message || "There was some issue in uploading CSV file")
        })

    }

    const uploadCSV = (e) => {
        setCSVFile(e.target.files[0])
    }

    const getRole = (roleId) => {

        switch (roleId) {
            case 2:
                return "ADMIN"
            case 3:
                return "INSTRUCTOR"
            case 4 :
            case 5:
                return "STUDENT"
            default:
                return "USER"
        }
    }

    const updateUserSendEmail = (userId) => {
        let newUserList = JSON.parse(JSON.stringify(allUsers));

        const userIndex = newUserList.findIndex((user) => user.id === userId)

        if (userIndex !== null) {
            newUserList[userIndex]["sendEmail"] = !newUserList[userIndex]["sendEmail"]
        }

        setAllUsers(newUserList)
    }

    const sendEmailForPasswordChange = () => {

        let tempList = []
        allUsers.forEach((user) => {
            if (!!user.sendEmail) {
                tempList.push(user.id)
            }
        })

        const body = {
            "userIdList": tempList
        }

        if (allUsers?.length > 0) {
            userService.sendEmailForPasswordReset(body).then((result) => {
                setDilog("Email has been successfully sent to all users")
                setShowDilog(true)
            }).catch((err) => {
                setDilog("There was some issue in sending email to all users")
                setShowDilog(true)
            })
        }

    }

    return (
        <Container style={{minHeight: "700px"}} id={'all-users-admin'}>
            <AdminHeaderComponent/>

            <Row style={{marginBottom: "10px", alignItems: "center"}}>
                <Col md={2}>
                    <Form.Group>
                        <Form.Control type="file" onChange={(e) => uploadCSV(e)}/>
                    </Form.Group>
                </Col>

                <Col md={2}>

                    <Button onClick={() => uploadUserList()}>Upload CSV</Button>
                </Col>

                <Col md={3}>
                    <Link className={'download-link'} to={sampleCSV} target="_blank" download>Download Sample CSV</Link>
                </Col>

                <Col md={2}>
                    <Button onClick={() => history.push("/admin/users/add-user")}>{'New User'}</Button>
                </Col>

                <Col md={3}>
                    <Button onClick={() => sendEmailForPasswordChange()}>{'Send Email'}</Button>
                </Col>

            </Row>

            <Row>
                {
                    allUsers && allUsers.map((user, index) => {
                        return (
                            <Row style={{border: "1px solid #dddddd", borderRadius: "5px"}} key={index}>
                                <Col md={4}>
                                    <Link style={{textDecoration: "none"}} to={'/admin/users/' + user.id} key={index}>
                                        <div style={{
                                            fontSize: "20px",
                                            color: "#424242",
                                            textTransform: "capitalize",
                                            padding: "5px",
                                            fontWeight: "bold"
                                        }}>
                                            {user.name}
                                        </div>
                                    </Link>
                                </Col>

                                <Col md={4}>
                                    <div style={{
                                        fontSize: "20px",
                                        color: "#424242",
                                        textTransform: "capitalize",
                                        padding: "5px",
                                        fontWeight: "bold"
                                    }}>
                                        {getRole(user.roleId)}
                                    </div>
                                </Col>

                                <Col>
                                    <Form.Check
                                        type={'checkbox'}
                                        checked={!!user.sendEmail}
                                        onChange={() => updateUserSendEmail(user.id)}
                                    />
                                </Col>
                            </Row>
                        )
                    })
                }
            </Row>

            <DialogModel
                message={dilogMessage}
                show={showDilog}
                onHide={() => setShowDilog(false)}
            />
        </Container>
    )
    
}

export default AllUsersComponent