import React, {useEffect} from "react"
import {Button, Col, FormControl, Row} from "react-bootstrap";


const EditZoomLink = (props) => {

    useEffect(() => {}, [])

    return (
        <Row  style={{marginBottom: "50px"}}>
            <Col md={12} style={{marginBottom: "10px"}}>
                <FormControl
                    onChange={(event) => props.updateChapterTopic(event.target.value, 'title')}
                    value={props.topic.title}
                    placeholder="Title"
                    aria-label="Title"
                    aria-describedby="basic-addon1"/>
            </Col>

            <Col md={12} style={{marginBottom: "10px"}}>
                <FormControl
                    onChange={(event) => props.updateChapterTopic(event.target.value, 'zoomLink')}
                    value={props.topic.zoomLink}
                    placeholder="Zoom Link"
                    aria-label="Zoom Link"
                    aria-describedby="basic-addon1"/>
            </Col>

            <Col md={12} style={{marginBottom: "10px"}}>
                <FormControl
                    onChange={(event) => props.updateChapterTopic(event.target.value, 'order')}
                    value={props.topic.order}
                    placeholder="Order"
                    aria-label="Order"
                    aria-describedby="basic-addon1"/>
            </Col>


            <Button onClick={() => props.deleteTopic()}>Delete Topic</Button>

        </Row>
    )

}

export default EditZoomLink