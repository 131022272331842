import React, {useEffect, useState} from "react"
import * as courseService from "../../Services/Course.service"
import * as adminService from "../../Services/Admin.service"
import {Link, useHistory, useParams} from 'react-router-dom';
import {Accordion, Button, Card, Col, Container, Image, Row, Spinner} from "react-bootstrap";
import "./CourseDetails.component.css"
import PageNotFound from "../PageNotFound/PageNotFound.component";
import defaultImage from "../../images/default-image.png"

const CourseDetails = () => {

    const [courseDetails, setCourseDetails] = useState(null);
    const [courseEnrollment, setCourseEnrollment] = useState(false);
    const [pathname] = useState(process.env.NODE_ENV === "development" ? "http://localhost:8080/api/image/file" : "https://www.learningplatform.in/api/image/file");
    const {slug} = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        courseService.getCourseDetails(slug).then(result => {
            setCourseDetails(result.data)
        }).catch((err) => {
            if (err?.response?.status === 403) {
            }
        }).finally(() => {
            setLoading(false)
        })
    }, [slug])

    useEffect(() => {
        if (courseDetails) {
            let userDetails = adminService.getUserFromToken()

            if (userDetails) {
                courseService.checkUserCourseEnrollment(userDetails.id, courseDetails.id).then((result) => {
                    setCourseEnrollment(result.data.userEnrolled)
                })
            }
        }

    }, [courseDetails])


    const startCourse = () => {
        if (courseDetails?.chapterList?.length > 0) {
            history.push(`/courses/${courseDetails.slug}/chapter/${courseDetails.chapterList[0].slug}`)
        }
    }

    const getAccordion = (chapter, index) => {
        return (
            <Card key={index} style={{border: "none", marginBottom: "10px"}}>
                <Accordion.Item eventKey={index} style={{
                    letterSpacing: '1px',
                    fontSize: '18px',
                    cursor: 'pointer'
                }}>
                    <Accordion.Header style={{textTransform: "capitalize"}}>{chapter.name}</Accordion.Header>
                    <Accordion.Body eventkey={index}>
                        <Card.Body style={{
                            fontSize: '18px',
                            letterSpacing: '1px'
                        }} className={"secondary-font-family"}>

                            <div className={'about-this-module'}>
                                About this Module
                            </div>

                            <div>
                                <span dangerouslySetInnerHTML={{__html: chapter.excerpt}}/>
                            </div>

                            <div className={'topics-covered'}>
                                Topics Covered
                            </div>

                            {
                                chapter.content && chapter.content.map((content, index) => {
                                    return (
                                        <div key={index} style={{marginLeft: "10px"}}>
                                            <Link to={`/courses/${courseDetails.slug}/chapter/${chapter.slug}/topic/${index + 1}`}>
                                            {content}
                                            </Link>
                                        </div>
                                    )
                                })
                            }

                            <Link to={`/courses/${courseDetails.slug}/chapter/${chapter.slug}`}>
                                Read Chapter
                            </Link>
                        </Card.Body>
                    </Accordion.Body>
                </Accordion.Item>
            </Card>
        )
    };

    const enrollCourse = () => {
        history.push({
            pathname: '/enroll',
            state: {"courseDetails": courseDetails}
        });
    }

    return (
        <Container id={"course-details"}>
            {
                courseDetails ?
                    <div>
                        <Row>
                            <Col>
                                <div className={'course-name'} >
                                    {courseDetails.name}
                                </div>
                            </Col>
                        </Row>

                        <Row style={{marginTop: "10px", marginBottom: "10px"}}>

                            <Col md={4}>
                                <div>
                                    <img style={{borderRadius: "10px", width: "100%", height: "300px"}}
                                         src={`${pathname}/${courseDetails.imageName}`} alt="course"/>
                                </div>
                            </Col>
                            <Col md={8}>
                                <div className={'about'}>
                                    About this Course
                                </div>

                                <div style={{textAlign: "justify"}}>
                                    <span dangerouslySetInnerHTML={{__html: courseDetails.excerpt}}/>
                                </div>
                            </Col>
                        </Row>

                        {/*<Row>*/}
                        {/*    <Col>*/}
                        {/*        <div style={{fontSize: "25px", fontWeight: "bold"}}>*/}
                        {/*            Chapter List*/}
                        {/*        </div>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}

                        <Row>
                            <Col>
                                <Accordion defaultActiveKey="1">
                                    {
                                        courseDetails?.chapterList && courseDetails.chapterList.map((chapter, index) => {
                                            return getAccordion(chapter, index)
                                        })
                                    }
                                </Accordion>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <div style={{fontSize: "25px"}}>
                                    Instructor
                                </div>
                            </Col>

                        </Row>
                        <Row style={{marginBottom: "10px"}}>
                            <Col md={1}>
                                {
                                    courseDetails.user.imageName ?
                                        <Image src={`${pathname}/${courseDetails.user.imageName}`}
                                               style={{width: "100px", height: "100px"}} roundedCircle/>:
                                        <Image style={{borderRadius: "10px", width: "100px", height: "100px"}}
                                             src={defaultImage} alt="default"/>
                                }

                            </Col>


                            <Col md={9}>
                                <div style={{fontSize: "20px", textTransform: "capitalize"}}>
                                    <Link to={`/instructors/${courseDetails.user.slug}`}
                                          style={{textDecoration: "none"}}>
                                        {courseDetails.user.name}
                                    </Link>
                                </div>

                                <div style={{fontSize: "15px", textTransform: "capitalize"}}>

                                        {courseDetails.user?.designation}

                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                {
                                    courseEnrollment ?
                                        <Button style={{border: "none"}} variant="secondary"
                                                onClick={() => startCourse()}>
                                            Start Course
                                        </Button> :
                                        <div>
                                            <Button style={{border: "none"}} variant="secondary"
                                                    onClick={() => enrollCourse()}>
                                                Enroll
                                            </Button>
                                            {' '}₹{courseDetails.price}
                                            <span style={{fontWeight: "bold"}}>
                                                {' '}Duration:{' '}
                                            </span>
                                            <span>{courseDetails.duration}{' '}Hrs</span>
                                        </div>
                                }

                            </Col>
                        </Row>
                    </div>

                    : <Row>
                        <Col style={{textAlign: "center", justifyContent: "center", alignItems: "center"}}>
                            {
                                loading ?
                                    <Spinner animation={"border"} />: <PageNotFound />
                            }

                        </Col>
                    </Row>
            }
        </Container>
    )
}

export default CourseDetails
