import React, {useEffect} from 'react';
import {BrowserRouter, Route, Switch, useHistory} from 'react-router-dom'
import HeaderComponent from "./Components/Header/Header.component";
import FooterComponent from "./Components/Footer/Footer.component"
import AllCoursesComponent from "./Components/AllCourses/AllCourses.component";
import CourseDetails from "./Components/CourseDetails/CourseDetails.component";
import ChapterDetails from "./Components/ChapterDetails/ChapterDetails.component"
import EditUserComponent from "./Components/Admin/User/EditUser.component";
import AllUsersComponent from "./Components/Admin/User/AllUsers.component";
import EditCourseComponent from "./Components/Admin/EditCourse/EditCourse.component";
import AllCoursesAdminComponent from "./Components/Admin/AllCourses.component"
import AdminComponent from "./Components/Admin/Admin.component";
import SignupStudentComponent from "./Components/Signup/SignupStudent.component";
import Faq from "./Components/FAQ/Faq.component";
import 'bootstrap/dist/css/bootstrap.min.css';
import About from "./Components/About/About.component";
import PrivacyPolicy from "./Components/Privacy-Policy/PrivacyPolicy.component";
import Login from "./Components/Login/Login.component";
import UserComponent from "./Components/Profile/UserProfile.component";
import EditProfile from "./Components/Profile/EditProfile.component";
import {ProvideAuth} from "./Services/Auth.service";
import PrivateRoute from "./Components/App/PrivateRoute.component";
import EnrolmentComponent from "./Components/Enrolment/Enrolment.component";
import ConfirmEmailComponent from "./Components/ConfirmEmail/ConfirmEmail.component";
import "./App.css"
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword.component";
import ChangePassword from "./Components/ChangePassword/ChangePassword.component";
import AllInstructorsComponent from "./Components/AllInstructors/AllInstructors.component";
import InstructorProfileComponent from "./Components/InstuctorProfile/InstructorProfile.component";
import HomeComponent from "./Components/Home/Home.component";
import CategoryDetails from "./Components/CategoryDetails/CategoryDetails";
import AllOrganizationsAdminComponent from "./Components/Admin/AllOrganizations.component";
import EditOrganization from "./Components/Admin/EditOrganization.component";
import AllCategories from "./Components/Admin/AllCategories.component";
import EditCategoryComponent from "./Components/Admin/EditCategory.component";
import PageNotFound from "./Components/PageNotFound/PageNotFound.component";
import {bindActionCreators} from "redux";
import {checkUserAuthorized} from "./Services/Admin.service";
import {connect} from "react-redux";
import ZoomMeeting from "./Components/ChapterDetails/ZoomMeeting.component";
import AllGroupsAdmin from "./Components/Admin/AllGroups.component";
import EditGroup from "./Components/Admin/EditGroup/EditGroup.component";
import H5PComponent from "./Components/Admin/EditCourse/EditH5P.component";
import TempLoginLink from "./Components/Admin/TempLoginLink.component";
import TempLogin from "./Components/TempLogin/TempLogin.component";
import CustomCalendar from "./Components/Admin/Calendar.component";

const App = (props) => {

    const history = useHistory();

    useEffect(() => {
        props.getUserAuthentication()

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);


    return (
        <BrowserRouter>
            <ProvideAuth>
                <div className={"App"} style={{paddingBottom: "10px"}}>

                    <HeaderComponent/>

                    <Switch>

                        <Route exact path="/">
                            <HomeComponent/>
                        </Route>

                        <Route exact path="/zoom">
                            <ZoomMeeting />
                        </Route>

                        <Route path="/signup">
                            <SignupStudentComponent/>
                        </Route>

                        <Route path="/faq">
                            <Faq/>
                        </Route>

                        <Route path="/about">
                            <About/>
                        </Route>

                        <Route path="/login">
                            <Login/>
                        </Route>

                        <Route path="/privacy-policy">
                            <PrivacyPolicy/>
                        </Route>

                        <Route path="/h5p-component">
                            <H5PComponent/>
                        </Route>

                        <Route path="/temp-login/:token">
                            <TempLogin />
                        </Route>

                        <PrivateRoute exact path="/admin">
                            <AdminComponent/>
                        </PrivateRoute>

                        <PrivateRoute exact path="/admin/groups">
                            <AllGroupsAdmin/>
                        </PrivateRoute>

                        <PrivateRoute exact path="/admin/groups/add-group">
                            <EditGroup/>
                        </PrivateRoute>

                        <PrivateRoute exact path="/admin/groups/:id">
                            <EditGroup/>
                        </PrivateRoute>

                        <PrivateRoute exact path="/admin/courses">
                            <AllCoursesAdminComponent/>
                        </PrivateRoute>

                        <PrivateRoute path="/admin/users/add-user">
                            <EditUserComponent/>
                        </PrivateRoute>

                        <PrivateRoute path="/admin/users/:id">
                            <EditUserComponent/>
                        </PrivateRoute>

                        <PrivateRoute path="/admin/users">
                            <AllUsersComponent/>
                        </PrivateRoute>

                        <PrivateRoute path="/admin/calendar">
                            <CustomCalendar />
                        </PrivateRoute>

                        <PrivateRoute path="/admin/courses/add-course">
                            <EditCourseComponent/>
                        </PrivateRoute>

                        <PrivateRoute path="/admin/courses/:id">
                            <EditCourseComponent/>
                        </PrivateRoute>


                        <PrivateRoute path={"/admin/companies/:id"}>
                            <EditOrganization/>
                        </PrivateRoute>

                        <PrivateRoute path={"/admin/companies"}>
                            <AllOrganizationsAdminComponent/>
                        </PrivateRoute>

                        <PrivateRoute path="/admin/add-company">
                            <EditOrganization/>
                        </PrivateRoute>

                        <PrivateRoute path={"/admin/categories/add-category"}>
                            <EditCategoryComponent/>
                        </PrivateRoute>

                        <PrivateRoute path={"/admin/categories/:id"}>
                            <EditCategoryComponent/>
                        </PrivateRoute>

                        <PrivateRoute path="/admin/categories">
                            <AllCategories/>
                        </PrivateRoute>

                        <PrivateRoute path="/admin/generate-link">
                            <TempLoginLink />
                        </PrivateRoute>

                        <Route path="/categories/:slug">
                            <CategoryDetails/>
                        </Route>

                        <Route path="/instructors/:slug">
                            <InstructorProfileComponent/>
                        </Route>

                        <Route path="/instructors">
                            <AllInstructorsComponent/>
                        </Route>

                        <Route path="/confirm-email/:token">
                            <ConfirmEmailComponent/>
                        </Route>

                        <Route path="/change-password/:token">
                            <ChangePassword/>
                        </Route>

                        <PrivateRoute path="/courses/:courseSlug/chapter/:chapterSlug/topic/:topicIndex">
                            <ChapterDetails/>
                        </PrivateRoute>

                        <PrivateRoute path="/courses/:courseSlug/chapter/:chapterSlug">
                            <ChapterDetails/>
                        </PrivateRoute>

                        <Route path="/courses/:slug">
                            <CourseDetails/>
                        </Route>

                        <Route path="/forgot-password">
                            <ForgotPassword/>
                        </Route>

                        <Route path="/courses">
                            <AllCoursesComponent/>
                        </Route>

                        <PrivateRoute path="/user/profile">
                            <UserComponent/>
                        </PrivateRoute>

                        <PrivateRoute path="/user/edit">
                            <EditProfile/>
                        </PrivateRoute>

                        <PrivateRoute path="/enroll">
                            <EnrolmentComponent/>
                        </PrivateRoute>

                        <Route path="*">
                            <PageNotFound/>
                        </Route>

                    </Switch>

                    <FooterComponent/>


                </div>
            </ProvideAuth>
        </BrowserRouter>

    );
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUserAuthentication: checkUserAuthorized,
        }, dispatch)
};

export default connect(null, mapDispatchToProps)(App);
