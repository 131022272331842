//import * as adminService from "./Admin.service";
import React, {createContext, useContext} from "react";



const authContext = createContext({});

export function useAuth() {
    return useContext(authContext);
}

export const useProvideAuth = () => {

    // const signIn = (body) => {
    //     return adminService.signIn(body)
    // };

    const signOut = (history) => {
        localStorage.removeItem('token');
        history.push("/login");
    };

    return {
        signOut
    };
};


export const ProvideAuth = ({children}) => {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
};


