import React, {useEffect, useState} from "react"
import {Button, Col, Container, Row, Spinner} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import AdminHeaderComponent from "./Header/Header.component";
import DialogModel from "../models/DialogModel.component";
import * as groupService from "../../Services/Group.service";

const AllGroupsAdmin = (props) => {

    const history = useHistory()
    const [dilogMessage, setDilog] = useState("");
    const [showDilog, setShowDilog] = useState(false);;
    const [allGroups, setAllGroups] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        groupService.getAllGroups().then((result) => {
            if (result) {
                setAllGroups(result.data)
            }
        }).catch((err) => {
        }).finally(() => {
            setLoading(false)
        })

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateDilogBox = () => {
        setShowDilog(false)
    }

    const deleteGroup = (id) => {
        groupService.deleteGroup(id).then((result)=> {
            setDilog("Group has been deleted")
            setShowDilog(true)
        }).catch((err)=> {
            setDilog("There was some issue in deleting group")
            setShowDilog(true)
        })
    }

    return (
        <Container style={{minHeight: "700px"}}>
            <AdminHeaderComponent/>

            <Row style={{marginBottom: "10px"}}>
                <Col md={12}>
                    <Button onClick={() => history.push("/admin/groups/add-group")}>{'New group'}</Button>
                </Col>
            </Row>
            {
                allGroups?.length ?

                    <Row>
                        {
                            allGroups.map((group, index) => {
                                return (
                                    <div style={{marginBottom: "10px"}} key={index}>
                                        <Row style={{border: "1px solid #dddddd", borderRadius: "5px", padding: "5px"}}>

                                            <Col md={4}>
                                                <Link style={{textDecoration: "none"}} to={'/admin/groups/' + group.id}>
                                                    <div style={{
                                                        fontSize: "20px",
                                                        color: "#424242",
                                                        textTransform: "capitalize",
                                                        padding: "5px",
                                                        fontWeight: "bold"
                                                    }}>
                                                        {group.name}
                                                    </div>
                                                </Link>
                                            </Col>

                                            <Col md={4}>
                                                <Button onClick={() => deleteGroup(group.id)}>Delete</Button>
                                            </Col>

                                        </Row>

                                    </div>
                                )
                            })
                        }
                    </Row> : <Row>
                        <Col style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            {
                                loading ?
                                    <Spinner animation={"border"}/> : <div>You dont have any Groups Yet</div>
                            }
                        </Col>
                    </Row>
            }


            <DialogModel
                message={dilogMessage}
                show={showDilog}
                onHide={() => updateDilogBox()}
            />

        </Container>
    )


}


export default AllGroupsAdmin