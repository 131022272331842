import React, {useEffect, useState} from "react"
import {Button, Col, Container, Row} from "react-bootstrap";
import {Link, useHistory, useLocation} from "react-router-dom";
import * as adminService from "../../Services/Admin.service";
import * as enrolmentService from "../../Services/Enrolment.service";
import "./Enrolment.component.css"
import DialogModel from "../models/DialogModel.component";
import * as courseService from "../../Services/Course.service"
import amex from '../../images/amex.png';
import mastercard from "../../images/mastercard.png"
import netbanking from "../../images/netbanking.png"
import upi from "../../images/upi.png"
import visa from "../../images/visa.png"

//import {updatePaymentStatus} from "../../Services/Enrolment.service";
//import {report} from "npm/lib/utils/explain-eresolve";
// import * as razorpay from "razorpay"

const EnrolmentComponent = (props) => {

    let location = useLocation();
    let history = useHistory();
    const [pathname] = useState(process.env.NODE_ENV === "development" ? "http://localhost:8080/api/image/file" : "https://www.learningplatform.in/api/image/file");
    const [enrolmentPlan, setEnrolmentPlan] = useState({})
    const [dilogMessage, setDilog] = useState("");
    const [showDilog, setShowDilog] = useState(false);
    const [courseDetails, setCourseDetails] = useState({})

    useEffect(() => {

        if (location.state?.courseDetails) {
            setCourseDetails(location.state?.courseDetails)
        } else if (location.state?.courseSlug) {
            courseService.getCourseDetails(location.state?.courseSlug).then((result) => {
                setCourseDetails(result.data)
            })
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const makePayment = () => {
        let user = adminService.getUserFromToken()
        let newEnrolmentPlan = {};
        Object.assign(newEnrolmentPlan, enrolmentPlan);
        newEnrolmentPlan['userId'] = user.id;
        newEnrolmentPlan['courseId'] = courseDetails.id
        setEnrolmentPlan(newEnrolmentPlan)
        enrolmentService.getOrderId(newEnrolmentPlan).then((result) => {

            if (result.data.orderId) {
                checkout(result.data.orderId)
            } else {
                // TODO show dilogue box
            }
        })
    }


    const checkout = (razorpayOrderId) => {

        const options = {
            key: "rzp_live_yM8s7nw5WYwCO6",
            amount: location?.state?.courseDetails?.price * 100,
            currency: 'INR',
            order_id: razorpayOrderId,
            handler: (response) => onPaymentSuccess(response),
            //"payment.failed" : (err) => onPaymentFailure(err)
        };

        const paymentObject = window.Razorpay(options);
        paymentObject.on('payment.failed', onPaymentFailure)

        paymentObject.open();
    }

    const onPaymentFailure = (err) => {
        let body = {
            status: "FAILURE"
        }
        enrolmentService.updatePaymentStatus(body).then((response) => {

            if (response.data.success) {
                // show dialogue
            } else {
                // show dialogue
            }

        })
    }

    const onPaymentSuccess = (response) => {

        let body = {
            status: "SUCCESS",
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature
        }
        enrolmentService.updatePaymentStatus(body).then((response) => {

            if (response.data.success) {
                history.push(`/courses/${courseDetails.slug}`)
            } else {
                setDilog("There was some issue in processing request")
                setShowDilog(true)
                // show dialogue
            }

        })
    }


    return (
        <Container>

            <Row>
                <Col md={8}>
                    <div style={{fontSize: "25px", fontWeight: "bold"}}>
                        Confirm
                    </div>
                    <div style={{borderBottom: "1px solid #dddddd", padding: "5px"}}>
                        <img src={amex} style={{width: "40px", height: "25px", marginRight: "4px"}} alt={"amex"}/>
                        <img src={mastercard} style={{width: "40px", height: "25px", marginRight: "4px"}}
                             alt={"mastercard"}/>
                        <img src={netbanking} style={{width: "40px", height: "25px", marginRight: "4px"}}
                             alt={"netbanking"}/>
                        <img src={upi} style={{width: "40px", height: "25px", marginRight: "4px"}} alt={"upi"}/>
                        <img src={visa} style={{width: "40px", height: "25px", marginRight: "4px"}} alt={"visa"}/>
                    </div>

                    <div style={{margin: "auto", paddingTop: "50px", width: "250px", textAlign: "left"}}>
                        By continuing to payment, I agree to the
                        <Link src={"#"} style={{color: "blue"}}>
                            {' '}Terms Of User,
                        </Link>
                        <Link src={"#"} style={{color: "blue"}}>
                            {' '}Refund Policy,
                        </Link>
                        <Link src={"#"} style={{color: "blue"}}>
                            {' '}Privacy Policy
                        </Link>
                        <div style={{marginTop: "10px"}}>
                            <Button variant="primary" onClick={() => makePayment()}>Continue to Payment</Button>
                        </div>

                    </div>


                </Col>
                <Col md={4}>
                    <Row className={"margin-15"} style={{border: "1px solid #eeeeee"}}>
                        <Col>

                            <Row>
                                <Col>
                                    <div>
                                        <img style={{width: "50px", height: "50px", marginRight: "10px", marginTop: "10px"}}
                                             src={`${pathname}/${courseDetails.imageName}`}
                                             alt="course"/> {courseDetails?.name}
                                    </div>
                                </Col>
                            </Row>

                            <Row style={{border: "1px solid #eeeeee",margin: "10px 1px 10px 1px"}}>

                            </Row>

                            <Row>
                                <Col md={5}>
                                    <div style={{fontWeight: "bold"}}>
                                   Total:
                                    </div>
                                </Col>
                                <Col md={7}>
                                    <div style={{textAlign: "right"}}>
                                        ₹{courseDetails?.price}
                                    </div>
                                </Col>
                            </Row>


                        </Col>
                    </Row>
                </Col>
            </Row>

            <DialogModel
                message={dilogMessage}
                show={showDilog}
                onHide={() => setShowDilog(false)}
            />
        </Container>
    )


}

export default EnrolmentComponent