import React, {useEffect} from 'react'
import {Route} from "react-router";
import {Redirect} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {checkUserAuthorized} from "../../Services/Admin.service";

const PrivateRoute =({children, ...props}) => {

    useEffect(()=> {
        // props.getUserAuthentication()

        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <div>
            {
                props.userAuthenticated?.data?.userAuthentic === true ?
                    <Route
                        {...props}
                        render={({ location }) =>
                        {
                            return children
                        }
                            }
                    />: <></>
            }

            {
                props.userAuthenticated?.error || props.userAuthenticated?.data?.userAuthentic === false?
                    <Route
                        {...props}
                        render={({ location }) => {
                           return <Redirect
                                to={{
                                    pathname: "/login",
                                    state :  location.state,
                                    from: location.pathname
                                }}
                            />
                        }

                        }
                    />: <></>
            }

            {
                props.userAuthenticated?.data?.userAuthentic === null ?
                    <>
                    </>: <></>
            }

        </div>
    );

};

const mapStateToProps = (state) => ({
    userAuthenticated: state.userAuthenticated,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUserAuthentication: checkUserAuthorized,
        }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);


