import {Col, Container, Row} from "react-bootstrap";
import * as React from 'react';

const PrivacyPolicy = () => {

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h1 style={{
                        letterSpacing: '1px',
                        fontSize: '25px',
                        textTransform: 'capitalize',
                    }}
                        className="font-color primary-font-family"
                    >Privacy Policy</h1>
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                    <div style={{
                        textAlign: "justify",
                        fontSize: '18px',
                        letterSpacing: '1px',
                        backgroundColor: 'white',
                        padding: "5px",
                        border: '1px solid #dddddd',
                        borderRadius: '5px',
                    }} className={"font-color secondary-font-family"}>

                        <div style={{marginBottom: "10px"}}>
                            This privacy policy has been compiled to better serve those who are concerned with how their
                            ‘Personally
                            Identifiable Information’ (PII) is being used online.
                            PII, as described in US privacy law and information security,
                            is information that can be used on its own or with other information to identify,
                            contact, or locate a single person, or to identify an individual in context.
                            Please read our privacy policy carefully to get a clear understanding of how we collect,
                            use,
                            protect or otherwise handle your Personally Identifiable Information in accordance with our
                            website.
                        </div>
                        <div style={{marginBottom: "10px"}}>
                            We do not collect information from visitors of our site or other details to
                            help you with your experience.
                        </div>
                        <div style={{marginBottom: "10px"}}>
                            We collect information from you when you register on our site, subscribe to a newsletter,
                            respond to a survey, fill out a form, Use Live Chat, Open a Support Ticket or enter
                            information
                            on our site.
                        </div>
                        <div style={{marginBottom: "10px"}}>
                            We may use the information we collect from you when you register, make a purchase, sign up
                            for our newsletter, respond to a survey or marketing communication, surf the website, or use
                            certain other site features in the following ways:
                            To ask for ratings and reviews of services or products To personalize your experience and to
                            allow us to deliver the type of content and product offerings in which you are most
                            interested. To improve our website in order to better serve you. To allow us to better
                            service you in responding to your customer service requests.
                            To follow up with them after correspondence (live chat, email or phone inquiries)
                        </div>
                        <div style={{marginBottom: "10px"}}>
                            We do not use vulnerability scanning and/or scanning to PCI standards.
                            We only provide articles and information. We never ask for credit card numbers.
                            We do not use any kind of Malware Scanning.
                        </div>
                        <div style={{marginBottom: "10px"}}>
                        </div>

                        <div style={{marginBottom: "10px"}}>
                            We do not sell, trade, or otherwise transfer to outside parties your Personally
                            Identifiable Information unless we provide users with advance notice.
                            This does not include website hosting partners and other parties who assist us
                            in operating our website, conducting our business, or serving our users,
                            so long as those parties agree to keep this information confidential.
                            We may also release information when it’s release is appropriate to comply with the law,
                            enforce our site policies, or protect ours or others’ rights, property or safety.
                            However, non-personally identifiable visitor information may be provided to other
                            parties for marketing, advertising, or other uses.
                        </div>

                        <div style={{marginBottom: "10px"}}>
                            Occasionally, at our discretion, we may include or offer third-party products or services on
                            our website.
                            These third-party sites have separate and independent privacy policies.
                            We therefore have no responsibility or liability for the content and activities of these
                            linked sites.
                            Nonetheless, we seek to protect the integrity of our site and welcome any feedback about
                            these sites
                        </div>

                        <div style={{marginBottom: "10px"}}>
                            We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do
                            Not Track (DNT) browser mechanism is in place. Does our site allow third-party behavioral
                            tracking?. It’s also important to note that we do not allow third-party behavioral tracking
                        </div>

                        <div style={{marginBottom: "10px"}}>
                            This app is for information purpose and recipes and ingredients explained can be allergic to
                            some people,
                            kindly recheck if you are allergic to anything.
                        </div>

                        <div style={{marginBottom: "10px"}}>
                            If there are any questions regarding this privacy policy, you may contact us using the
                            information below.Email: vivekagarwal5592@gmail.com
                        </div>
                    </div>
                </Col>
            </Row>

        </Container>
    )
};

export default PrivacyPolicy
