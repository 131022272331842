import axios from "axios";

const URL = process.env.NODE_ENV === "development" ? "/h5p" : "/h5p"

export const getContent = () => axios.get(`${URL}/`)
export const getEdit = (contentId) => axios.get(`${URL}/${contentId}/edit`)
export const getPlayer = (contentId) => axios.get(`${URL}/${contentId}/play`)
export const deletePlayer = (contentId) => axios.delete(`${URL}/${contentId}`)

export const saveContent = (contentId, requestBody) => {
    if (contentId) {
        return axios.patch(`${URL}/${contentId}`, requestBody)
    } else {
        return axios.post(`${URL}`, requestBody)
    }
};

