import React, {useEffect, useState} from "react"
import {Button, Col, Row} from "react-bootstrap";

const Quiz = (props) => {

    const [pathname] = useState(process.env.NODE_ENV === "development" ? "http://localhost:8080/api/image/file" : "https://www.learningplatform.in/api/image/file");

    useEffect(() => {

    }, [])

    const updateSelectedAnswer = (quizIndex, selectedAnswer) => {
        props.updateChapterWithSelectedAnswer(quizIndex, selectedAnswer)
    }

    const updateDisplay = (quizIndex) => {
        props.updateDisplayAnswer(quizIndex)
    }

    return (
        <Col md={12} key={props.index}
             style={{marginBottom: "10px"}}>
            <Row>
                <Col md={12}>
                    <div
                        style={{fontWeight: "bold", marginBottom: "10px"}}>
                        {props.topic.title}
                    </div>
                </Col>

                <Col md={12}
                     style={{textAlign: "justify"}}>
                    <div>
                        <Row>
                            <Col md={4}>
                                <div>
                                    {
                                        props.chapterDetails?.imageName ?
                                            <img
                                                style={{
                                                    borderRadius: "10px",
                                                    width: "100%",
                                                    height: "300px"
                                                }}
                                                src={`${pathname}/${props.chapterDetails?.imageName}`}
                                                alt="chapter"/> :
                                            <span></span>
                                    }
                                </div>
                            </Col>
                        </Row>
                        {
                            props.topic?.quiz && props.topic.quiz.map((q, quizIndex) => {
                                return (
                                    <div key={quizIndex}>
                                        <Row>
                                            <Col md={12}>
                                                <div>
                                                    {
                                                        q.question ?
                                                            <div>
                                                                {q.question}
                                                            </div> : <span></span>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>


                                        <div>
                                            {
                                                q?.answers && q.answers.map((answer, index) => {
                                                    return (
                                                        <Row style={{marginBottom: "10px"}} key={index}>
                                                            <Col md={6}>
                                                                <div>
                                                                    {answer}
                                                                </div>

                                                            </Col>
                                                            <Col md={6}>
                                                                <Button
                                                                    style={{color: q.selectedAnswer === index ? "black" : "white"}}
                                                                    onClick={() => updateSelectedAnswer(quizIndex, index)}>Select</Button>
                                                            </Col>
                                                        </Row>

                                                    )
                                                })
                                            }
                                        </div>

                                        <Row>
                                            <Col md={12}>
                                                <Button disabled={q.selectedAnswer === null || q.selectedAnswer === undefined}
                                                        onClick={() => updateDisplay(quizIndex)}>
                                                    Check the right answer
                                                </Button>
                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col md={12}>
                                                {
                                                    q.displaySelectedAnswer ?
                                                        <div>
                                                            {
                                                                q.selectedAnswer === q.correctAnswer ?
                                                                    <div style={{color: "green"}}>
                                                                        Your answer is correct
                                                                    </div> :
                                                                    <div style={{color: "red"}}>Your answer is
                                                                        incorrect</div>
                                                            }
                                                        </div> : <span></span>
                                                }
                                            </Col>
                                        </Row>

                                    </div>
                                )
                            })
                        }


                        <div style={{marginTop: "10px", textAlign: "right"}}>
                            {
                                !props.isTopicComplete(props.topicIndex - 1) ?

                                    <Button style={{
                                        border: "none",
                                        borderRadius: "15px"
                                    }}
                                            variant="secondary"
                                            onClick={() => props.markCourseComplete(props.topicIndex - 1)}>
                                        Mark as Complete
                                    </Button> : <div></div>

                            }
                        </div>
                    </div>
                </Col>
            </Row>
        </Col>
    )


}

export default Quiz