import React, {useEffect, useState} from "react"
import {Link, useHistory} from "react-router-dom";
import * as userService from "../../Services/User.service";
import {Accordion, Button, Card, Col, Container, Row} from "react-bootstrap";
import * as adminService from "../../Services/Admin.service"
import * as orderService from "../../Services/Enrolment.service"
import moment from "moment";
import defaultImage from "../../images/default-image.png"

const UserComponent = () => {

    const history = useHistory()
    const [pathname] = useState(process.env.NODE_ENV === "development" ? "http://localhost:8080/api/image/file" : "https://www.learningplatform.in/api/image/file");
    const [user, setUser] = useState(null);
    const [userOrders, setUserOrders] = useState([]);

    useEffect(() => {
        const userFromToken = adminService.getUserFromToken()
        userService.getUserProfile(userFromToken.slug).then((result) => {
            setUser(result.data)
        })

        orderService.getAllOrdersForUser(userFromToken.slug).then((result) => {
            setUserOrders(result.data)
        })

    }, [])


    const getAccordion = (order, index) => {
        return (
            <Card key={index}>
                <Accordion.Item eventKey={index} style={{
                    letterSpacing: '1px',
                    fontSize: '18px',
                    cursor: 'pointer'
                }}>
                    <Accordion.Header style={{textTransform: "capitalize"}}>{order.courseName}</Accordion.Header>
                    <Accordion.Body eventkey={index}>
                        <Card.Body style={{
                            fontSize: '18px',
                            letterSpacing: '1px'
                        }} className={"secondary-font-family"}>

                            <div style={{fontWeight: "bold"}}>
                                Course Name: {order.courseName}
                            </div>

                            <div style={{fontWeight: "bold"}}>
                                Amount: {order.amount}
                            </div>

                            <div style={{fontWeight: "bold"}}>
                                Date Executed: {moment(order.createdAt).format("DD/MM/YYYY")}

                            </div>

                        </Card.Body>
                    </Accordion.Body>
                </Accordion.Item>
            </Card>
        )
    };


    return (
        <Container style={{minHeight: "700px"}}>
            {
                user ? <div>
                    <Row style={{alignItems: "center"}}>

                        <Col md={3}>
                            {
                                user.imageName ?
                                    <div>
                                        <img style={{borderRadius: "10px", width: "250px", height: "250px"}}
                                             src={`${pathname}/${user.imageName}`} alt={user.name}/>
                                    </div> :
                                    <div>
                                        <img style={{borderRadius: "10px", width: "250px", height: "250px"}}
                                             src={defaultImage} alt={user.name}/>
                                    </div>
                            }
                        </Col>


                        <Col md={4}>
                            <Row>
                                <Col md={12} style={{textTransform: "capitalize"}}>
                                    Name: {user.name}
                                </Col>

                                <Col md={12}>
                                    Email: {user.email}
                                </Col>

                                <Col md={12}>
                                    {
                                        user.contact && user.contact !== 0 ?
                                            <div>
                                                Contact : {user.contact}
                                            </div> : <></>
                                    }
                                </Col>
                            </Row>

                            <Row>
                                {
                                    user.courseList?.length > 0 ?
                                        <div>
                                            <div>
                                                Courses Enrolled In
                                            </div>
                                            {
                                                user.courseList.map((course, index) => {
                                                    return (
                                                        <div key={index}>
                                                            Name: <Link to={'/courses/' + course.slug}
                                                                        style={{textDecoration: "none"}}> {course.name}  </Link>
                                                        </div>

                                                    )
                                                })
                                            }

                                        </div> :
                                        <></>
                                }
                            </Row>


                            <Row>
                                <Col md={3}>
                                    <Button onClick={() => history.push(`/user/edit`)}>EDIT</Button>
                                </Col>
                            </Row>
                        </Col>


                        <Col md={4}></Col>
                    </Row>


                    {
                        userOrders?.length > 0 ?
                            <div>
                                <Row>
                                    <Col md={12}>
                                        <div style={{fontWeight: "bold", fontSize: "20px"}}>
                                            Your Orders
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Accordion defaultActiveKey="1">
                                            {
                                                userOrders.map((chapter, index) => {
                                                    return getAccordion(chapter, index)
                                                })
                                            }
                                        </Accordion>
                                    </Col>
                                </Row>
                            </div> :
                            <Row>
                                <Col>
                                    <div>
                                        {/*You have not placed any orders yet*/}
                                    </div>
                                </Col>
                            </Row>
                    }
                </div> : <span></span>
            }


        </Container>
    )

}

export default UserComponent
