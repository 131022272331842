import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import * as userService from "../../Services/User.service"
//import {useAuth} from "../../Services/Auth.service";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {signIn} from "../../Services/Admin.service";
import {useHistory, useParams} from "react-router-dom";
import DialogModel from "../models/DialogModel.component";

const ChangePassword = (props) => {

    const {token} = useParams()
    const history = useHistory()

    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [errors, setErrors] = useState({password: null});
    const [dilogMessage, setDilog] = useState("");
    const [showDilog, setShowDilog] = useState(false);
    const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false)

    useEffect(() => {
    }, []);

    let changePassword = () => {
        if (validateUserInput()) {

            let body = {password: password}

            userService.changePassword(body, token).then((result) => {
                if (result.data.passwordChange) {
                    setPasswordChangeSuccess(true)
                    setDilog("Password has been changed successfully. You can now login with new Password")
                    setShowDilog(true)
                }

            }).catch((err) => {
                setDilog("There was some issue while processing your request.")
                setShowDilog(true)
            })
        }
    };

    const hideDilogBox = () => {
        setShowDilog(false)

        if (passwordChangeSuccess) {
            history.push("/login")
        }
    }

    const validateUserInput = () => {

        //eslint-disable-next-line
        const passwordPattern = /(?=.*?[0-9])(?=.*?[A-Za-z])(?=.*[^0-9A-Za-z]).+/i

        let errors = {password: ""}
        let valid = true

        if (!password) {
            errors.password = "Password is required"
            valid = false
        } else if (password !== confirmPassword) {
            errors.password = "Passwords do not match"
            valid = false
        } else if (!passwordPattern.test(password)) {
            errors.password = "Password must have both alphabets, numbers and Special Characters"
            valid = false
        } else if (password.length < 8) {
            errors.password = "Password must be minimum 8 characters"
            valid = false
        }

        setErrors(errors)
        return valid

    }


    return (
        <Container>
            <Row style={{height: "300px", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                <Col md={3}></Col>
                <Col md={6}>

                    {/*<div style={{ borderRadius: "7px"}}>*/}
                    {/*    <GoogleLogin*/}
                    {/*    clientId="796999681176-rlpls42eralenpnqdmsnscr939dl52hh.apps.googleusercontent.com"*/}
                    {/*    buttonText="Continue with Google"*/}
                    {/*    onSuccess={(result) => login(result)}*/}
                    {/*    onFailure={(result) => failureResponseFromGoogle(result)}*/}
                    {/*    cookiePolicy={'single_host_origin'}*/}
                    {/*/>*/}
                    {/*</div>*/}


                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            <InputGroup>
                                <Form.Control
                                    type={'password'}
                                    onChange={(event) => setPassword(event.target.value)}
                                    value={password}
                                    placeholder={"Password"}
                                    aria-label={"Password"}
                                    aria-describedby="basic-addon1"
                                    isInvalid={!!errors.password}
                                    isValid={errors.password === ''}/>
                                <Form.Control.Feedback type='invalid'>
                                    {errors.password}
                                </Form.Control.Feedback>
                                <Form.Control.Feedback>
                                    {"Looks Good!!"}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            <InputGroup>
                                <FormControl
                                    type={'password'}
                                    onChange={(event) => setConfirmPassword(event.target.value)}
                                    value={confirmPassword}
                                    placeholder={"Confirm Password"}
                                    aria-label={"Confirm Password"}
                                    aria-describedby="basic-addon1"/>
                            </InputGroup>
                        </Col>
                    </Row>


                    <Row style={{marginBottom: '20px'}}>
                        <Col md={3}>
                            <Button variant="primary" onClick={() => changePassword()}>Submit</Button>
                        </Col>
                    </Row>


                </Col>
                <Col md={3}></Col>
            </Row>


            <DialogModel
                message={dilogMessage}
                show={showDilog}
                onHide={() => hideDilogBox()}
            />

        </Container>
    )

}

const mapStateToProps = (state) => ({
    userAuthenticated: state.userAuthenticated,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            loginUser: signIn
        }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
