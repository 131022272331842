import React, {useEffect, useState} from "react"
import {Link, useHistory, useParams} from "react-router-dom";
import * as courseService from "../../../Services/Course.service";
import {Button, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import AdminHeaderComponent from "../Header/Header.component";
import DialogModel from "../../models/DialogModel.component";
import {getAllCategoriesAdmin} from "../../../Services/Category.service";
import Select from "react-select";
import {getAllAdmins, getAllInstructorsAdminSection} from "../../../Services/User.service";
import {getUserFromToken} from "../../../Services/Admin.service";
import WisywigEditor from "../WisywigEditor.component";
import * as enrolmentService from "../../../Services/Enrolment.service";
import EditChapter from "./EditChapter.component";
import * as h5PService from "../../../Services/h5p.service";
import moment from 'moment'

const EditCourseComponent = () => {

    const {id} = useParams();
    const history = useHistory()
    const [courseDetails, setCourseDetails] = useState({});
    const [pathname] = useState(process.env.NODE_ENV === "development" ? "http://localhost:8080/api/image/file" : "https://www.learningplatform.in/api/image/file");
    const [dilogMessage, setDilog] = useState("");
    const [showDilog, setShowDilog] = useState(false);
    const [showDilogAddEnrolment, setShowDilogAddEnrolment] = useState(false);
    const [allCategories, setAllCategories] = useState([]);
    const [allAdmins, setAllAdmins] = useState([]);
    const [allInstructors, setAllInstructors] = useState([]);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [studentDetailsForCourse, setStudentDetailsForCourse] = useState([]);
    const [errors, setErrors] = useState({courseName: null})

    useEffect(() => {
        if (id) {
            courseService.getCourseDetailsAdmin(id).then(result => {
                setCourseDetails(result.data)
            });
        }

        getAllCategoriesAdmin().then((result) => {
            setAllCategories(result.data)
        })

        getAllInstructorsAdminSection().then((result) => {
            if (result.data) {
                setAllInstructors(result.data)
            }
        })

        getAllAdmins().then((result) => {
            if (result.data) {
                setAllAdmins(result.data)
            }
        })


        if ((getUserFromToken()?.roleId === 1 || getUserFromToken()?.roleId === 2) && id) {
            courseService.getAllStudentsEnrolment(id).then((result) => {
                if (result) {
                    setStudentDetailsForCourse(result.data)
                }
            }).catch((err) => {
                console.log(err?.response?.data?.message)
            })
        }

    }, [id]);


    const setCourseData = (event, element) => {
        let newCourse = {};
        Object.assign(newCourse, courseDetails);
        newCourse[element] = event.target.value;
        setCourseDetails(newCourse)
    };

    const setCourseDataWisywig = (value, element) => {
        let newCourse = {};
        Object.assign(newCourse, courseDetails);
        newCourse[element] = value;
        setCourseDetails(newCourse)
    };


    const addNewChapter = () => {
        let newCourse = {};
        Object.assign(newCourse, courseDetails);
        let newChapter = {name: "New Module", excerpt: ""}

        if (!newCourse?.chapterList) {
            newCourse.chapterList = []
        }
        newCourse.chapterList.push(newChapter)
        setCourseDetails(newCourse)
    }


    const InputBox = (method, value, placeholder, errorVar) => {
        return (
            <InputGroup>
                <Form.Control
                    onChange={method}
                    value={value || ""}
                    placeholder={placeholder}
                    aria-label={placeholder}
                    isInvalid={!!errorVar}
                    isValid={errorVar === ''}
                    aria-describedby="basic-addon1"/>
                <Form.Control.Feedback type='invalid'>
                    {errorVar}
                </Form.Control.Feedback>
                <Form.Control.Feedback>
                    {"Looks Good!!"}
                </Form.Control.Feedback>
            </InputGroup>
        )

    };

    const updateCourse = () => {

        let newCourse = {};
        Object.assign(newCourse, courseDetails);
        newCourse.price = courseDetails.price ? parseInt(courseDetails.price) : 0
        newCourse.duration = newCourse.duration ? parseInt(courseDetails.duration) : 0
        setCourseDetails(newCourse)

        if (newCourse.chapterList) {
            newCourse.chapterList.forEach((chapter) => {
                if (chapter.content) {
                    for (let i = chapter.content.length - 1; i >= 0; i--) {
                        if (chapter.content[i].delete && chapter.content[i].type === 'h5p') {
                            h5PService.deletePlayer(chapter.content[i].contentId)
                            chapter.content.splice(i, 1)
                        }
                    }
                }
            })
        }

        if (!validateUserInput()) {
            return
        }

        if (newCourse.id === null || newCourse.id === 0 || newCourse.id === undefined) {
            courseService.saveCourse(newCourse).then((result) => {
                let newCourse = {};
                Object.assign(newCourse, courseDetails);
                newCourse.id = result.data.id
                setCourseDetails(newCourse)

                setDilog("Course has been saved successfully")
                setShowDilog(true)
                setUpdateSuccess(true)

            }).catch((err) => {
                setDilog("There was some issue in saving the data")
                setShowDilog(true)
                setUpdateSuccess(false)
            })
        } else {
            courseService.updateCourse(newCourse).then((result) => {
                setDilog("Course has been update successfully")
                setShowDilog(true)
                setUpdateSuccess(true)
            }).catch((err) => {
                setDilog("There was some issue in updating the course. Page will now reload")
                setShowDilog(true)
                setUpdateSuccess(false)
            })
        }
    }

    const setLocalImage = (e) => {

        let reader = new FileReader();

        reader.readAsDataURL(e.target.files[0]);

        reader.onload = () => {
            // Make a fileInfo Object
            const baseURL = reader.result;

            let newCourse = {};
            Object.assign(newCourse, courseDetails);
            newCourse["imageBase64"] = baseURL
            setCourseDetails(newCourse)


        }

    };

    const updatePublished = (event) => {
        let newCourse = {};
        Object.assign(newCourse, courseDetails);
        newCourse.published = !newCourse.published
        setCourseDetails(newCourse)
    }


    const getCategoryList = () => {
        let result = []

        allCategories?.length > 0 && allCategories.forEach((category) => {
            result.push({value: category.id, label: category.name})
        });

        return result
    }

    const getCategoryType = () => {
        if (courseDetails?.categoryId && allCategories) {
            const category = allCategories.find((category) => category.id === courseDetails?.categoryId)

            if (category) {
                return {value: category.id, label: category.name}
            }
        }
    }

    const handleDropdownClick = (val) => {
        let newCourse = {};
        Object.assign(newCourse, courseDetails);
        newCourse['categoryId'] = val.value;
        setCourseDetails(newCourse)
    }

    const getInstructorAdminList = () => {
        let result = []

        allAdmins?.length > 0 && allAdmins.forEach((admin) => {
            result.push({value: admin.id, label: admin.name + " - ADMIN"})
        });


        allInstructors?.length > 0 && allInstructors.forEach((instructor) => {
            result.push({value: instructor.id, label: instructor.name + " - INSTRUCTOR"})
        });

        return result
    }

    const getSelectedInstructor = () => {
        if (courseDetails?.user?.id && allAdmins?.length > 0) {
            const admin = allAdmins.find((admin) => admin.id === courseDetails?.user?.id)

            if (admin) {
                return {value: admin.id, label: admin.name + " - ADMIN"}
            }
        }

        if (courseDetails?.user?.id && allInstructors?.length > 0) {
            const instructor = allInstructors.find((instructor) => instructor.id === courseDetails?.user?.id)

            if (instructor) {
                return {value: instructor.id, label: instructor.name + " - INSTRUCTOR"}
            }
        }
    }

    const changeInstructor = (val) => {
        let newCourse = {};
        Object.assign(newCourse, courseDetails);
        newCourse['user'] = {};
        newCourse.user['id'] = val.value
        setCourseDetails(newCourse)
    }

    const afterSaveCourse = () => {
        setShowDilog(false)

        if (updateSuccess) {
            if(history.location.pathname.split('/')[3] === 'add-course') {
                history.push(`/admin/courses/${courseDetails.id}`)
            } else {
                window.location.reload(false);
            }
        }
    }


    const enrolUser = (userId, courseId) => {

        const body = studentDetailsForCourse.find((item) => item.userId === userId)

        enrolmentService.enrolUserForCourse(body, courseId).then((result) => {
            setDilog("User has been successfully enrolled")
            setShowDilogAddEnrolment(true)

            let newStudentDetailsForCourse = JSON.parse(JSON.stringify(studentDetailsForCourse))

            const studentIndex = newStudentDetailsForCourse?.findIndex((item) => item.userId === userId)

            if (studentIndex !== -1) {
                newStudentDetailsForCourse[studentIndex].enrolmentId = result.data.id
            }

            setStudentDetailsForCourse(newStudentDetailsForCourse)

        }).catch((err) => {
            setDilog("There was some issue in enroling user")
            setShowDilogAddEnrolment(true)
        })
    }

    const removeUserEnrolment = (userId, courseId) => {

        enrolmentService.removeUserEnrolment(userId, courseId).then((result)=> {
            setDilog("User Enrolment has been successfully removed")
            setShowDilogAddEnrolment(true)

            let newStudentDetailsForCourse = JSON.parse(JSON.stringify(studentDetailsForCourse))

            const studentIndex = newStudentDetailsForCourse?.findIndex((item) => item.userId === userId)

            if (studentIndex !== -1) {
                newStudentDetailsForCourse[studentIndex].enrolmentId = null
            }

            setStudentDetailsForCourse(newStudentDetailsForCourse)

        }).catch((err)=> {
            setDilog("There was some issue in removing user Enrolment")
            setShowDilogAddEnrolment(true)
        })
    }

    const updateDilogAddEnrolment = () => {
        setShowDilogAddEnrolment(false)
    }

    const setEnrolmentDetails = (event, index, element) => {
        let newStudentDetails = JSON.parse(JSON.stringify(studentDetailsForCourse))
        newStudentDetails[index][element] = event.target.value
        setStudentDetailsForCourse(newStudentDetails)

    }

    const deleteImage = () => {
        let newCourse = {};
        Object.assign(newCourse, courseDetails);
        newCourse["imageBase64"] = null
        newCourse["imageId"] = null
        newCourse["imageName"] = null
        setCourseDetails(newCourse)
    }

    const validateUserInput = () => {
        let errors = {courseName: ""}
        let valid = true
        if (!courseDetails?.name) {
            errors.courseName = "Course Name is required"
            valid = false
        }

        if (courseDetails?.published) {
            if (!courseDetails.categoryId) {
                errors.category = "Category is required"
                valid = false
            }

            if (!courseDetails?.user?.id) {
                errors.instructor = "Instructor is required"
                valid = false
            }
        }

        setErrors(errors)
        return valid
    }


    return (
        <Container>
            <AdminHeaderComponent/>
            <Row style={{marginBottom: '20px'}}>
                <Col md={8}>
                    <Row style={{marginBottom: "10px", alignItems: "center"}}>
                        <Col md={courseDetails?.id ? 8 : 12}>
                            {
                                InputBox((val) => setCourseData(val, 'name'), courseDetails?.name, 'Name', errors.courseName)
                            }
                        </Col>

                        {
                            courseDetails?.id ?
                                <Col md={4}>
                                    <div style={{fontSize: "18px"}}>
                                        <Link to={`/courses/${courseDetails.slug}`}>Preview Course</Link>
                                    </div>
                                </Col> : <span></span>
                        }
                    </Row>

                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            <Select
                                className='react-select-container'
                                classNamePrefix={!!errors.category ? "react-select" : ""}
                                placeholder={'CATEGORY'}
                                value={getCategoryType()}
                                onChange={(val) => handleDropdownClick(val)}
                                options={getCategoryList()}
                                isInvalid={!!errors.category}
                                isValid={errors.category === ''}
                            />
                            {
                                !!errors.category ?
                                    <div className={'invalid-feedback-custom'}>
                                        {errors.category}
                                    </div> : <span></span>
                            }
                        </Col>
                    </Row>


                    {
                        getUserFromToken()?.roleId === 1 ?
                            <Row style={{marginBottom: '20px'}}>
                                <Col md={12}>
                                    <Select
                                        className='react-select-container'
                                        classNamePrefix={!!errors.instructor ? "react-select" : ""}
                                        placeholder={'INSTRUCTOR'}
                                        value={getSelectedInstructor()}
                                        onChange={(val) => changeInstructor(val)}
                                        options={getInstructorAdminList()}
                                        isInvalid={!!errors.instructor}
                                        isValid={errors.instructor === ''}
                                    />
                                    {
                                        !!errors.instructor ?
                                            <div className={'invalid-feedback-custom'}>
                                                {errors.instructor}
                                            </div> : <span></span>
                                    }
                                </Col>
                            </Row> : <span></span>
                    }


                    <Row style={{marginBottom: "10px"}}>
                        <Col md={12}>
                            {
                                InputBox((val) => setCourseData(val, 'price'), courseDetails?.price || "", 'Price')
                            }
                        </Col>
                    </Row>

                    <Row style={{marginBottom: "10px"}}>
                        <Col md={10}>
                            {
                                InputBox((val) => setCourseData(val, 'duration'), courseDetails?.duration || "", 'Duration')
                            }
                        </Col>

                        <Col md={2}>
                            <div style={{marginTop: "5px"}}>{' '}Hours</div>
                        </Col>
                    </Row>


                    {/*<Row>*/}
                    {/*    <Col md={12} style={{marginBottom: "10px"}}>*/}
                    {/*        <InputGroup>*/}
                    {/*            <FormControl as="textarea" rows={6}*/}
                    {/*                         onChange={(event) => setCourseData(event, 'excerpt')}*/}
                    {/*                         value={courseDetails?.excerpt}*/}
                    {/*                         placeholder="Excerpt"*/}
                    {/*                         aria-label="Excerpt"*/}
                    {/*                         aria-describedby="basic-addon1"/>*/}
                    {/*        </InputGroup>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}


                    <Row>
                        <Col style={{marginBottom: '20px'}} md={12}>
                            <WisywigEditor
                                value={courseDetails?.excerpt}
                                updateDescription={(val) => setCourseDataWisywig(val, 'excerpt')}
                            />
                        </Col>
                    </Row>


                </Col>

                <Col md={4}>

                    <Row style={{
                        textAlign: "center",
                        marginBottom: "10px",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <Col md={12}>
                            {
                                courseDetails?.imageName || courseDetails?.imageBase64 ?
                                    <img style={{borderRadius: "10px", width: "250px", height: "250px"}}
                                         src={`${courseDetails.imageBase64 ? courseDetails.imageBase64 : pathname + "/" + courseDetails.imageName}`}
                                         alt="course"
                                    /> :
                                    <div style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textAlign: "center",
                                        border: "1px solid black",
                                        borderRadius: "10px",
                                        height: "250px"
                                    }}>
                                        No Image
                                    </div>
                            }
                        </Col>
                    </Row>

                    <Row style={{textAlign: "center", marginBottom: "10px"}}>
                        <Col md={12}>
                            <Form.Group>
                                <Form.Control type="file" onChange={(e) => setLocalImage(e)}/>
                            </Form.Group>

                            <Button style={{marginTop: "10px"}} onClick={() => deleteImage()}>Delete Image</Button>
                        </Col>
                    </Row>

                </Col>

            </Row>


            <Row style={{marginBottom: "10px"}}>
                <Col>
                    <Button style={{border: "none"}} variant="secondary"
                            onClick={() => addNewChapter()}>
                        +{' '}Add Module
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                    <EditChapter
                        courseDetails={courseDetails}
                        updateCourse={(newCourse) => setCourseDetails(newCourse)}
                    />
                </Col>
            </Row>

            <Row>


            </Row>


            <Row>
                <Col md={12}>
                    <InputGroup>
                        <Form.Check
                            checked={courseDetails?.published || false}
                            onChange={(event) => updatePublished(event)}
                            type='checkbox'
                            id={id}
                            label={"Published"}
                        />
                    </InputGroup>
                </Col>
            </Row>

            <Row>
                <Col style={{fontSize: "25px", margin: "20px 0px", fontWeight: "bold"}} md={12}>
                    Student Enrolment Details
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                    {
                        studentDetailsForCourse?.length > 0 && studentDetailsForCourse.map((item, index) => {
                            return (
                                <div style={{marginBottom: "10px"}} key={index}>
                                    <Row>
                                        <Col md={3}>
                                            <div>
                                                Student: {item.studentName}
                                            </div>
                                        </Col>

                                        {
                                            item.enrolmentId ?
                                                <Col md={9}>
                                                    <Row>
                                                        <Col md={4}>
                                                            <Button
                                                                onClick={() => removeUserEnrolment(item.userId, item.courseId)}>Remove Enrolment</Button>
                                                        </Col>

                                                        <Col md={4}>
                                                            {
                                                                item.courseStartDate ?
                                                                    moment(item.courseStartDate).format("DD/MM/YYYY"): <div>No Date Specified</div>
                                                            }
                                                        </Col>

                                                        <Col md={4}>
                                                            {
                                                                item.courseEndDate ?
                                                                moment(item.courseEndDate).format("DD/MM/YYYY"): <div>No Date Specified</div>}
                                                        </Col>
                                                    </Row>
                                                </Col> :
                                                <Col md={9}>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div>
                                                                <Button
                                                                    onClick={() => enrolUser(item.userId, item.courseId)}>Enroll
                                                                    User</Button>
                                                            </div>
                                                        </Col>

                                                        <Col md={4}>
                                                            <div>
                                                                <InputGroup>
                                                                    <FormControl
                                                                        type="date"
                                                                        onChange={(event) => setEnrolmentDetails(event, index, 'courseStartDate')}
                                                                        value={item.courseStartDate || ""}
                                                                        placeholder={'Start Date'}
                                                                        aria-label={'Start Date'}
                                                                        aria-describedby="basic-addon1"/>
                                                                </InputGroup>
                                                            </div>
                                                        </Col>

                                                        <Col md={4}>
                                                            <div>
                                                                <InputGroup>
                                                                    <FormControl
                                                                        type="date"
                                                                        onChange={(event) => setEnrolmentDetails(event, index, 'courseEndDate')}
                                                                        value={item.courseEndDate || ""}
                                                                        placeholder={'End Date'}
                                                                        aria-label={'End Date'}
                                                                        aria-describedby="basic-addon1"/>
                                                                </InputGroup>
                                                            </div>
                                                        </Col>

                                                    </Row>

                                                </Col>
                                        }


                                    </Row>


                                </div>
                            )
                        })
                    }
                </Col>
            </Row>

            <Row>
                <Col md={2}>
                    <Button variant="primary" onClick={() => updateCourse()}>Save</Button>
                </Col>


            </Row>


            <DialogModel
                message={dilogMessage}
                show={showDilog}
                onHide={() => afterSaveCourse()}
            />

            <DialogModel
                message={dilogMessage}
                show={showDilogAddEnrolment}
                onHide={() => updateDilogAddEnrolment()}
            />


        </Container>
    )
}

export default EditCourseComponent