import React, {useEffect} from "react"
import {Container, Navbar, Nav, NavDropdown, Col} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLightbulb, faStickyNote} from "@fortawesome/free-regular-svg-icons";
import {useHistory} from "react-router-dom";
import {bindActionCreators} from "redux";
import {checkUserAuthorized, getUserFromToken, logout} from "../../Services/Admin.service";
import {getAllOrganizations} from "../../Services/Organization.service";
import {connect} from "react-redux";
import Select from "react-select";
import logo from "../../images/logo.png";

const HeaderMobile = (props) => {

    const history = useHistory()

    useEffect(() => {
        props.getUserAuthentication()

        if (getUserFromToken()?.roleId === 1) {
            props.getAllOrganizations()
        }


        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getOrganizationList = () => {
        let result = []

        if (props.allOrganizations?.length > 0) {
            props.allOrganizations.forEach((organization) => {
                result.push({value: organization.id, label: organization.name, url: organization.url})
            })
        }

        return result
    }

    const getCurrentOrganization = () => {
        if (props.allOrganizations?.length > 0) {
            const org = props.allOrganizations.find((organization) => window.location.hostname === organization?.url.split(":")[0])

            if (org) {
                return {value: org.id, label: org.name}
            }
        }
    }

    const changeOrganization = (val) => {
        const URL = process.env.NODE_ENV === "development" ? "http://" + val.url.split(":")[0] + ":3000" : "https://" + val.url
        window.location.href = URL
    }

    return (
        <Navbar bg="light" expand="lg" className="d-block d-sm-none">
            <Container >
                <Navbar.Brand href="/">
                    <img src={logo} style={{width: "40px", height: "30px"}} alt={'learning platform'}/>
                    Learning Platform</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/about">About</Nav.Link>
                        <Nav.Link href="/instructors">Instructors</Nav.Link>
                        <NavDropdown title="Resources" id="basic-nav-dropdown" style={{marginTop: "5px"}}>
                            <NavDropdown.Item href="#action/3.1">
                                <div style={{display: "flex"}}>
                                    <div style={{flex: "1"}}>
                                        <FontAwesomeIcon
                                            style={{
                                                cursor: "pointer",
                                                fontSize: "18px",
                                                marginRight: "0",
                                                marginLeft: "auto"
                                            }}
                                            icon={faLightbulb}/>
                                    </div>
                                    <div style={{flex: "10"}}>
                                        Revenue Generation
                                    </div>
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#">
                                <div style={{display: "flex"}}>
                                    <div style={{flex: "1"}}>
                                        <FontAwesomeIcon
                                            style={{
                                                cursor: "pointer",
                                                fontSize: "18px",
                                                marginRight: "0",
                                                marginLeft: "auto"
                                            }}
                                            icon={faStickyNote}/>
                                    </div>
                                    <div style={{flex: "10"}}>
                                        Blog
                                    </div>
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#">
                                <div style={{display: "flex"}}>
                                    <div style={{flex: "1"}}>
                                        <FontAwesomeIcon
                                            style={{
                                                cursor: "pointer",
                                                fontSize: "18px",
                                                marginRight: "0",
                                                marginLeft: "auto"
                                            }}
                                            icon={faLightbulb}/>
                                    </div>
                                    <div style={{flex: "10"}}>
                                        Action
                                    </div>
                                </div>
                            </NavDropdown.Item>
                        </NavDropdown>
                        <div style={{marginTop: "10px"}}>
                        {
                            props.userAuthenticated?.data?.userAuthentic ?
                                <span className={'logout-link'} onClick={() => props.logout(history)}>
                                        Logout
                                    </span> :
                                <Nav.Link href="/login">Login</Nav.Link>
                        }
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Container>


            {

                getUserFromToken()?.roleId === 1 ?
                <div style={{ padding: "10px"}}>
                    <Col md={1} style={{marginTop: "10px"}}>
                        <Select
                            placeholder={'ORGANIZATION'}
                            value={getCurrentOrganization()}
                            onChange={(val) => changeOrganization(val)}
                            options={getOrganizationList()}
                        />
                    </Col>  </div>: undefined

            }

        </Navbar>
    )



}

const mapStateToProps = (state) => ({
    userAuthenticated: state.userAuthenticated,
    currentOrganization: state.currentOrganization,
    allOrganizations: state.allOrganizations

});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUserAuthentication: checkUserAuthorized,
            getAllOrganizations: getAllOrganizations,
            logout: logout
        }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMobile);
