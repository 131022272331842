import React, {useEffect} from "react"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Editor} from '@tinymce/tinymce-react';

const WisywigEditor = (props) => {

    useEffect(() => {
    });

    const handleEditorChange = (content, editor) => {
        props.updateDescription(content)
    };

    const getToolBarContent= () =>{
        return 'undo redo | formatselect | bold italic underline code backcolor image| ' +
            'alignleft aligncenter alignright alignjustify |' +
            'bullist numlist outdent indent | removeformat | help'
    };

    return (
        <div>
            <Editor
                apiKey='vvbk3fa833mpi94q5xgw2dzva8ajfxni6c4pe5zylokd4wwu'
                // initialValue={props.value}
                value={props.value}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: getToolBarContent()

                }}
                onEditorChange={handleEditorChange}
            />

        </div>
    )

};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(WisywigEditor);
