import * as React from 'react';
import {useEffect} from 'react';
import { Container, Dropdown} from "react-bootstrap"
import {Link, useHistory} from "react-router-dom";
import * as adminService from "../../Services/Admin.service"
import {checkUserAuthorized, getUserFromToken, logout} from "../../Services/Admin.service"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import "./Header.component.css"
import {getAllOrganizations} from "../../Services/Organization.service";
import Select from "react-select";
import {faLightbulb, faStickyNote} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import HeaderMobile from "./Header-mobile.component";
import logo from "../../images/logo.png"
// import "../App/App.scss"

const Header = (props) => {

    const history = useHistory();


    useEffect(() => {
        props.getUserAuthentication()

        if (getUserFromToken()?.roleId === 1) {
            props.getAllOrganizations()
        }


        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getOrganizationList = () => {
        let result = []

        if (props.allOrganizations?.length > 0) {
            props.allOrganizations.forEach((organization) => {
                result.push({value: organization.id, label: organization.name, url: organization.url})
            })
        }

        return result
    }

    const getCurrentOrganization = () => {
        if (props.allOrganizations?.length > 0) {
            const org = props.allOrganizations.find((organization) => window.location.hostname === organization?.url.split(":")[0])

            if (org) {
                return {value: org.id, label: org.name}
            }
        }
    }

    const changeOrganization = (val) => {
        const URL = process.env.NODE_ENV === "development" ? "http://" + val.url.split(":")[0] + ":3000" : "https://" + val.url
        window.location.href = URL
    }

    return (
        <div id="header" className={"parent-block-header"}>
            <div className={"headerImage"}></div>
            <Container className="d-none d-sm-block" fluid style={{backgroundColor: "white", height: "100%"}}>
                <div  style={{display:"flex",textAlign: "center", boxShadow: "0 0.75rem 2rem 0 rgb(0 0 0 / 5%)"}}>
                    <div   style={{flex: "2",borderRight: "1px solid #e5e7e9", textAlign: "center", padding: '15px 15px 15px 15px'}}>
                        <div style={{alignItems: "center"}}>
                            <div>
                                <Link to={'/'}>

                                    <div style={{
                                        display: "flex",
                                        fontSize: "15px",
                                        textTransform: "uppercase"
                                    }}>
                                        <div style={{flex: "1", textAlign: "right"}}>
                                        <img src={logo} style={{width: "60px", height: "50px"}} alt={'learning platform'}/>
                                        </div>
                                        <div  style={{flex: "1", textAlign: "left"}}>
                                            <div style={{display: "flex", flexDirection: "column"}}>
                                                <div style={{flex:"1"}}>
                                                    Learning
                                                </div>
                                                <div style={{flex:"1"}}>
                                                    Platform
                                                </div>
                                            </div>
                                             </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className={'header-nav-item'}>
                        <Link to={"/"}>{'Home'}</Link>
                    </div>

                    <div className={'header-nav-item'}>
                        <Link to={"/about"}>{'About'}</Link>
                    </div>

                    {
                        props.userAuthenticated?.data?.userAuthentic && (adminService.getUserFromToken()?.roleId === 2 || adminService.getUserFromToken()?.roleId === 3 || adminService.getUserFromToken()?.roleId === 1) ?
                            <div  className={'header-nav-item'}>
                                <Link
                                    to={"/admin"}>{'Admin'}</Link>
                            </div> :
                            undefined
                    }

                    {
                        props.userAuthenticated?.data?.userAuthentic ?
                            <div className={'header-nav-item'}>
                                <Link
                                    to={"/user/profile"}>{'Profile'}</Link>
                            </div> :
                            undefined
                    }


                    <div className={'header-nav-item'}>

                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" className={'dropdown-toggle'}>
                                Resources
                            </Dropdown.Toggle>

                            <Dropdown.Menu className={'dropdown-toggle-menu'}>
                                <Dropdown.Item href="#/action-1">
                                    <div style={{display: "flex"}}>
                                        <div style={{flex: "1"}}>
                                            <FontAwesomeIcon
                                                style={{
                                                    cursor: "pointer",
                                                    fontSize: "18px",
                                                    marginRight: "0",
                                                    marginLeft: "auto"
                                                }}
                                                icon={faLightbulb}/>
                                        </div>
                                        <div style={{flex: "10"}}>
                                            Revenue Generation
                                        </div>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                    <div style={{display: "flex"}}>
                                        <div style={{flex: "1"}}>
                                            <FontAwesomeIcon
                                                style={{
                                                    cursor: "pointer",
                                                    fontSize: "18px",
                                                    marginRight: "0",
                                                    marginLeft: "auto"
                                                }}
                                                icon={faStickyNote}/>
                                        </div>
                                        <div style={{flex: "10"}}>
                                            Blog
                                        </div>
                                    </div>

                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                    <div style={{display: "flex"}}>
                                        <div style={{flex: "1"}}>
                                            <FontAwesomeIcon
                                                style={{
                                                    cursor: "pointer",
                                                    fontSize: "18px",
                                                    marginRight: "0",
                                                    marginLeft: "auto"
                                                }}
                                                icon={faLightbulb}/>
                                        </div>
                                        <div style={{flex: "10"}}>
                                            Action
                                        </div>
                                    </div>
                                </Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown>

                    </div>

                    <div className={'header-nav-item'}>
                        <Link
                            to={"/instructors"}>{'Instructors'}</Link>
                    </div>

                    <div style={{flex: "3",borderRight: "1px solid #e5e7e9", padding: '25px 15px 15px 15px', textAlign: "right"}}>
                        {
                            props.userAuthenticated?.data?.userAuthentic ?

                                <span className={'logout-link'} onClick={() => props.logout(history)}>
                                        Logout
                                    </span> :
                                <Link to={"/login"}>{'Login'}</Link>


                        }
                    </div>


                    {
                        getUserFromToken()?.roleId === 1 ?
                            <div style={{flex: "2",marginTop: "10px", marginLeft: "5px"}}>
                                <Select
                                    placeholder={'ORGANIZATION'}
                                    value={getCurrentOrganization()}
                                    onChange={(val) => changeOrganization(val)}
                                    options={getOrganizationList()}
                                />
                            </div> : undefined
                    }

                </div>


            </Container>

            <HeaderMobile  />
        </div>
    );
}

const mapStateToProps = (state) => ({
    userAuthenticated: state.userAuthenticated,
    currentOrganization: state.currentOrganization,
    allOrganizations: state.allOrganizations

});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUserAuthentication: checkUserAuthorized,
            getAllOrganizations: getAllOrganizations,
            logout: logout
        }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
