import React, {useEffect} from "react"
import {Col, Row} from "react-bootstrap";


const PageNotFound = () => {

    useEffect(() => {

    }, [])

    return (
        <div style={{minHeight: "600px"}}>
            <Row style={{textAlign: "center", justifyContent: "center", alignItems: "center"}}>
                <Col md={12}>
                    The Page you are looking for is not found
                </Col>
            </Row>
        </div>
    )

}

export default PageNotFound