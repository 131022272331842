import React from 'react';
import {Button, Modal} from "react-bootstrap";

const DialogModel = (props) =>{

    // const copyContent = () => {
    //     navigator.clipboard.writeText(props.message)
    // };

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter" style={{textTransform: "capitalize"}}>
                    {props.message}
                </Modal.Title>
            </Modal.Header>

            <Modal.Footer>
                <Button onClick={props.onHide}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DialogModel;
