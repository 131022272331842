export const getAllOrganizationsReducer = (state = null, action) => {
    switch (action.type) {
        case "STORE_ALL_ORGANIZATIONS":
            return action.data;
        default:
            return state
    }
};

export const currentOrganizationReducer = (state = null, action) => {
    switch (action.type) {
        case "STORE_CURRENT_ORGANIZATION":
            return action.data;
        default:
            return state
    }
};


