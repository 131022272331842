export const userAuthenticationSuccess = (data) => {
    return {
        type: "USER_AUTHENTICATION_SUCCESS",
        data: data.payload,
        error: null
    }
};

export const userAuthenticationFailure = (data) => {
    return {
        type: "USER_AUTHENTICATION_FAILURE",
        data: null,
        error: data.error
    }
};

export const userAuthenticationProgress = () => {
    return {
        type: "USER_AUTHENTICATION_IN_PROGRESS",
        data: null,
        error: null
    }
};







