import React, {useEffect} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {getUserFromToken} from "../../../Services/Admin.service";


const AdminHeaderComponent = () => {

    useEffect(() => {

    }, [])

    return (
        <Container style={{
            border: "1px solid #dddddd",
            borderRadius: "5px",
            padding: "5px",
            background: "#dddddd",
            marginBottom: "10px",
            marginTop: "10px"
        }}>
            <Row>
                <Col md={12}>
                    <div>
                        <Link style={{color: "black", margin: "5px", textDecoration: "none"}}
                              to={"/admin?page=1"}>{'Admin'} <b>|</b></Link>
                        <Link style={{color: "black", margin: "5px", textDecoration: "none"}}
                              to="/admin/courses">{'Courses'} <b>|</b></Link>
                        {
                            getUserFromToken().roleId === 2 || getUserFromToken().roleId === 1 ?
                                <>
                                    <Link style={{color: "black", margin: "5px", textDecoration: "none"}}
                                          to="/admin/users?page=1">{'Users'} <b>|</b></Link>

                                    <Link style={{color: "black", margin: "5px", textDecoration: "none"}}
                                          to="/admin/categories?page=1">{'Categories'} <b>|</b></Link>

                                    <Link style={{color: "black", margin: "5px", textDecoration: "none"}}
                                          to="/admin/groups">{'Groups'} <b>|</b></Link>

                                    <Link style={{color: "black", margin: "5px", textDecoration: "none"}}
                                          to="/admin/generate-link">{'Generate Link'} <b>|</b></Link>
                                </> : <span></span>
                        }

                        {
                            getUserFromToken().roleId === 1 ?
                                <>
                                    <Link style={{color: "black", margin: "5px", textDecoration: "none"}}
                                          to="/admin/companies">{'Companies'} <b>|</b></Link>
                                </> : <span></span>
                        }


                    </div>
                </Col>
            </Row>
        </Container>


    )
}

export default AdminHeaderComponent