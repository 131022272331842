import React, {useEffect, useState} from "react"
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {getAllCourses} from "../../Services/Course.service";
import {connect} from "react-redux";
import "./AllCourses.component.css"

const AllCoursesComponent = (props) => {

    const [pathname] = useState( process.env.NODE_ENV ==="development" ? "http://localhost:8080/api/image/file":"https://www.learningplatform.in/api/image/file");

    useEffect(() => {
        props.getAllCourses()

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div id={"all-courses-component"}>
            <Row style={{paddingRight: "20px", paddingLeft: "20px"}}>
                {
                    props.allCourses && props.allCourses.map((course, index) => {
                        return (
                            <Col md={4} key={index}>
                                <Link to={'/courses/' + course.slug} style={{textDecoration: "none"}}>
                                    <div key={index}>
                                        <img className={"course-image"}
                                             src={`${pathname}/${course.image}`} alt="course" />
                                    </div>
                                    <div className={"course-name"}>
                                        {course.name}
                                    </div>
                                </Link>
                            </Col>
                        )
                    })
                }
            </Row>
        </div>
    )
}

const mapStateToProps = (state) => ({
    allCourses: state.allCourses,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getAllCourses: getAllCourses,
        }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(AllCoursesComponent);