import React, {useEffect, useState} from "react"
import * as adminService from "../../Services/Admin.service";
import * as userService from "../../Services/User.service";
import {Button, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import defaultImage from "../../images/default-image.png"

const EditProfile = () => {

    const [userDetails, setUserDetails] = useState({})
    const [passwordDetails, setPasswordDetails] = useState({})
    const [errors, setErrors] = useState({name: null, contact: null, password: null})
    const history = useHistory()
    const [pathname] = useState(process.env.NODE_ENV === "development" ? "http://localhost:8080/api/image/file" : "https://www.learningplatform.in/api/image/file");

    useEffect(() => {
        const userFromToken = adminService.getUserFromToken()
        userService.getUserProfile(userFromToken.slug).then((result) => {
            setUserDetails(result.data)
        })
    }, [])


    const setUserData = (event, element) => {
        let newUser = {};
        Object.assign(newUser, userDetails);
        newUser[element] = event.target.value;
        setUserDetails(newUser)
    };

    const setPasswordData = (event, element) => {
        let newPasswordDetails = {};
        Object.assign(newPasswordDetails, passwordDetails);
        newPasswordDetails[element] = event.target.value;
        setPasswordDetails(newPasswordDetails)
    };


    const updateUserContact = (event, element) => {
        let newUser = {};
        Object.assign(newUser, userDetails);

        newUser[element] = Number.isNaN(parseInt(event.target.value)) ? "" : event.target.value;
        setUserDetails(newUser)
    };

    const InputBox = (method, value, placeholder, errorVar) => {
        return (
            <InputGroup>
                <FormControl
                    onChange={method}
                    value={value}
                    placeholder={placeholder}
                    aria-label={placeholder}
                    aria-describedby="basic-addon1"
                    isInvalid={!!errorVar}
                    isValid={errorVar === ''}/>
                <Form.Control.Feedback type='invalid'>
                    {errorVar}
                </Form.Control.Feedback>
                <Form.Control.Feedback>
                    {"Looks Good!!"}
                </Form.Control.Feedback>
            </InputGroup>)

    }

    const updateUser = () => {
        if (validateUserInput()) {
            userService.updateUser(userDetails).then((result) => {
                history.push('/user/profile')
            })
        }
    }

    const updatePassword = () => {
        if (validatePasswordInput()) {
            userService.changePasswordProfile(passwordDetails, userDetails.slug).then((result) => {
                history.push('/user/profile')
            })
        }
    }


    const validateUserInput = () => {

        const contactPattern = /^[0-9]*$/;

        let errors = {name: "", contact: ""}
        let valid = true
        if (!userDetails?.name) {
            errors.name = "Name is required"
            valid = false
        }

        if (!userDetails?.contact) {
            errors.contact = "Contact number is required"
            valid = false
        } else if (!contactPattern.test(userDetails?.contact)) {
            errors.contact = "Contact number is incorrect"
            valid = false
        } else if (userDetails?.contact?.toString().length !== 10) {
            errors.contact = "Contact number must be 10 digits"
            valid = false
        }

        setErrors(errors)
        return valid

    }


    const validatePasswordInput = () => {

        //eslint-disable-next-line
        const passwordPattern = /(?=.*?[0-9])(?=.*?[A-Za-z])(?=.*[^0-9A-Za-z]).+/i

        let errors = {name: "", email: "", contact: "", password: ""}
        let valid = true

        if (!passwordDetails?.oldPassword) {
            errors.password = "Password is required"
            valid = false
        } else if (!passwordPattern.test(passwordDetails?.newPassword)) {
            errors.password = "Password must have both alphabets, numbers and Special Characters"
            valid = false
        } else if (passwordDetails?.newPassword?.length < 8) {
            errors.password = "Password must be minimum 8 characters"
            valid = false
        }

        setErrors(errors)
        return valid

    }

    const setLocalImage = (e) => {
        let reader = new FileReader();

        reader.readAsDataURL(e.target.files[0]);

        reader.onload = () => {
            const baseURL = reader.result;

            let newUser = {};
            Object.assign(newUser, userDetails);
            newUser["imageBase64"] = baseURL
            setUserDetails(newUser)
        }
    };

    const deleteImage = () => {
        let newUser = {};
        Object.assign(newUser, userDetails);
        newUser["imageBase64"] = null
        newUser["imageId"] = null
        newUser["imageName"] = null
        setUserDetails(newUser)
    }

    return (
        <Container>
            <Row>
                <Col md={8}>
                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            {
                                InputBox((val) => setUserData(val, 'name'), userDetails?.name, 'Name', errors.name)
                            }
                        </Col>
                    </Row>

                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            {
                                InputBox((val) => updateUserContact(val, 'contact'), userDetails?.contact, 'Contact', errors.contact)
                            }
                        </Col>
                    </Row>

                    <Row style={{marginBottom: '20px'}}>
                        <Col md={3}>
                            <Button variant="primary" onClick={() => updateUser()}>Update</Button>
                        </Col>
                    </Row>


                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            {
                                <InputGroup>
                                    <FormControl
                                        type={'password'}
                                        onChange={(val) => setPasswordData(val, 'oldPassword')}
                                        value={passwordDetails.oldPassword || ''}
                                        placeholder={'Old Password'}
                                        aria-label={'Old Password'}
                                        aria-describedby="basic-addon1"
                                        isInvalid={!!errors.password}
                                        isValid={errors.password === ''}/>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.password}
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback>
                                        {"Looks Good!!"}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            }
                        </Col>
                    </Row>

                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            {
                                <InputGroup>
                                    <FormControl
                                        type={'password'}
                                        onChange={(val) => setPasswordData(val, 'newPassword')}
                                        value={passwordDetails.newPassword || ''}
                                        placeholder={'New Password'}
                                        aria-label={'New Password'}
                                        aria-describedby="basic-addon1"
                                        isInvalid={!!errors.password}
                                        isValid={errors.password === ''}/>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.password}
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback>
                                        {"Looks Good!!"}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            }
                        </Col>
                    </Row>

                    <Row style={{marginBottom: '20px'}}>
                        <Col md={3}>
                            <Button variant="primary" onClick={() => updatePassword()}>Update Password</Button>
                        </Col>
                    </Row>


                </Col>

                <Col md={4}>

                    <Row style={{textAlign: "center"}}>
                        <Col md={12} style={{marginBottom: "10px"}}>
                            {
                                userDetails?.imageName || userDetails?.imageBase64 ?
                                    <div>
                                        <img style={{borderRadius: "10px", width: "250px", height: "250px"}}
                                             src={`${userDetails.imageBase64 ? userDetails.imageBase64 : pathname + "/" + userDetails.imageName}`}
                                             alt="user"/>
                                    </div> :
                                    <div>
                                        <img style={{borderRadius: "10px", width: "250px", height: "250px"}}
                                             src={defaultImage}
                                             alt="default"/>
                                    </div>
                            }
                        </Col>
                    </Row>

                    <Row style={{textAlign: "center"}}>
                        <Col md={12}>
                            <Form.Group>
                                <Form.Control type="file" onChange={(e) => setLocalImage(e)}/>
                            </Form.Group>

                            <Button style={{marginTop: "10px"}} onClick={() => deleteImage()}>Delete Image</Button>

                        </Col>

                    </Row>


                </Col>
            </Row>
        </Container>
    )

}


export default EditProfile
