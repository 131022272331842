import React, {useEffect, useState} from 'react';
import {Link, useHistory, useLocation} from "react-router-dom";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {getUserFromToken, signIn, updateUserAuthentication} from "../../Services/Admin.service";
import DialogModel from "../models/DialogModel.component";
import "./Login.component.css"
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Login = (props) => {

    const location = useLocation()
    const history = useHistory();
    const [userCredentials, setUserCredentials] = useState({})
    const [errors, setErrors] = useState({email: "", password: ""})
    const [dilogMessage, setDilog] = useState("");
    const [showDilog, setShowDilog] = useState(false);

    useEffect(() => {

        if (props.userAuthenticated?.data?.userAuthentic) {
            const userDetails = getUserFromToken()

            if (userDetails.organizationUrl) {
                window.location.href  = process.env.NODE_ENV === "development" ? "http://" + userDetails.organizationUrl.split(":")[0] + ":3000" : "https://" + userDetails.organizationUrl
            } else {
                window.location.href  = process.env.NODE_ENV === "development" ? "http://localhost.com:3000" : "https://www.learningplatform.in"
            }
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.userAuthenticated, history, location]);


    useEffect(() => {

        if (props.userAuthenticated?.error) {
            props.updateUserAuthentication()
            setDilog(props.userAuthenticated?.error)
            setShowDilog(true)
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.userAuthenticated])

    const setUserData = (event, element) => {
        let newUser = {};
        Object.assign(newUser, userCredentials);
        newUser[element] = event.target.value;
        setUserCredentials(newUser)
    };

    let login = () => {
        if (validateUserInput()) {
            props.loginUser(userCredentials, location.from)
        }
    };

    const validateUserInput = () => {

        const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        let errors = {email: "", password: ""}
        let valid = true

        if (!userCredentials?.email) {
            errors.email = "Email is required"
            valid = false
        } else if (!emailPattern.test(userCredentials?.email)) {
            errors.email = "Email ID is incorrect"
            valid = false
        }

        if (!userCredentials?.password) {
            errors.password = "Password is required"
            valid = false
        }

        setErrors(errors)
        return valid

    }

    const hideDilogBox = () => {
        setShowDilog(false)
    }

    return (
        <Container id={'login'}>
            <Row style={{margin: "150px auto", justifyContent: "center"}}>
                <Col md={3}></Col>
                <Col md={6} className={'login-box'}>

                    <Row style={{textAlign: "left", marginBottom: "20px"}}>
                        <Col md={6}>
                            <div style={{fontSize: "30px"}}>Sign in to your Learning Platform site</div>


                            <div className={'signup-link'}>
                                or <Link to={'/signup'}>create new account</Link>
                            </div>
                        </Col>


                        <Col md={6}>
                            <Row style={{marginBottom: '20px'}}>
                                <Col md={12}>
                                    <Form.Group>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            className={'email-input-box'}
                                            onChange={(val) => setUserData(val, 'email')}
                                            value={userCredentials?.email}
                                            aria-label={"Email"}
                                            placeholder={"you@example.com"}
                                            isInvalid={!!errors.email}
                                            aria-describedby="basic-addon1"/>
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>


                            <Row style={{marginBottom: '20px'}}>
                                <Col md={12}>
                                    <Form.Group>
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            className={'email-input-box'}
                                            type={'password'}
                                            onChange={(val) => setUserData(val, 'password')}
                                            value={userCredentials?.password}
                                            aria-label={"Password"}
                                            isInvalid={!!errors.password}
                                            aria-describedby="basic-addon1"/>
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.password}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={12} className={'forgot-password-link'}>
                                    <Link to={'/forgot-password'}>Forgot your password?</Link>
                                </Col>
                            </Row>


                            <Row style={{marginBottom: '20px'}}>
                                <Col md={12}>
                                    <Button variant="primary" onClick={() => login()} className={'login-button'}>
                                        Login
                                        <FontAwesomeIcon
                                            style={{cursor: "pointer", fontSize: "18px", marginRight: "0", marginLeft: "auto"}}
                                            icon={faArrowRight}/>
                                    </Button>
                                </Col>
                            </Row>


                        </Col>
                    </Row>


                </Col>
                <Col md={3}></Col>
            </Row>

            <DialogModel
                message={dilogMessage}
                show={showDilog}
                onHide={() => hideDilogBox()}
            />
        </Container>
    )

}

const mapStateToProps = (state) => ({
    userAuthenticated: state.userAuthenticated,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            loginUser: signIn,
            updateUserAuthentication: updateUserAuthentication
        }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
