import React, {useEffect, useState} from "react";
import * as userService from "../../Services/User.service"
import {Col, Container, Image, Row, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import defaultImage from "../../images/default-image.png"

const AllInstructorsComponent = () => {

    const [instructorList, setInstructorList] = useState([])
    const [pathname] = useState(process.env.NODE_ENV === "development" ? "http://localhost:8080/api/image/file" : "https://www.learningplatform.in/api/image/file");

    useEffect(() => {
        userService.getAllInstructors().then((result) => {
            setInstructorList(result.data)
        })

    }, [])

    return (
        <Container style={{minHeight: "700px"}}>
            {
                instructorList?.length > 0 ?
                    <div>
                        {
                            instructorList.map((instructor, index) => {
                                return (
                                    <Row style={{marginBottom: "10px", alignItems: "center"}} key={index}>
                                        <Col md={2} style={{textAlign: "center"}}>
                                            <Link to={'/instructors/' + instructor.slug}>
                                                {
                                                    instructor.imageName ?
                                                        <Image src={`${pathname}/${instructor.imageName}`}
                                                               style={{width: "200px", height: "200px", borderRadius: "5px", textAlign: "center"}}/>:

                                                        <Image src={defaultImage}
                                                               style={{width: "200px", height: "200px", borderRadius: "5px", textAlign: "center"}}/>
                                                }
                                            </Link>
                                        </Col>
                                        <Col md={3} style={{textAlign: "center"}}>
                                            <div style={{ textTransform: "capitalize"}}>
                                                <span style={{fontWeight: "bold", textTransform: "capitalize"}}>Name:</span> {instructor.name}
                                            </div>

                                            <div>
                                                <span style={{fontWeight: "bold"}}>Email:</span> {instructor.email}
                                            </div>

                                            <div>
                                                <span style={{fontWeight: "bold"}}>Designation:</span> {instructor.designation}
                                            </div>
                                            {
                                                instructor.courseList?.length > 0 ?
                                                    <div>
                                                        <div style={{fontWeight: "bold"}}>
                                                            Courses Published
                                                        </div>
                                                        {
                                                            instructor.courseList.map((course, i)=>{
                                                                return (
                                                                    <div key={i}>
                                                                        <Link to={`/courses/${course.slug}`} style={{textTransform: "capitalize"}}>
                                                                            {course.name}
                                                                        </Link>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                    </div> :
                                                    <span></span>
                                            }

                                        </Col>
                                        <Col md={7}/>
                                    </Row>
                                )
                            })
                        }
                </div>:
                    <Row>
                        <Col style={{textAlign: "center", justifyContent: "center", alignItems: "center"}}>
                            <Spinner animation={"border"} />
                        </Col>
                    </Row>
            }

        </Container>
    )

}

export default AllInstructorsComponent
