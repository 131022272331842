import axios from "axios";

const URL = process.env.NODE_ENV === "development" ? "/api/categories" : "/api/categories"

export const getAllCategories = () => axios.get(URL + `/get-all`)
export const getAllCategoriesAdmin = () => axios.get(URL + `/get-all-admin`)
export const getCategoryDetails = (slug) => axios.get(URL + `/get/slug/${slug}`)
export const getCategoryDetailsAdmin = (id) => axios.get(URL + `/get/id/${id}`)
export const saveCategory = (body) => axios.post(URL + `/save`, body)
export const updateCategory = (body, id) => axios.put(URL + `/update/${id}`, body)
export const deleteCategory = (id) =>  axios.delete(URL + `/delete/${id}`)