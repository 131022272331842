import React, {useEffect, useState} from "react"
import {useHistory, useParams} from "react-router-dom";
import * as organizationService from "../../Services/Organization.service";
import {Button, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import AdminHeaderComponent from "./Header/Header.component";
import DialogModel from "../models/DialogModel.component";


const EditOrganization = () => {

    const [organizationDetails, setOrganizationDetails] = useState(null);
    const [pathname] = useState(process.env.NODE_ENV === "development" ? "http://localhost:8080/api/image/file" : "https://www.learningplatform.in/api/image/file");
    const {id} = useParams();
    const history = useHistory()
    const [dilogMessage, setDilog] = useState("");
    const [showDilog, setShowDilog] = useState(false);

    useEffect(() => {
        if (id) {
            organizationService.getOrganizationDetails(id).then(result => {
                setOrganizationDetails(result.data)
            })
        }
    }, [id]);


    const setOrganizationData = (event, element) => {
        let newCourse = {};
        Object.assign(newCourse, organizationDetails);
        newCourse[element] = event.target.value;
        setOrganizationDetails(newCourse)
    };

    const InputBox = (method, value, placeholder) => {
        return (
            <InputGroup>
                <FormControl
                    onChange={method}
                    value={value || ""}
                    placeholder={placeholder}
                    aria-label={placeholder}
                    aria-describedby="basic-addon1"/>
            </InputGroup>
        )
    };

    const updateCourse = () => {

        let newOrganization = {};
        Object.assign(newOrganization, organizationDetails);
        newOrganization.price = parseInt(organizationDetails.price)
        setOrganizationDetails(newOrganization)

        if (!newOrganization.id) {
            organizationService.saveOrganization(newOrganization).then((result) => {
                history.push(`/admin/companies/${result.data.id}`)
            }).catch((err) => {
                setDilog(err.response.data)
                setShowDilog(true)
            })
        } else {
            organizationService.updateOrganization(newOrganization).then((result) => {
                window.location.reload(false);
            })
        }
    }

    const setLocalImage = (e) => {

        let reader = new FileReader();

        reader.readAsDataURL(e.target.files[0]);

        reader.onload = () => {
            const baseURL = reader.result;
            let newOrganization = {};
            Object.assign(newOrganization, organizationDetails);
            newOrganization["imageBase64"] = baseURL
            setOrganizationDetails(newOrganization)
        }

    };

    const addNewImage = (e) => {

        let reader = new FileReader();

        reader.readAsDataURL(e.target.files[0]);

        reader.onload = () => {
            const baseURL = reader.result;


            let newOrganization = {};
            Object.assign(newOrganization, organizationDetails);

            if (!newOrganization.homepage) {
                newOrganization["homepage"] = {
                    carousal: []
                }
            }

            newOrganization.homepage.carousal.push({
                imageBase64: baseURL
            })

            setOrganizationDetails(newOrganization)

        }

    }

    const  deleteCarousalImage = (index) => {
        let newOrganization = {};
        Object.assign(newOrganization, organizationDetails);

        console.log('1')
        newOrganization.homepage.carousal[index]["delete"] = true

        setOrganizationDetails(newOrganization)
    }


    return (
        <Container style={{minHeight: "700px"}}>
            <AdminHeaderComponent/>


            <Row style={{marginBottom: '20px'}}>
                <Row>

                    <Col md={8}>

                        <Row style={{marginBottom: "10px"}}>
                            <Col md={12}>
                                {
                                    InputBox((val) => setOrganizationData(val, 'name'), organizationDetails?.name, 'Name')
                                }
                            </Col>
                        </Row>

                        <Row style={{marginBottom: "10px"}}>
                            <Col md={12}>
                                {
                                    InputBox((val) => setOrganizationData(val, 'url'), organizationDetails?.url, 'URL')
                                }
                            </Col>
                        </Row>


                        <Row>
                            <Col md={12} style={{marginBottom: "10px"}}>
                                <InputGroup>
                                    <FormControl as="textarea" rows={6}
                                                 onChange={(event) => setOrganizationData(event, 'about')}
                                                 value={organizationDetails?.about || ""}
                                                 placeholder="About"
                                                 aria-label="About"
                                                 aria-describedby="basic-addon1"/>
                                </InputGroup>
                            </Col>
                        </Row>

                    </Col>

                    <Col md={4}>
                        <Row style={{marginBottom: "10px", textAlign: "center", justifyContent: "center", alignItems: "center"}}>
                            <Col md={12}>
                                {
                                    (organizationDetails?.imageName || organizationDetails?.imageBase64) ?
                                        <img style={{borderRadius: "10px", width: "250px", height: "250px"}}
                                             src={`${organizationDetails.imageBase64 ? organizationDetails.imageBase64 : pathname + "/" + organizationDetails.imageName}`}
                                             alt="course"
                                        /> :
                                        <div>
                                            <img style={{borderRadius: "10px", width: "250px", height: "250px"}}
                                                 src={`${pathname}/default-image.png`}
                                                 alt="default"/>
                                        </div>
                                }
                            </Col>
                        </Row>


                        <Row style={{marginBottom: "10px"}}>
                            <Col md={12}>
                                <Form.Group>
                                    <Form.Control type="file" onChange={(e) => setLocalImage(e)}/>
                                </Form.Group>
                            </Col>
                        </Row>



                    </Col>
                </Row>
            </Row>

            <Row style={{marginBottom: "10px"}}>
                <Col md={3}>
                    <Form.Group>
                        <Form.Control type="file" onChange={(e) => addNewImage(e)}/>
                    </Form.Group>
                </Col>
            </Row>


            <Row style={{marginBottom: "10px"}}>
                <Col md={12}>
                    {
                        organizationDetails?.homepage?.carousal?.length > 0 ?
                            <div>
                                {
                                    organizationDetails.homepage.carousal.map((item, index)=> {
                                        return (
                                            <div key={index}>
                                            {
                                                (item?.imageName || item?.imageBase64) && !item.delete ?
                                                    <div>
                                                <img style={{borderRadius: "10px", width: "100%", height: "350px"}}
                                                     src={`${item.imageBase64 ? item.imageBase64 : pathname + "/" + item.imageName}`}
                                                     alt="course"
                                                />
                                                    <Button onClick={()=> deleteCarousalImage(index)}>Delete</Button>
                                                    </div>: undefined

                                    }
                                            </div>
                                        )
                                    })
                                }
                            </div>: undefined
                    }
                </Col>
            </Row>

            <Row>
                <Col md={3}>
                    <Button variant="primary" onClick={() => updateCourse()}>Save</Button>
                </Col>
            </Row>


            <DialogModel
                message={dilogMessage}
                show={showDilog}
                onHide={() => setShowDilog(false)}
            />

        </Container>
    )
}

export default EditOrganization