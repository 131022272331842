import React, {useEffect, useState} from "react"
import {Link, useParams} from "react-router-dom";
import * as categoryService from "../../Services/Category.service"
import {Col, Container, Row, Spinner} from "react-bootstrap";
import "./CategoryDetails.component.css"
import defaultImage from "../../images/default-image.png"

const CategoryDetails = () => {

    const {slug} = useParams()
    const [categoryDetails, setCategoryDetails] = useState({})
    const [pathname] = useState(process.env.NODE_ENV === "development" ? "http://localhost:8080/api/image/file" : "https://www.learningplatform.in/api/image/file");

    useEffect(() => {
        categoryService.getCategoryDetails(slug).then((result) => {
            setCategoryDetails(result.data)
        })
    }, [slug])

    return (
        <Container id={"category-details-component"} style={{minHeight: "700px"}}>

            <Row style={{marginBottom: "10px"}}>
                <Col md={2} xs={12} sm={12}>
                    <div className={'category-image-container'}>
                    {
                        categoryDetails?.imageName ?
                            <img className={'category-image'}
                                 src={`${pathname}/${categoryDetails?.imageName}`} alt={'default'}/>: <span></span>
                    }
                    </div>
                </Col>


                <Col md={10} xs={12} sm={12}>
                    <div className={'category-heading'} >
                        {categoryDetails?.name}
                    </div>

                    <div className={'category-excerpt'}>
                        {categoryDetails?.excerpt}
                    </div>
                </Col>
            </Row>

            {
                categoryDetails?.courseList?.length > 0 ?
                    <Row>
                        {
                            categoryDetails.courseList.map((course, index) => {
                                return (
                                    <Col md={4} key={index} style={{marginBottom: "50px"}}>
                                        <div className={'course-image-container'}>
                                            <Link to={'/courses/' + course.courseSlug} style={{textDecoration: "none"}}>
                                                <div key={index}>
                                                    <img className={"course-image"}
                                                         src={`${pathname}/${course.courseImageName}`} alt="course"/>
                                                </div>
                                                <div className={"course-name"}>
                                                    {course.courseName}
                                                </div>
                                            </Link>

                                            <div style={{display:"flex", alignItems: "center", padding: "10px"}}>
                                                <div style={{flex: "3"}}>
                                                    {
                                                        course.userImage ?
                                                            <img style={{height: "50px", width: "50px", borderRadius: "50px"}}
                                                                 src={`${pathname}/${course.userImage}`} alt="course"/>:
                                                            <img style={{borderRadius: "50px", width: "50px", height: "50px"}}
                                                                 src={defaultImage}
                                                                 alt="default"/>
                                                    }
                                                </div>

                                                <div className={'course-instructor-name'}>
                                                    {course.userName}
                                                </div>
                                            </div>


                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                    :
                    <Row>
                        <Col style={{textAlign: "center", justifyContent: "center", alignItems: "center"}}>
                            <Spinner animation={"border"} />
                        </Col>
                    </Row>

            }


        </Container>
    )
}

export default CategoryDetails
