import React, {useEffect, useState} from "react"
import * as userService from "../../Services/User.service"
import {Link, useParams} from "react-router-dom";
import {Col, Container, Image, Row} from "react-bootstrap";
import "./InstructorProfile.component.css"
import defaultUserImage from '../../images/default-image.png'

const InstructorProfileComponent = () => {

    const {slug} = useParams()

    const [instructorProfile, setInstructorProfile] = useState({})
    const [pathname] = useState(process.env.NODE_ENV === "development" ? "http://localhost:8080/api/image/file" : "https://www.learningplatform.in/api/image/file");

    useEffect(() => {
        userService.getInstructorDetails(slug).then((result) => {
            setInstructorProfile(result.data)
        })
    }, [slug])

    return (
        <Container id={'instructor-profile'}>
            <Row style={{
                marginBottom: "10px",
                alignItems: "center",
                justifyItems: "center",
                borderBottom: "1px solid #9a9a9a"
            }}>
                <Col md={12}>
                    <Row style={{marginBottom: "10px"}}>
                        <Col md={2}>
                            {
                                instructorProfile?.image ?
                                    <Image src={`${pathname}/${instructorProfile.image}`}
                                           style={{width: "175px", height: "175px", borderRadius: "5px"}}/> :
                                    <Image roundedCircle={true}
                                           style={{borderRadius: "50px", width: "175px", height: "175px"}}
                                           src={defaultUserImage}
                                           alt="default"/>
                            }
                        </Col>
                        <Col md={10}>
                            <div style={{textTransform: "capitalize", fontSize: "20px"}}>
                                {instructorProfile?.name}
                            </div>

                            <div>
                                {instructorProfile?.email}
                            </div>

                            <div>
                                {instructorProfile?.designation}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>


            <Row style={{
                paddingBottom: "10px",
                marginBottom: "10px",
                alignItems: "center",
                justifyItems: "center",
                borderBottom: "1px solid #9a9a9a"
            }}>
                <Col md={12}>
                    <div style={{fontSize: "20px"}}>About</div>

                    <div style={{textAlign: "justify"}}>
                        {instructorProfile?.about}
                    </div>
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                    <div style={{fontSize: "20px"}}>Courses</div>
                </Col>
            </Row>

            <div>
                {
                    instructorProfile?.courseList?.length > 0 ?
                        <Row>
                            {
                                instructorProfile.courseList.map((course, index) => {
                                    return (
                                        <Col md={4} key={index} style={{marginBottom: "50px"}}>
                                            <div className={'course-image-container'}>
                                                <Link to={'/courses/' + course.slug}
                                                      style={{textDecoration: "none"}}>
                                                    <div key={index}>
                                                        <img className={"course-image"}
                                                             src={`${pathname}/${course.imageName}`} alt="course"/>
                                                    </div>
                                                    <div className={"course-name"}>
                                                        {course.name}
                                                    </div>
                                                </Link>

                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                        :
                        undefined

                }


            </div>
        </Container>
    )
}

export default InstructorProfileComponent
