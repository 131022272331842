import React from 'react';
import {Button, Col, FormControl, InputGroup, Modal, Row} from "react-bootstrap";

const ScheduleModel = ({eventObject,updateEventObject,removeElement,...props}) => {

    // const dateList = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
    // const year = [2022, 2023,2024]

    //const [eventObject, setStartDate] = useState("")

    // useEffect(()=> {
    //
    //     setStartDate(props.eventObject.startDate)
    //     setEndDate(props.eventObject.endDate)
    //     setTitle(props.eventObject.additionalInfo.title)
    //
    // },[props.eventObject])

    const closeModel = (updateSchedule) => {

        // const schedule = {
        //     startDate: moment(startDate).format('YYYY-MM-DDThh:mm:ssZ'),
        //     endDate: moment(endDate).format('YYYY-MM-DDThh:mm:ssZ'),
        //     title: title
        // }

        props.onHide(updateSchedule)

    }

    const removeEvent = () => {
        removeElement(eventObject.id)
    }

    const updateEvent = (value, element) => {
        let newEventObject = Object.assign({}, eventObject)
        newEventObject[element] = value
        updateEventObject(newEventObject)
    }

    const updateTitle = (value) => {
        let newEventObject = Object.assign({}, eventObject)
        newEventObject.additionalInfo.title = value
       updateEventObject(newEventObject)
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header translate>
                <Modal.Title id="contained-modal-title-vcenter" style={{textTransform: "capitalize"}}>
                    Set Schedule
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <Row style={{marginBottom: "10px"}}>
                    <Col md={5}>
                        <InputGroup>
                            <InputGroup.Text id="basic-addon3">
                               Start Date
                            </InputGroup.Text>
                            <FormControl
                                type="date"
                                onChange={(event) => updateEventObject(event.target.value, 'startDate')}
                                value={eventObject.startDate || ""}
                                placeholder={'Start Date'}
                                aria-label={'Start Date'}
                                aria-describedby="basic-addon1"/>
                        </InputGroup>
                    </Col>

                    <Col md={5}>
                        <InputGroup>
                            <InputGroup.Text id="basic-addon3">
                                End Date
                            </InputGroup.Text>
                            <FormControl
                                type="date"
                                onChange={(event) => updateEvent(event.target.value, 'endDate')}
                                value={eventObject.endDate || ""}
                                placeholder={'End Date'}
                                aria-label={'End Date'}
                                aria-describedby="basic-addon1"/>
                        </InputGroup>
                    </Col>
                </Row>


                <InputGroup>
                    <FormControl
                        onChange={(event) => updateTitle(event.target.value)}
                        value={eventObject.additionalInfo.title || ""}
                        placeholder={'Title'}
                        aria-label={'Title'}
                        aria-describedby="basic-addon1"/>
                </InputGroup>


            </Modal.Body>

            <Modal.Footer>
                <Button onClick={() => closeModel(true)}>Save</Button>

                <Button onClick={() => closeModel(false)}>Cancel</Button>

                {
                    eventObject.id ?
                        <Button onClick={() => removeEvent()}>Delete</Button>: undefined
                }
            </Modal.Footer>
        </Modal>
    );
};

export default ScheduleModel;
