import axios from "axios";

const URL = process.env.NODE_ENV === "development" ? "/api/groups" : "/api/groups"


export const getAllGroups = () => axios.get(`${URL}/get-all`)
export const getGroupById = (id) => axios.get(`${URL}/id/${id}`)
export const getStudentsEnrolled = (groupId) => axios.get(`${URL}/students-enrolled/${groupId}`)
export const saveGroup = (body) => axios.post(`${URL}/save`, body)
export const addStudentToGroup = (body) => axios.post(`${URL}/add-student-to-group`, body)
export const removeStudentFromGroup = (userId, groupId) => axios.delete(`${URL}/remove-student-from-group/user/${userId}/group/${groupId}`)
export const updateGroup = (body, id) => axios.put(`${URL}/update/${id}`, body)
export const getSignature = () => axios.get(`${URL}/get-signature`)
export const deleteGroup = (id) => axios.delete(`${URL}/delete/${id}`)
