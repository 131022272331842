import React, {useEffect} from "react"
import {Col, Container, Row} from "react-bootstrap";
import {H5PPlayerUI} from "@lumieducation/h5p-react";
import * as h5pService from "../../Services/h5p.service";

const H5PComponent = (props) => {

    const h5pPlayer = React.createRef();

    useEffect(() => {

    })

    const getPlay = async (contentId) => {
        const result = await h5pService.getPlayer(contentId)
        return result.data
    }

    return (
        <Container>

            <Row>
                <Col md={12}>
                    <H5PPlayerUI
                        ref={h5pPlayer}
                        contentId={props?.topic?.contentId}
                        loadContentCallback={getPlay}
                    />
                </Col>
            </Row>


        </Container>
    )

}

export default H5PComponent