import axios from "axios";
import jwt_decode from "jwt-decode";
import {
    userAuthenticationFailure,
    userAuthenticationProgress,
    userAuthenticationSuccess
} from "../actions/userAuthenticationAction";
import Cookies from 'js-cookie'

const URL = process.env.NODE_ENV ==="development" ? "/api/admin" : "/api/admin"

export const setHeaders = (accessToken, refreshToken) => {

    if (accessToken && refreshToken) {
        Cookies.set("accessToken", "bearer " + accessToken + " " + refreshToken,  {expires: 7, domain:  process.env.NODE_ENV === "development"? 'localhost.com': 'learningplatform.in' })
    }
};

export const getHeadersCustom = () => {
    return {
        "headers": {
            "authorization": Cookies?.get("accessToken")
        }
    }
};

export const getUserFromToken = () => {
    let token = Cookies?.get("accessToken")

    if (token) {
        let decodedToken = jwt_decode(token);
        return decodedToken?.user
    }

    return null

}

export const logout = (history) => {
    return (dispatch) => {
        return axios.delete(URL + "/logout")
            .then((res) => {
                dispatch(userAuthenticationSuccess({userAuthentic: false}))
            })
            .then(() => {
                Cookies?.remove("accessToken",  { domain:  process.env.NODE_ENV === "development"? 'localhost.com': 'learningplatform.in' })
                const URL = process.env.NODE_ENV === "development" ? 'http://localhost.com:3000': 'https://www.learningplatform.in'
                window.location.href = URL
            })
            .catch((err) => {
                console.log(err)
            })
    }
};


// export const checkUserAuthorized = () => axios.get(URL+"/authenticate-user",getHeadersCustom())
export const checkUserAuthorized = () => {
    return (dispatch) => {
        dispatch(userAuthenticationProgress())
        if (Cookies?.get("accessToken")) {
            return axios.get(URL + "/authenticate-user")
                .then((res) => {
                    setHeaders(res.headers.AccessToken, res.headers.RefreshToken)
                    dispatch(userAuthenticationSuccess({payload: res.data, error: null}))
                })
                .catch((err) => {
                   // Cookies?.remove("accessToken",  { domain:  process.env.NODE_ENV === "development"? 'localhost.com': 'learningplatform.in' })
                    dispatch(userAuthenticationFailure({
                        payload: null,
                        error: err?.response?.error
                    }))
                })

        } else {
            dispatch(userAuthenticationSuccess({payload: {userAuthentic: false}, error: null}))
        }
    }
};

//export const signIn = (body) => axios.post(URL+"/sign-in", body)

export const signIn = (body, redirectUrl) => {
    return (dispatch) => {
        dispatch(userAuthenticationProgress())
        return axios.post(URL + "/sign-in", body)
            .then((res) => {
                setHeaders(res.headers.accesstoken, res.headers.refreshtoken)
                dispatch(userAuthenticationSuccess({payload: res.data, error: null}))
            })
            .catch((err) => {
                console.log(err)
                dispatch(userAuthenticationFailure({error: err.response?.data?.error}))
            })
    }
};


export const updateUserAuthentication = () => {
    return (dispatch) => {
        dispatch(userAuthenticationSuccess({payload: null, error: null}))
    }
}