import React, {useEffect} from "react"
import {Button, Col, FormControl, Row} from "react-bootstrap";
import WisywigEditor from "../WisywigEditor.component";


const EditTopic = (props) => {

    useEffect(() => {

    }, [])


    return (
        <Row  style={{marginBottom: "50px"}}>
            <Col md={12} style={{marginBottom: "10px"}}>
                <FormControl
                    onChange={(event) => props.updateChapterTopic(event.target.value, 'title')}
                    value={props.topic.title}
                    placeholder="Title"
                    aria-label="Title"
                    aria-describedby="basic-addon1"/>
            </Col>

            {/*<Col md={12} style={{marginBottom: "10px"}}>*/}
            {/*    <FormControl as="textarea" rows={6}*/}
            {/*                 onChange={(val) => updateChapterTopic(val, key, i, 'text')}*/}
            {/*                 value={topic.text}*/}
            {/*                 placeholder="Text"*/}
            {/*                 aria-label="Text"*/}
            {/*                 aria-describedby="basic-addon1"/>*/}
            {/*</Col>*/}


            <Col style={{marginBottom: '20px'}} md={12}>
                <WisywigEditor
                    value={props.topic.text}
                    updateDescription={(val) => props.updateChapterTopicWisyWig(val, 'text')}
                />
            </Col>


            <Col md={12} style={{marginBottom: "10px"}}>
                <FormControl
                    onChange={(event) => props.updateChapterTopic(event.target.value, 'video')}
                    value={props.topic.video}
                    placeholder="Video"
                    aria-label="Video"
                    aria-describedby="basic-addon1"/>
            </Col>

            <Col md={12} style={{marginBottom: "10px"}}>
                <FormControl
                    onChange={(event) => props.updateChapterTopic(event.target.value, 'order')}
                    value={props.topic.order}
                    placeholder="Order"
                    aria-label="Order"
                    aria-describedby="basic-addon1"/>
            </Col>


            <Button onClick={() => props.deleteTopic()}>Delete Topic</Button>

        </Row>
    )

}

export default EditTopic