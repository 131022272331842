import React, {useEffect, useState} from "react"
import {Button, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import AdminHeaderComponent from "../Header/Header.component";
import { useParams} from "react-router-dom";
import * as groupService from "../../../Services/Group.service"
import DialogModel from "../../models/DialogModel.component";

const EditGroup = () => {

    const {id} = useParams();
    const [groupDetails, setGroupDetails] = useState({});
    const [dilogMessage, setDilog] = useState("");
    const [showDilog, setShowDilog] = useState(false);
    const [studentsEnrolled, setStudentsEnrolled] = useState([]);
    const [errors] = useState({name: null, email: null, contact: null, role: null})

    useEffect(() => {
        if (id) {
            groupService.getGroupById(id).then((result) => {
                setGroupDetails(result.data)
            })

            groupService.getStudentsEnrolled(id).then((result)=> {
                if (result) {
                    setStudentsEnrolled(result.data)
                }
            })

        }

    }, [id])

    const InputBox = (method, value, placeholder, errorVar, disabled = false) => {
        return (<InputGroup>
            <Form.Control
                disabled={disabled}
                onChange={method}
                value={value}
                placeholder={placeholder}
                aria-label={placeholder}
                isInvalid={!!errorVar}
                isValid={errorVar === ''}
                aria-describedby="basic-addon1"/>
            <Form.Control.Feedback type='invalid'>
                {errors.errorVar}
            </Form.Control.Feedback>
            <Form.Control.Feedback>
                {"Looks Good!!"}
            </Form.Control.Feedback>
        </InputGroup>)

    };

    const setGroupData = (event, element) => {
        let newUser = {};
        Object.assign(newUser, groupDetails);
        newUser[element] = event.target.value;
        setGroupDetails(newUser)
    };

    const updateGroup = () => {

        if (!groupDetails.id || groupDetails.id === 0) {
            groupService.saveGroup(groupDetails).then((result) => {
                let newUser = {};
                Object.assign(newUser, groupDetails);
                newUser.id = result.data.id
                setGroupDetails(newUser)

                setDilog("Group has been successfully saved")
                setShowDilog(true)

            }).catch((err) => {
                setDilog(err?.response?.data?.message || "There was some issue while updating user")
                setShowDilog(true)
            })
        } else {
            groupService.updateGroup(groupDetails, groupDetails.id).then((result) => {
                setDilog("User has been successfully saved")
                setShowDilog(true)
            }).catch((err) => {
                setDilog("There was some issue while updating group")
                setShowDilog(true)
            })
        }
    }

    const removeStudentFromGroup = (studentId, groupId) => {

        groupService.removeStudentFromGroup(studentId, groupId).then((result)=> {
            
            let tempList = JSON.parse(JSON.stringify(studentsEnrolled))

            const index = tempList.findIndex((group) => group.studentId === studentId)

            tempList[index].studentsGroupStatus = false

            setStudentsEnrolled(tempList)

            setDilog("Student has been successfully removed from group")
            setShowDilog(true)
        }).catch((err)=> {
            setDilog("There was some issue while removing user from the group")
            setShowDilog(true)
        })
    }


    const addStudentToGroup = (studentId, groupId) => {

        const body = {
            studentId: studentId,
            groupId: groupId
        }

        groupService.addStudentToGroup(body).then((result)=> {

            let tempList = JSON.parse(JSON.stringify(studentsEnrolled))

            const index = tempList.findIndex((group) => group.studentId === studentId)

            tempList[index].studentsGroupStatus = true

            setStudentsEnrolled(tempList)

            setDilog("Student has been successfully added to group")
            setShowDilog(true)
        }).catch((err)=> {
            setDilog("There was some issue while adding user to the group")
            setShowDilog(true)
        })
    }



    return (
        <Container style={{minHeight: "700px"}}>
            <AdminHeaderComponent/>

            <Row>
                <Col md={12}>
                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            {
                                InputBox((val) => setGroupData(val, 'name'), groupDetails?.name, 'Name', errors.name, false)
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Col md={12} style={{marginBottom: "10px"}}>
                <InputGroup>
                    <FormControl as="textarea" rows={6}
                                 onChange={(val) => setGroupData(val, 'description')}
                                 value={groupDetails?.description}
                                 placeholder="Description"
                                 aria-label="Description"
                                 aria-describedby="basic-addon1"/>
                </InputGroup>
            </Col>

            <Row style={{marginBottom: '20px'}}>
                <Col md={3}>
                    <Button variant="primary" onClick={() => updateGroup()}>Save</Button>
                </Col>
            </Row>


            <Row>
                <Col md={12}>
                    {
                        studentsEnrolled?.length > 0 && studentsEnrolled.map((item, index) => {
                            return (
                                <div style={{marginBottom: "10px"}} key={index}>
                                    <Row>
                                        <Col md={3}>
                                            <div>
                                                Student: {item.studentName}
                                            </div>
                                        </Col>

                                        {
                                            item.studentsGroupStatus ?
                                                <Col md={9}>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div>
                                                                <Button disabled={!id}
                                                                        onClick={() => removeStudentFromGroup(item.studentId, id)}>Remove from Group</Button>
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                </Col> :
                                                <Col md={9}>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div>
                                                                <Button disabled={!id}
                                                                    onClick={() => addStudentToGroup(item.studentId, id)}>Add to Group</Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                        }
                                    </Row>
                                </div>
                            )
                        })
                    }
                </Col>
            </Row>




            <DialogModel
                message={dilogMessage}
                show={showDilog}
                onHide={() => setShowDilog(false)}
            />

        </Container>
    )


}

export default EditGroup