import axios from "axios";
import {setHeaders} from "../Services/Admin.service";
import {
    userAuthenticationFailure,
    userAuthenticationProgress,
    userAuthenticationSuccess
} from "../actions/userAuthenticationAction";

const URL = process.env.NODE_ENV === "development" ? "/api/users" : "/api/users"

export const getAllStudents = () => axios.get(URL + "/get-all-students")
export const getAllInstructors = () => axios.get(URL + "/get-all-instructors")
export const getAllInstructorsAdminSection = () => axios.get(URL + "/get-all-instructors-admin")
export const getAllAdmins = () => axios.get(URL + "/get-all-admins")
export const getInstructorDetails = (slug) => axios.get(URL + `/get/instructor/${slug}`)
export const getUserDetailsAdmin = (userId) => axios.get(`${URL}/get/id/${userId}`)
export const getUserProfile = (slug) => axios.get(URL + `/profile/${slug}`)
export const downloadCertificate = (courseId) => axios.get(`${URL}/get-course-certificate/${courseId}`)


export const saveUser = (user) => axios.put(URL + `/save-user`, user)
export const sendPasswordResetLink = (userId) => axios.post(URL + `/password-reset-link/${userId}`, null)
export const updateUser = (user) => axios.put(URL + `/update-user/${user.id}`, user)
export const signUp = (user) => axios.put(URL + `/signup`, user)
export const changePasswordProfile = (body, slug) => axios.put(`${URL}/change-password-profile/${slug}`, body)

export const confirmEmail = (token) => axios.post(URL + `/confirm-email`,token)
export const forgotPassword = (email) => axios.post(URL + `/forgot-password/${email}`)
export const changePassword = (body) => axios.post(URL +`/change-password`, body)
export const uploadUserList = (body) => axios.post(`${URL}/upload-user-list`, body, {"headers": {'Content-Type': 'multipart/form-data'}})
export const generateLink = (body) => axios.post(`${URL}/get-temp-login-link`, body)
export const sendEmailForPasswordReset = (body) => axios.post(`${URL}/send-bulk-email-password-change`,body)

export const submitTempLogin = (body, redirectUrl) => {
    return (dispatch) => {
        dispatch(userAuthenticationProgress())
        return axios.post(URL + "/temp-login", body)
            .then((res) => {
                setHeaders(res.headers.accesstoken, res.headers.refreshtoken)
                dispatch(userAuthenticationSuccess({payload: res.data, error: null}))
            })
            .catch((err) => {
                dispatch(userAuthenticationFailure({error: err.response.data.error}))
            })
    }
};