import React, {useEffect, useState} from "react"
import * as userService from "../../../Services/User.service"
import {useHistory, useParams} from "react-router-dom";
import AdminHeaderComponent from "../Header/Header.component";
import {Button, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import Select from "react-select";
import {getAllOrganizations} from "../../../Services/Organization.service";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import DialogModel from "../../models/DialogModel.component";
import "./EditUser.component.css"
import defaultUserImage from '../../../images/default-image.png'

const EditUserComponent = () => {

    const {id} = useParams();
    const history = useHistory();
    const [userDetails, setUserDetails] = useState({});
    const [dilogMessage, setDilog] = useState("");
    const [showDilog, setShowDilog] = useState(false);
    const [showDilogEmailLink, setShowDilogEmailLink] = useState(false)
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [pathname] = useState(process.env.NODE_ENV === "development" ? "http://localhost:8080/api/image/file" : "https://www.learningplatform.in/api/image/file");
    const [errors, setErrors] = useState({name: null, email: null, contact: null, role: null})

    useEffect(() => {
        if (id) {
            userService.getUserDetailsAdmin(id).then((result) => {
                setUserDetails(result.data)
            })
        }


        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const setUserData = (event, element) => {
        let newUser = {};
        Object.assign(newUser, userDetails);
        newUser[element] = event.target.value;
        setUserDetails(newUser)
    };

    const updateUserContact = (event, element) => {
        let newUser = {};
        Object.assign(newUser, userDetails);
        newUser[element] = Number.isNaN(parseInt(event.target.value)) ? "" : event.target.value;
        setUserDetails(newUser)
    };

    const InputBox = (method, value, placeholder, errorVar, disabled = false) => {
        return (<InputGroup>
            <Form.Control
                disabled={disabled}
                onChange={method}
                value={value || ""}
                placeholder={placeholder}
                aria-label={placeholder}
                isInvalid={!!errorVar}
                isValid = {errorVar === ''}
                aria-describedby="basic-addon1"/>
            <Form.Control.Feedback type='invalid'>
                { errors.errorVar }
            </Form.Control.Feedback>
            <Form.Control.Feedback>
                { "Looks Good!!" }
            </Form.Control.Feedback>
        </InputGroup>)

    };

    const updateUser = () => {

        if (!validateUserInput()) {
            return false
        }

        if (!userDetails.id || userDetails.id === 0) {
            userService.saveUser(userDetails).then((result) => {
                let newUser = {};
                Object.assign(newUser, userDetails);
                newUser.id = result.data.id
                setUserDetails(newUser)

                setUpdateSuccess(true)
                setDilog("User has been successfully saved")
                setShowDilog(true)

            }).catch((err) => {
                setDilog(err?.response?.data?.message || "There was some issue while updating user")
                setShowDilog(true)
            })
        } else {
            userService.updateUser(userDetails).then(() => {
                setUpdateSuccess(true)
                setDilog("User has been successfully saved")
                setShowDilog(true)
            }).catch(() => {
                setDilog("There was some issue while updating user")
                setShowDilog(true)
            })
        }
    }

    const getUserRoleList = () => {
        return [
            {value: 2, label: "ADMIN"},
            {value: 3, label: "INSTRUCTOR"},
            {value: 4, label: "STUDENT"},
            {value: 5, label: "STUDENT - VIEW ENROLLED"}
        ]
    }

    const getRoleLabel = (roleId) => {

        if (roleId === 2) {
            return 'ADMIN'
        } else if (roleId === 3) {
            return 'INSTRUCTOR'
        } else if (roleId === 4) {
            return 'STUDENT'
        } else if (roleId === 5) {
            return 'STUDENT - VIEW ENROLLED'
        }

    }

    const getUserRole = () => {
        if (userDetails?.roleId) {
            return {value: userDetails.roleId, label: getRoleLabel(userDetails.roleId)}
        }
    }

    const changeUserRole = (val) => {
        let newUser = {};
        Object.assign(newUser, userDetails);
        newUser['roleId'] = val.value;
        setUserDetails(newUser)
    }

    const setLocalImage = (e) => {

        let reader = new FileReader();

        reader.readAsDataURL(e.target.files[0]);

        reader.onload = () => {
            // Make a fileInfo Object
            const baseURL = reader.result;
            let newUser = {};
            Object.assign(newUser, userDetails);
            newUser["imageBase64"] = baseURL
            setUserDetails(newUser)
        }

    };

    const afterSaveUser = () => {
        setShowDilog(false)

        if (updateSuccess) {
               history.push(`/admin/users/${userDetails.id}`)
        }
    }

    const restPasswordLink = () => {
        userService.sendPasswordResetLink(userDetails.id).then((result) => {
            setDilog(result.data.message)
            setShowDilogEmailLink(true)
        }).catch((err) => {
            setDilog(err?.response?.data?.message)
            setShowDilogEmailLink(false)
        })
    };

    const validateUserInput = () => {

        const contactPattern  =/^[0-9]*$/;
        const emailPattern =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        let errors = {name: "", email: "", contact: ""}
        let valid = true
        if(!userDetails?.name) {
            errors.name = "Name is required"
            valid = false
        }

        if (!userDetails?.contact) {
            errors.contact = "Contact number is required"
            valid = false
        }  else if ( !contactPattern.test(userDetails?.contact)) {
            errors.contact = "Contact number is incorrect"
            valid = false
        } else if (userDetails?.contact?.length !== 10) {
            errors.contact = "Contact number must be 10 digits"
            valid = false
        }

        if (!userDetails?.email) {
            errors.email = "Email is required"
            valid = false
        } else if (!emailPattern.test(userDetails?.email)) {
            errors.email = "Email ID is incorrect"
            valid = false
        }

        if (!userDetails?.roleId) {
            errors.role = "Role is required"
            valid = false
        }

        setErrors(errors)
        return valid

    }

    return (
        <Container>
            <AdminHeaderComponent/>

            <Row>
                <Col md={8}>
                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            {
                                InputBox((val) => setUserData(val, 'name'), userDetails?.name, 'Name',  errors.name, false)
                            }
                        </Col>
                    </Row>

                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            {
                                InputBox((val) => setUserData(val, 'email'), userDetails?.email, 'Email', errors.email, !!userDetails.id)
                            }
                        </Col>
                    </Row>

                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            {
                                InputBox((val) => setUserData(val, 'designation'), userDetails?.designation, 'Designation', errors.designation)
                            }
                        </Col>
                    </Row>

                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            {
                                InputBox((val) => updateUserContact(val, 'contact'), userDetails?.contact, 'Contact',  errors.contact,false)
                            }
                        </Col>
                    </Row>

                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            <InputGroup>
                                <FormControl as="textarea" rows={6}
                                             onChange={(val) => setUserData(val, 'about')}
                                             value={userDetails?.about}
                                             placeholder="About"
                                             aria-label="About"
                                             aria-describedby="basic-addon1"/>
                            </InputGroup>
                        </Col>


                    </Row>


                    <Row style={{marginBottom: '20px'}}>
                        <Col md={12}>
                            <Select
                                className='react-select-container'
                                classNamePrefix={!!errors.role ? "react-select": ""}
                                placeholder={'ROLE'}
                                value={getUserRole()}
                                onChange={(val) => changeUserRole(val)}
                                options={getUserRoleList()}
                                isInvalid={!!errors.role}
                                isValid = {errors.role === ''}
                            />
                            {
                                !!errors.role ?
                                    <div className={'invalid-feedback-custom'}>
                                        { errors.role }
                                    </div>: <span></span>
                            }
                        </Col>
                    </Row>

                    <Row style={{marginBottom: '20px'}}>
                        <Col md={3}>
                            <Button variant="primary" onClick={() => updateUser()}>Save</Button>
                        </Col>

                        <Col md={3}>
                            <Button variant="primary" onClick={() => restPasswordLink()}>Send Reset Password Link</Button>
                        </Col>
                    </Row>
                </Col>

                <Col md={4}>
                    <Row style={{textAlign: "center"}}>
                        <Col md={12}>
                        {
                            userDetails?.imageName || userDetails?.imageBase64 ?
                                <div>
                                    <img style={{borderRadius: "10px", width: "250px", height: "250px"}}
                                         src={`${userDetails.imageBase64 ? userDetails.imageBase64 : pathname + "/" + userDetails.imageName}`}
                                         alt="user"/>
                                </div> :
                                <div>
                                    <img style={{borderRadius: "10px", width: "250px", height: "250px"}}
                                         src={defaultUserImage} alt="default"/>
                                </div>
                        }
                        </Col>
                    </Row>
                    <Row style={{marginBottom: '20px'}}>

                        <Col md={12}>
                            <Form.Group>
                                <Form.Control type="file" onChange={(e) => setLocalImage(e)}/>
                            </Form.Group>
                        </Col>

                    </Row>
                </Col>
            </Row>

            <DialogModel
                message={dilogMessage}
                show={showDilog}
                onHide={() => afterSaveUser()}
            />

            <DialogModel
                message={dilogMessage}
                show={showDilogEmailLink}
                onHide={() => setShowDilogEmailLink(false)}
            />


        </Container>
    )

}

const mapStateToProps = (state) => ({
    allOrganizations: state.allOrganizations,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getAllOrganizations: getAllOrganizations,
        }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserComponent);
