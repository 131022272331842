

export const allCourses = (data) => {
    return {
        type: "STORE_ALL_COURSES",
        data
    }
};

export const loading = ( loading ) => {
    return {
        type: "LOADING",
        loading,
    }
};







