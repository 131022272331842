import React, {useEffect, useState} from "react"
import * as chapterService from "../../Services/Chapter.service"
import * as userService from "../../Services/User.service"
import {Link, useHistory, useParams} from 'react-router-dom';
import {Button, Col, Container, Row, Spinner} from "react-bootstrap";
import * as courseService from "../../Services/Course.service";
import "./ChapterDetails.component.css"
import {getUserFromToken} from "../../Services/Admin.service";
import {faCircle, faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageNotFound from "../PageNotFound/PageNotFound.component";
import Content from "./Content.component";
import Quiz from "./Quiz.component";
import H5PComponent from "./H5P.component";
import ZoomMeeting from "./ZoomMeeting.component";

const ChapterDetails = () => {

    const history = useHistory()
    const [chapterDetails, setChapterDetails] = useState(null);
    const [courseDetails, setCourseDetails] = useState(null);
    const {courseSlug} = useParams();
    const {chapterSlug} = useParams();
    const {topicIndex} = useParams();
    const [showEnrolmentMessage, setShowEnrolmentMessage] = useState(false)
    const [courseCompletion, setCourseCompletion] = useState([])
    const [courseCompletionPercentage, setCourseCompletionPercentage] = useState(0)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        chapterService.getChapterDetails(courseSlug, chapterSlug).then(result => {
            setChapterDetails(result.data)
        }).catch((err) => {
            if (err?.response?.status === 403) {
                setShowEnrolmentMessage(true)
            }
            else if (err?.response?.status === 401) {
                history.push("/login")
            }
        }).finally(() => {
            setLoading(false)
        })
    }, [chapterSlug, courseSlug, history])


    useEffect(() => {
        courseService.getCourseDetails(courseSlug).then(result => {
            setCourseDetails(result.data)
        })
    }, [courseSlug])

    useEffect(() => {
        if (courseDetails && chapterDetails) {
            courseService.getCourseCompletion(getUserFromToken().id, courseDetails.id).then((result) => {
                if (result.data) {
                    setCourseCompletion(result.data)
                }
            })
        }
    }, [courseDetails, chapterDetails])


    const updateChapterWithSelectedAnswer = ( topicIndex, quizIndex,  selectedAnswer) => {
        let newChapter = {};
        Object.assign(newChapter, chapterDetails);
        newChapter.content[topicIndex]["quiz"][quizIndex]['selectedAnswer'] = selectedAnswer
        setChapterDetails(newChapter)
    }

    const updateDisplayAnswer = (topicIndex, quizIndex) => {
        let newChapter = {};
        Object.assign(newChapter, chapterDetails);
        newChapter.content[topicIndex]["quiz"][quizIndex]['displaySelectedAnswer'] = true
        setChapterDetails(newChapter)
    }


    useEffect(() => {

        if (courseCompletion?.length > 0) {

            let totalCourses = 0
            let totalCoursesComplete = 0

            courseDetails?.chapterList.forEach((chapter) => {
                totalCourses += chapter.content?.length
            })

            courseDetails?.chapterList.forEach((chapter) => {
                chapter.content && chapter.content.forEach((topic, index) => {
                    if (isTopicComplete(index, chapter.id, courseDetails.id)) {
                        totalCoursesComplete += 1
                    }
                })
            })

            if (totalCourses > 0) {
                setCourseCompletionPercentage(Math.floor((totalCoursesComplete / totalCourses) * 100))
            }
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseCompletion])


    const enroll = () => {
        history.push({
            pathname: '/enroll',
            state: {"courseSlug": courseSlug, "chapterSlug": chapterSlug}
        });
    }

    const markCourseComplete = (topic) => {
        const body = {
            userId: getUserFromToken().id,
            courseId: courseDetails.id,
            chapterId: chapterDetails.id,
            topic: parseInt(topic)
        }

        courseService.markCourseComplete(getUserFromToken().id, courseDetails.id, body).then((result) => {

            if (result.data) {
                let newCompletion = []
                newCompletion = JSON.parse(JSON.stringify(courseCompletion))
                newCompletion.push(body)
                setCourseCompletion(newCompletion)
            }

        })
    }

    const isTopicComplete = (topic, chapterId, courseId) => {
        const result = courseCompletion?.length > 0 && courseCompletion.find((val) => val.topic === topic && val.chapterId === chapterId && val.courseId === courseId)
        return !!result
    }

    const downloadCertificate = () => {
        userService.downloadCertificate(courseDetails.id).then((result)=> {
            const blob = base64toBlob(result.data.base64);

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
        }).then(()=> {

        })
    }

    const base64toBlob = (data) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64

        const bytes = window.atob(data);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'application/pdf' });
    };

    return (
        <Container fluid>
        <div id={"chapter-details"} style={{padding: "0px 20px 0px 30px", minHeight: "700px"}}>
            <div>
                <Row>
                    <Col md={3} className={"index-container"}>
                        <div className={"course-name"}>
                            <Link to={`/courses/${courseDetails?.slug}`}>
                                {courseDetails?.name}
                            </Link>
                            {
                                courseCompletionPercentage > 0 ? <div>
                                    {courseCompletionPercentage}% complete
                                </div> : <span></span>
                            }
                        </div>

                        <div style={{
                            fontSize: '18px',
                            letterSpacing: '1px'
                        }} className={"secondary-font-family"}>

                            {
                                courseDetails?.chapterList && courseDetails.chapterList.map((chapter, index) => {
                                    return (
                                        <div key={index}>
                                            <Link to={`/courses/${courseDetails.slug}/chapter/${chapter.slug}`}>
                                                <div>
                                                    <div className={'chapter'}>
                                                        Module {index + 1}: {chapter.name}
                                                    </div>
                                                </div>
                                            </Link>
                                            <div>
                                                {
                                                    chapter.content?.length > 0 && chapter.content.map((content, index) => {
                                                        return (
                                                            <Link key={index}
                                                                  to={`/courses/${courseDetails.slug}/chapter/${chapter.slug}/topic/${index + 1}`}>
                                                                <div
                                                                    className={index === topicIndex - 1 && chapter.slug === chapterSlug ? "lesson-active" : "lesson-inactive"}>
                                                                    <FontAwesomeIcon
                                                                        className={isTopicComplete(index, chapter.id, courseDetails.id) ? "topic-complete" : "topic-incomplete"}
                                                                        icon={isTopicComplete(index, chapter.id, courseDetails.id) ? faCheck : faCircle}/> Topic {index + 1} -
                                                                    {content}
                                                                </div>
                                                            </Link>
                                                        )
                                                    })
                                                }
                                            </div>

                                        </div>
                                    )
                                })
                            }
                        </div>

                    </Col>

                    {
                        chapterDetails ?
                            <Col md={9}>

                                <Row>
                                    <Col>
                                        <div
                                            style={{
                                                fontSize: "25px",
                                                fontWeight: "bold",
                                                textTransform: "capitalize",
                                                marginBottom: "30px"
                                            }}>
                                            {chapterDetails.name}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col md={12}>
                                        {
                                            !topicIndex ?
                                                <div style={{textAlign: "justify"}}>
                                                    <span dangerouslySetInnerHTML={{__html: chapterDetails.excerpt}}/>
                                                </div> : <span></span>
                                        }

                                    </Col>
                                </Row>


                                <Row>
                                    {
                                        chapterDetails?.content && chapterDetails.content.filter((topic, index) => index === topicIndex - 1).map((topic, index) => {
                                            return (
                                                <div key={index}>
                                                    {
                                                        topic.type === 'text' || !topic.type ?
                                                            <Content
                                                                index={index}
                                                                topic={topic}
                                                                chapterDetails={chapterDetails}
                                                                topicIndex={topicIndex}
                                                                isTopicComplete={(topic) => isTopicComplete(topic, chapterDetails?.id, courseDetails?.id)}
                                                                markCourseComplete={(topic) => markCourseComplete(topic)}
                                                            /> : <span></span>
                                                    }

                                                    {
                                                        topic.type === 'quiz' ?
                                                            <Quiz
                                                                index={index}
                                                                topic={topic}
                                                                chapterDetails={chapterDetails}
                                                                topicIndex={topicIndex}
                                                                isTopicComplete={(topic) => isTopicComplete(topic, chapterDetails?.id, courseDetails?.id)}
                                                                markCourseComplete={(topic) => markCourseComplete(topic)}
                                                                updateChapterWithSelectedAnswer={(quizIndex, selectedAnswer)=>updateChapterWithSelectedAnswer(topicIndex-1, quizIndex,  selectedAnswer)}
                                                                updateDisplayAnswer={(quizIndex)=>updateDisplayAnswer(topicIndex-1, quizIndex)}
                                                            /> : <span></span>
                                                    }


                                                    {
                                                        topic.type === 'h5p' ?
                                                            <H5PComponent
                                                                index={index}
                                                                topic={topic}
                                                                chapterDetails={chapterDetails}
                                                                topicIndex={topicIndex}
                                                                isTopicComplete={(topic) => isTopicComplete(topic, chapterDetails?.id, courseDetails?.id)}
                                                                markCourseComplete={(topic) => markCourseComplete(topic)}
                                                            /> : <span></span>
                                                    }

                                                    {
                                                        topic.type === 'zoom' ?
                                                            <ZoomMeeting
                                                                index={index}
                                                                topic={topic}
                                                                chapterDetails={chapterDetails}
                                                                topicIndex={topicIndex}
                                                                isTopicComplete={(topic) => isTopicComplete(topic, chapterDetails?.id, courseDetails?.id)}
                                                                markCourseComplete={(topic) => markCourseComplete(topic)}
                                                            /> : <span></span>
                                                    }

                                                </div>
                                            )

                                        })
                                    }
                                </Row>
                            </Col> :
                            <Col md={9}>
                                {
                                    showEnrolmentMessage ?
                                        <div>
                                            <div>
                                                <Row style={{
                                                    height: "200px",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    textAlign: "center"
                                                }}>
                                                    <Col md={12}>
                                                        <div style={{
                                                            textAlign: "center",
                                                            fontWeight: "bold",
                                                            fontSize: "30px"
                                                        }}>
                                                            {courseDetails?.name}
                                                        </div>
                                                    </Col>

                                                    <Col md={12}>
                                                        <Button size="lg" style={{border: "none", borderRadius: "15px"}}
                                                                variant="secondary"
                                                                onClick={() => enroll()}>
                                                            Enroll in Course for ₹{courseDetails?.price}
                                                        </Button>
                                                    </Col>
                                                </Row>

                                            </div>
                                        </div>
                                        : <Row>
                                            <Col style={{
                                                textAlign: "center",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                {
                                                    loading ?
                                                        <Spinner animation={"border"}/> : <PageNotFound/>
                                                }
                                            </Col>
                                        </Row>
                                }
                            </Col>
                    }
                </Row>


                {
                    courseCompletionPercentage === 100 ?
                        <Row style={{textAlign: "center"}}>
                            <Col>
                                <Button onClick={()=> downloadCertificate()}>Download Certificate</Button>
                            </Col>
                        </Row>: undefined
                }

            </div>
        </div>
        </Container>
    )
}


export default ChapterDetails