import React, {useEffect, useState} from "react"
import AdminHeaderComponent from "./Header/Header.component";
import * as adminService from "../../Services/Admin.service"
import * as courseService from "../../Services/Course.service"
import {Col, Container, Row} from "react-bootstrap";


const AdminComponent = () => {

    const [userEnrolment, setUserEnrolment] = useState({})

    useEffect(()=>{
        courseService.getTotalUsersEnrolledForCourses().then((result)=> {
            setUserEnrolment(result.data)
        })

    }, [])


    return (
        <Container style={{minHeight: "700px"}}>
            <AdminHeaderComponent />

            <div style={{textTransform: "capitalize"}}>
                Welcome, {adminService.getUserFromToken()?.name}
            </div>

            <Row>
            {
                userEnrolment?.userEnrolled?.length > 0 && userEnrolment.userEnrolled?.map((course, index) =>{
                    return (
                        <Col md={4} style={{marginBottom: "10px"}} key={index}>
                            <div>Course Name: {course.courseName}</div>
                            <div>Instructor Name: {course.instructorName}</div>
                            <div>Total Students Enrolled: {course.totalCoursesEnrolled}</div>
                        </Col>
                    )
                })
            }
            </Row>

        </Container>

    )


}

export default AdminComponent